import * as en from '../translations/languages/en.json'
import * as sv from '../translations/languages/sv.json'
import * as dk from '../translations/languages/dk.json'
import * as fi from '../translations/languages/fi.json'

import { useTranslation } from "react-i18next";
import { ApplicationTranslationSchema } from '../types'



const langEn = en as any
const langSV = sv as any
const langDK = dk as any
const langFI = fi as any

export const getDefaultSchema = (language: string) => {
  switch (language) {
    case 'en':
      return removeRestrictedTranslationSection(langEn.default)
    case 'sv':
      return removeRestrictedTranslationSection(langSV.default)
    case 'dk':
      return removeRestrictedTranslationSection(langDK.default)
    case 'fi':
      return removeRestrictedTranslationSection(langFI.default)

  }
}

const restirctedSections = ["PLATFORM"]

const removeRestrictedTranslationSection = (templateTranslations: any) => {
  restirctedSections.forEach((section: string) => {
    if (section in templateTranslations) {
      delete templateTranslations[section]
    }
  })
  return templateTranslations
}

const iterateSchemaObject = (translationsObject: ApplicationTranslationSchema, customerTranslations: ApplicationTranslationSchema) => {
  for (const [key, value] of Object.entries(translationsObject)) {
    if (!customerTranslations[key]) {
      customerTranslations[key] = value
    } else {
      if (typeof value !== "string") {
        customerTranslations[key] = iterateSchemaObject(translationsObject[key] as ApplicationTranslationSchema, customerTranslations[key] as ApplicationTranslationSchema)
      }
    }
  }
  return customerTranslations
}

const removeNoLongerUsedTranslations = (translationsObject: ApplicationTranslationSchema, customerTranslations: ApplicationTranslationSchema) => {
  for (const [key, value] of Object.entries(customerTranslations)) {
    if (!translationsObject[key]) {
      delete customerTranslations[key]
    } else {
      if (typeof value !== "string") {
        customerTranslations[key] = removeNoLongerUsedTranslations(translationsObject[key] as ApplicationTranslationSchema, customerTranslations[key] as ApplicationTranslationSchema)
      }
    }
  }
  return customerTranslations
}

export const getFullSchema = (customerTranslations: ApplicationTranslationSchema, lang: string) => {
  let defaultSchema = getDefaultSchema(lang)
  let updatedCustomerTranslations = removeNoLongerUsedTranslations(defaultSchema, customerTranslations)
  updatedCustomerTranslations = iterateSchemaObject(defaultSchema, customerTranslations)

  return updatedCustomerTranslations
}



export const useCustomTranslation = (organizationId: string) => {
  const { t } = useTranslation(['common', `${organizationId}`])
  const getTranslation = (key: string, options?: any) => {
    let custom = t(key, { ns: `${organizationId}`, ...options })
    let defaultKey = t(key, options)

    if (custom === key || custom === "") {
      return defaultKey
    }
    return custom
  }
  return { getTranslation };
}
