import '../../../css/App.css';
import { useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai'


type TagsInputProps = {
  title: string
  name: string
  onAddTag: Function
  onRemoveTag: Function
  currentTags: string[]
}



export const TagsInput = ({ title, name, onAddTag, onRemoveTag, currentTags }: TagsInputProps) => {

  const [inputValue, setInputValue] = useState<string>('')
  const createTag = () => {
    const tag = inputValue
    onAddTag('tags', tag)
    setInputValue('')
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && inputValue.length > 0) {
      e.preventDefault()
      createTag()
    }
  }

  const handleChange = (value: string) => {
    const last = value.slice(value.length - 1)
    if ([',', ';', ':', ' '].includes(last)) {
      createTag()
      return
    }
    setInputValue(value)
  }

  const createdTags = () => {
    if (!currentTags || currentTags.length === 0) {
      return null
    }
    return (
      currentTags.map(tag => (
        <div className="tag" key={tag} onClick={() => onRemoveTag('tags', tag)}>
          <div className="option-text">{tag}</div>
          <AiOutlineCloseCircle size="1.1em" />

        </div>))
    )
  }

  const focusOut = () => {
    if (inputValue !== '') {
      createTag()
    }
  }

  return (
    <div className="field-pair">
      <div className="tags scrollbar" id="style-4">
        {createdTags()}
      </div>
      <label htmlFor={name}>{title}</label>
      <input
        type="text"
        id={name}
        name={name}
        value={inputValue}
        onKeyDown={handleKeyDown}
        onBlur={() => { focusOut() }}
        onChange={(e) => handleChange(e.target.value)} />
    </div>
  )

}