import { useState } from 'react'
import { toastNotifySuccess, toastNotifyError } from '../common/ToastMessage'
import { Event, SingleFileUpLoadState } from '../../types'
type Props = {
  entityId: string
  currentImageUrl: string
  buttonColor: string
  updateFunction: Function
  updateText: string
  onSuccess?: Function
  translationFunction: Function
}
export const ImageEdit = ({ entityId, currentImageUrl, buttonColor, updateFunction, updateText, onSuccess, translationFunction }: Props) => {
  const [showReplaceImage, setShowReplaceImage] = useState<boolean>(false)
  const [imageFile, setImageFile] = useState<SingleFileUpLoadState>({
    selectedFile: null,
  })
  const [isUpdatingImage, setIsUpdatingImage] = useState<boolean>(false)
  const [updatedImageURL, setUpadatedImageURL] = useState<string | null>(null)

  const submitNewCollectionImage = async () => {
    if (!imageFile.selectedFile) {
      return
    }
    const data = new FormData()
    data.append('photo', imageFile.selectedFile)
    setIsUpdatingImage(true)
    try {
      const response = await updateFunction(entityId, data)
      setUpadatedImageURL(response.data.imageUrl)
      setImageFile({ selectedFile: null })
      toastNotifySuccess(translationFunction('GLOBAL.UPDATE_SUCCESS'))
      if (onSuccess) {
        onSuccess(response.data.imageUrl)
      }

    } catch (error: any) {
      toastNotifyError(error.respone ? error.response.data.message : 'Something when wrong, could not update image,')
    }
    finally {
      setIsUpdatingImage(false)
      setShowReplaceImage(false)
    }
  }

  const submitButtonClasses = isUpdatingImage ? 'loader-btn loader-btn--loading btn-stnd-al m-t-50' : 'loader-btn btn-stnd-al m-t-50'

  const choseNewImage = () => {
    const preview = () => {
      if (!imageFile.selectedFile) {
        return null
      }
      return <img className="" src={URL.createObjectURL(imageFile.selectedFile)} alt="" />
    }
    return (
      <div className="image-selection-container">
        <div className="preview-collection-image">
          {preview()}
        </div>
        <label className="image-input">
          <input
            type="file"
            id="collectionImage"
            name="collectionImage"
            accept="image/*"
            onChange={(event: Event<HTMLInputElement>) => {
              const file = event.target.files ? event.target.files[0] : null
              if (file != null) {
                setImageFile({ selectedFile: file })
              }
            }}
          />
        </label>
        <button className={submitButtonClasses} style={{ backgroundColor: buttonColor }} onClick={() => submitNewCollectionImage()} disabled={imageFile.selectedFile === null}>
          <span className="loader-btn__text">{translationFunction('ADMIN.EDIT_COLLECTION.UPDATE_IMG_SUBMIT')}</span>
        </button>
      </div>
    )
  }

  return (
    <div>
      {
        showReplaceImage ? choseNewImage() :
          (
            <>
              <div className="preview-collection-image" style={{ margin: 'auto' }}>
                <img className="" src={updatedImageURL || currentImageUrl} alt="" />
              </div>
              <button
                className="btn btn-secondary"
                style={{ backgroundColor: buttonColor }}
                onClick={() => setShowReplaceImage(true)}>
                {updateText}
              </button>
            </>
          )
      }

    </div >
  )
}