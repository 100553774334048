import "../../css/Carousel.css"

import { useState, useEffect, useRef } from 'react'

const colors = ["#0088FE", "#00C49F", "#FFBB28"];
const delay = 4000;

type CarouselProps = {
  images: string[]
}

export const Carousel = ({ images }: CarouselProps) => {
  const [index, setIndex] = useState(0);

  const timeoutRef = useRef<NodeJS.Timeout>();

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => { resetTimeout(); };
  }, [index]);



  return (
    <div className="slideshow">
      <div className="slideshowSlider" style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
        {images.map((imgUrl, index) => (
          <div className="carousel-slide" key={index}  >
            <img
              style={{ width: '100%', height: 500 }}
              src={imgUrl} alt="Carousel"
              key={imgUrl}
            />)
          </div>
        ))}
      </div>
      <div className="slideshowDots">
        {images.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>

  );
}