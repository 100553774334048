import { useState, useEffect } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { useApplicationContext } from "../../context/ApplicationContext";
import { ROUTE } from "../../routes";
import "../../css/Header.css";
import "../../css/App.css";
import { LanguageSelector } from "../common/LanguageSelector";
import { ApplicationStorage } from "../../api/applicationStorage";
import { Can } from "../../helpers/permissionsHelper";
import { AdminMenu } from "../common/AdminMenu";
import { useCustomTranslation } from "../../helpers/CustomTranslationsComps";

export const Header = () => {
  const userContext = useUserContext();
  const organizationContext = useOrganizationContext();
  const appCtx = useApplicationContext();
  const location = useLocation();
  const [userName, setUserName] = useState<string>("");
  const history = useHistory();

  const { getTranslation } = useCustomTranslation(organizationContext?.organization?.id || "");
  console.log("org", organizationContext?.organization)
  const favourites = () => {
    return (
      <div className="header-profile-content">
        {userContext && userContext.loggedInUser ? (
          organizationContext && organizationContext.organization ? (
            <Link to={`/organization/${organizationContext!.organization!.id}/tagged`} className="header-username header-link-text link-text-color header-item">
              {getTranslation("HEADER.FAVOURITES")}
            </Link>
          ) : (
            <div className="header-username">{userName}</div>
          )
        ) : null}
      </div>
    );
  };

  const profileName = () => {
    return (
      <div className="header-profile-content">
        {userContext && userContext.loggedInUser ? (
          organizationContext && organizationContext.organization ? (
            <Link to={`/organization/${organizationContext!.organization!.id}/edit-profile`} className="header-username">
              {userName}
            </Link>
          ) : (
            <div className="header-username">{userName}</div>
          )
        ) : organizationContext && organizationContext.organization ? (
          <Link className="header-link-text link-text-color header-item" to={{ pathname: ROUTE.LOGIN, state: { redirectOrg: organizationContext.organization.id } }}>
            {getTranslation("HEADER.SIGNIN_LINK")}
          </Link>
        ) : null}
      </div>
    );
  };

  const backButton = () => {
    if (organizationContext && organizationContext.organization) {
      return (
        <>
          <Link className="header-link-text link-text-color header-item" to={{ pathname: `/organization/${organizationContext.organization.id}` }}>
            {getTranslation("HEADER.COLLECTIONS_LINK")}
          </Link>
        </>
      );
    }
    if (!organizationContext?.organization && location.pathname === ROUTE.ABOUT) {
      return (
        <>
          <Link className="header-link-text link-text-color header-item" to={ROUTE.HOME}>
            {getTranslation("HEADER.HOME")}
          </Link>
        </>
      );
    }

    return null;
  };

  const logout = () => {
    if (!userContext || !userContext.loggedInUser) return;
    ApplicationStorage.removeToken();
    userContext.clearUserContext();
    if (organizationContext && organizationContext.organization) {
      history.push(`/organization/${organizationContext.organization.id}`);
    } else {
      history.push("/");
    }
  };

  const updateApplicationLanguage = (language: string) => {
    appCtx.setLanguageInContext(language);
    ApplicationStorage.setUserLanguageSelection(language);
  };

  useEffect(() => {
    let name = userContext && userContext.loggedInUser ? `${userContext.loggedInUser.firstName} ${userContext.loggedInUser.lastName}` : "";
    if (name.length && name !== userName) {
      setUserName(name);
    }
  }, [userContext?.loggedInUser?.firstName, userName, setUserName, userContext]);

  return (
    <div className="app-header" style={location.pathname !== "/" ? { backgroundColor: `${organizationContext?.organization?.color}` } : {}}>
      <div className="header-left-content"></div>
      <div className="header-right-content">
        {organizationContext?.organization?.id === "d09b87dc-a66b-45ab-af90-80304d2a8e32" && favourites()}
        {backButton()}
        {profileName()}
        {userContext && userContext.loggedInUser && (
          <button className="header-signout" onClick={logout}>
            <p>{getTranslation("LOGIN.LOGOUT")}</p>
            <img style={{ width: "25px" }} src="/icons/sign_out.png"></img>
          </button>
        )}

        <div className="header-lang-selector-container">
          <LanguageSelector onLanguageUpdate={updateApplicationLanguage} />
        </div>
        {userContext && userContext.loggedInUser && <Can userRoles={userContext?.loggedInUser?.roles} requiredPermission={userContext?.actionRoles?.ADMIN} organizationId={organizationContext?.organization?.id} isPlatformAdmin={userContext?.userIsPlaformAdmin} yes={() => <AdminMenu />} />}
      </div>
    </div>
  );
};
