import { useState } from 'react'
import { TextArea } from '../CreateContribution/formComponents/FormComponents'
type AddCommentProps = {
  title: string
  addComment: Function
  buttonColor: string
  addButtonText: string
}
export const AddComment = ({ title, addComment, buttonColor, addButtonText }: AddCommentProps) => {
  const [comment, setComment] = useState<string>('')

  const onAddComment = () => {
    if (comment === '') {
      return
    }
    let newComment = comment
    addComment(newComment)
    setComment('')
  }
  return (
    <div className="settings-q-wrapper">
      <TextArea
        title={title}
        name={title}
        onUpdate={(name: string, value: string) => setComment(value)}
        handleKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}
        value={comment} />
      <button className="btn btn-secondary" style={{ backgroundColor: buttonColor }} onClick={onAddComment}>{addButtonText}</button>
    </div>
  )
}
