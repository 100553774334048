import { FiTrash2 } from 'react-icons/fi'
type AddQuestionsProps = {
  title: string
  questions: string[] | null
  deleteQuestion: Function
  buttonColor: string
  canDelete?: boolean
}
export const ShowQuestions = ({ title, questions, deleteQuestion, buttonColor, canDelete = true }: AddQuestionsProps) => {

  return (
    <div className="show-questions-container">
      <div className="collection-questions-container">
        <p>{title}</p>
        {questions && questions.map((q: string) => (
          <div className="collection-questions-wrapper">
            <span className="questions-span" key={q}>{q}</span>
            {canDelete && (
              <button className="btn-icon" style={{ backgroundColor: buttonColor }} onClick={() => { deleteQuestion(q) }}>
                <FiTrash2 />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}