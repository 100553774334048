import { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import loadImage from "blueimp-load-image";
import { MediaFile } from '../../types'


export type ImageCropProps = {
  onUpdate: Function
  onCrop?: Function
  src: MediaFile
  onSave: Function
  cancel: Function
  translationFunction: Function
}

type cropState = {
  unit: '%' | 'px',
  width: number,
  height: number,
  aspect?: number,
  x: number,
  y: number
}

export const ImageCropper = ({ src, onSave, cancel, translationFunction }: ImageCropProps) => {
  const orientation: number[] = [1, 8, 3, 6];
  const [orientationIndex, setOrientationIndex] = useState<number>(0);
  const [upImg, setUpImg] = useState<string>(src.URL);
  const [cropElementRef, setCropElementRef] = useState<HTMLImageElement>();
  const [cropState, setCropState] = useState<cropState>({
    unit: '%',
    width: 0,
    height: 0,
    aspect: 0,
    x: 0,
    y: 0
  });
  const [croppedImage, setCroppedImage] = useState<MediaFile>()

  const rotateRight = (e: any) => {
    let i: number = orientationIndex;
    (i === 0) ? i = 3 : i = i - 1; // 0,1,2,3,0,1...
    setOrientationIndex(i);
    transformImage(i);
  };

  const rotateLeft = (e: any) => {
    let i: number = orientationIndex;
    (i === 3) ? i = 0 : i = i + 1; // 0,1,2,3,0,1...
    setOrientationIndex(i);
    transformImage(i);
  };

  const transformImage = (rotationIndex: number) => {
    loadImage(
      src.File,
      (img: HTMLCanvasElement) => {
        let base64data: string = img.toDataURL('image/jpeg', 0.8)
        setUpImg(base64data);
        img.toBlob((b: (Blob | null)) => {
          if (b === null) {
            return
          }
          setCroppedImage({
            URL: base64data,
            File: new File([b], src.File.name),
            type: src.type
          });
        });
      },
      { orientation: orientation[rotationIndex], canvas: true }
    )
  }

  const cropAndSave = async (e: any) => {
    // A crop rectangle is drawn, so crop before save
    if (cropState.width !== 0 && cropElementRef) {
      const croppedImageResult = await getCroppedImage(
        cropElementRef,
        cropState,
        src.File.name // destination filename
      );
      onSave(croppedImageResult);
      return cancel()
    }

    // no crop was attempted, but image has been rotated
    if (orientationIndex !== 0 && croppedImage) {
      onSave(croppedImage);
    }

    // no rotation or crop, just close
    return cancel();
  }

  const getCroppedImage = (sourceImage: HTMLImageElement, cropConfig: cropState, filename: string) => {



    // creating the cropped image from the source image
    const canvas = document.createElement('canvas');
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext('2d');


    if (ctx === null) {
      return
    }

    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high"

    ctx.drawImage(
      sourceImage, // source
      cropConfig.x * scaleX, // sx
      cropConfig.y * scaleY, // sy
      cropConfig.width * scaleX,// sw
      cropConfig.height * scaleY, // sh
      0, //dx
      0, //dy
      cropConfig.width, //dw
      cropConfig.height //dh
    );


    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          let file: File = new File([blob], filename);
          const croppedImageUrl = URL.createObjectURL(blob);

          let img: MediaFile = {
            URL: croppedImageUrl,
            File: file,
            type: src.type
          }

          resolve(img);
        }, 'image/jpeg'
      );
    });
  }




  return (
    <div className="image-cropper">
      <div className="crop-control">
        <button type="button" className="btn btn-default" onClick={rotateLeft}>{translationFunction('CREATE_CONTRIBUTION_PAGE.CROP_CONTROL_ROTATE_LEFT')}</button>
        {/* <button type="button" className="btn" onClick={cropImage}>Crop</button> */}
        <button type="button" className="btn btn-default " onClick={rotateRight}>{translationFunction('CREATE_CONTRIBUTION_PAGE.CROP_CONTROL_ROTATE_RIGHT')}</button>
        {cropState.width === 0 ? <button type="button" className="btn btn-default" onClick={cropAndSave}>{translationFunction('GLOBAL.SAVE')}</button> : <button type="button" className="btn  btn-default" onClick={cropAndSave}>{translationFunction('CREATE_CONTRIBUTION_PAGE.CROP_CONTROL_CROP_SAVE')}</button>}
      </div>
      <div className="crop-hint" style={{ marginBottom: '15px' }}>{translationFunction('CREATE_CONTRIBUTION_PAGE.CROP_CONTROL_HELP_TEXT')}</div>

      {upImg !== "" ? (
        <ReactCrop
          className="react-crop-img"
          src={upImg}
          imageStyle={{ height: "100%", maxWidth: "100%", objectFit: "contain" }}
          style={{ height: "calc(100% - 40px)", maxWidth: "95%", objectFit: "contain", display: "inline-block", boxShadow: "rgb(95 95 95) 4px 6px 18px 6px" }}
          crop={cropState}
          onChange={(c) => setCropState(c)}
          onComplete={(cropConfig) => setCropState(cropConfig)}
          onImageLoaded={(imageRef) => setCropElementRef(imageRef)}
        />
      ) : (
        <div />
      )}

    </div>
  );
}
