import axios from "axios";
import { UpdatedContributionType } from "../types";
import { CreateAuthString, CreateAuthHeaders } from "./createAuthString";
import { GalleryImage } from "../components/gallery/Gallery";

const base_url = process.env.REACT_APP_API_URL || "";

export const Api = (() => {
  const getCollections = async (organizationId: string) => {
    try {
      const authorizationHeader = CreateAuthHeaders(true);

      return (await axios.get(base_url + "/collections", { params: { organizationId }, headers: { ...authorizationHeader } })).data;
    } catch (error: any) {
      return (await axios.get(base_url + "/collections", { params: { organizationId } })).data;
    }
  };

  const getCollection = async (collectionId: string) => {
    try {
      const authorizationHeader = CreateAuthHeaders(true);
      return (await axios.get(base_url + "/collection", { params: { collectionId }, headers: { ...authorizationHeader } })).data;
    } catch (error: any) {
      return (await axios.get(base_url + "/collection", { params: { collectionId } })).data;
    }
  };

  const getSimilarFiles = async (uniqueTags: string[], cId: string) => {
    let searchParams = new URLSearchParams();

    uniqueTags.forEach((tag: string) => {
      searchParams.append("tag", tag);
    });

    searchParams.append("contributionId", cId);
    return (await axios.get(base_url + "/similar-pictures", { params: searchParams })).data;
  };

  const uploadContribution = async (postdata: FormData): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false);
      const respone = await axios.post(base_url + "/submit-contribution", postdata, { headers: { ...authorizationHeader } });
      return respone;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "Error while submitting contribution";
    }
  };
  const editContribution = async (postdata: UpdatedContributionType): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false);
      const respone = await axios.post(base_url + "/update-contribution", postdata, { headers: { ...authorizationHeader } });
      return respone;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "Error while submitting edit of contribution";
    }
  };

  const getContribution = async (contributionId: string): Promise<any> => {
    let token = "";
    try {
      token = CreateAuthString(true);
    } catch (error: any) {}

    let headers = {};
    if (token !== "") {
      headers = { headers: { Authorization: token } };
    }

    try {
      const response = await axios.get(base_url + "/contribution/" + contributionId, headers);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "Error while getting the contribution";
    }
  };

  const getCollectionImages = async (collectionId: string, paginationId: number, isGallery: boolean = false): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(true);
      const response = await await axios.get(base_url + "/collection-contributions", { params: { collectionId, paginationId, isGallery }, headers: { ...authorizationHeader } });
      return response.data;
    } catch (error: any) {
      const response = await axios.get(base_url + "/collection-contributions", { params: { collectionId } });
      return response.data;
    }
  };

  const getUserContributions = async (userID: string, organizationId: string): Promise<any> => {
    let byOrg = "";
    if (organizationId !== "") {
      byOrg = "/organization/" + organizationId;
    }

    try {
      let authorizationHeader = CreateAuthHeaders(false);

      const response = await axios.get(base_url + "/user/" + userID + byOrg + "/contributions", { headers: { ...authorizationHeader } });
      return response.data;
    } catch (error: any) {
      const response = await axios.get(base_url + "/user/" + userID + byOrg + "/contributions");
      return response.data;
    }
  };

  const registerUser = async (registrationData: any, organizationId: string): Promise<any> => {
    try {
      const response = await axios.post(base_url + "/register-user", registrationData, { params: { organizationId } });
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log(error.response.data.message);
      }
      throw error.response ? (error.response.status === 400 ? (error.response.data && error.response.data.message && error.response.data.message.includes("this email is already in use.") ? "email-in-use" : error.response.data.message) : error.response.data.message) : "Error while registering user.";
    }
  };

  const registerVisitor = async (registrationData: any, organizationId: string) => {
    const { email, firstName, lastName } = registrationData;
    console.log(`Registring user: mail: ${email}, firstname: ${firstName}, lastName: ${lastName}`);
    try {
      const response = await axios.post(base_url + "/register-visitor/" + organizationId, registrationData);
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log(error.response.data.message);
      }
      throw error.response ? (error.response.status === 400 ? (error.response.data && error.response.data.message && error.response.data.message.includes("this email is already in use.") ? "email-in-use" : error.response.data.message) : error.response.data.message) : "Error while registering user.";
    }
  };

  const checkIfEmailExists = async (email: string) => {
    console.log("Checking if email exists: ", email);
    try {
      const response = await axios.get(base_url + "/email-exists/" + email);
      const data: any = response.data;
      return data.exists;
    } catch (error: any) {
      if (error.response) {
        console.log(error.response.data.message);
      }
      throw error.response ? (error.response.status === 400 ? (error.response.data && error.response.data.message && error.response.data.message.includes("this email is already in use.") ? "email-in-use" : error.response.data.message) : error.response.data.message) : "Error while registering user.";
    }
  };

  const requestPasswordReset = async (email: string, organizationId: string): Promise<any> => {
    try {
      const response = await axios.post(base_url + "/password-reset", { email, organizationId });
      return response;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "Error while requesting password reset.";
    }
  };

  const confirmPasswordReset = async (payload: any): Promise<any> => {
    try {
      const response = await axios.put(base_url + "/password-reset", payload);
      return response;
    } catch (error: any) {
      // eslint-disable-next-line
      throw {
        status: error.response ? error.response.status : "",
        message: error.response ? error.response.data.message : "Error while confirming password reset.",
      };
    }
  };

  const updateUser = async (userData: any): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false);
      const response = await axios.post(base_url + "/user", userData, { headers: { ...authorizationHeader } });
      return response;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "Error while updating user.";
    }
  };

  const loginUser = async (loginData: any): Promise<any> => {
    try {
      const response = await axios.post(base_url + "/login", loginData);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const deleteUser = async (): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false);
      const response = await axios.delete(base_url + "/user", { headers: { ...authorizationHeader } });
      return response;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "Error while updating user.";
    }
  };

  const getUserByID = async (userID: string, orgID: string): Promise<any> => {
    const authorizationHeader = CreateAuthHeaders(true);
    try {
      const response = await axios.get(base_url + "/user/" + userID + "/" + orgID, { headers: { ...authorizationHeader } });
      return response.data;
    } catch (error: any) {
      if (error.response) {
        return { statusCode: error.response.status };
      }
      // eslint-disable-next-line
      throw "Error while trying to fetch user information";
    }
  };

  const getUser = async (token: string): Promise<any> => {
    try {
      const AuthStr = "Bearer ".concat(token);
      const response = await axios.get(base_url + "/user", { headers: { Authorization: AuthStr } });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "Error while trying to login.";
    }
  };

  const getLicences = async (): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false);
      const response = await axios.get(base_url + "/licenses", { headers: { ...authorizationHeader } });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "Error while trying to login.";
    }
  };
  const getRoles = async (): Promise<any> => {
    try {
      const response = await axios.get(base_url + "/roles");
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "Error while trying to login.";
    }
  };

  const getOrganizations = async (): Promise<any> => {
    try {
      const response = await axios.get(base_url + "/organizations");
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "Error while fetching organization";
    }
  };

  const getOrganization = async (organizationId: string): Promise<any> => {
    try {
      const response = await axios.get(base_url + "/organization/" + organizationId);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "Error while getting the organization";
    }
  };

  const deleteContribution = async (contributionId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false);
      const response = await axios.delete(base_url + "/delete-contribution", { headers: { ...authorizationHeader }, params: { contributionId } });
      return response;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "An error occured while trying to delete the contribution";
    }
  };
  const getOrganizationHelpPages = async (organizationId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(true);
      const response = await axios.get(base_url + "/organization/" + organizationId + "/help-page", { headers: { ...authorizationHeader } });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "An error occured while fetching help page translations";
    }
  };
  const getSystempPages = async (): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(true);
      const response = await axios.get(base_url + "/system-page", { headers: { ...authorizationHeader } });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "An error occured while fetching system page translations";
    }
  };

  const sendClientError = async (clientError: any): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(true);
      await axios.post(base_url + "/send-client-error", clientError, { headers: { ...authorizationHeader } });
    } catch (error: any) {
      throw error.response ? error.response.data.message : "An error occured while fetching system page translations";
    }
  };

  const getTaggedImages = async () => {
    try {
      const authorizationHeader = CreateAuthHeaders(true);
      const response = await axios.get(base_url + "/tagged-pictures", { headers: { ...authorizationHeader } });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data.message : "An error occured while fetching system page translations";
    }
  };

  const addToTagged = async (image: GalleryImage, email: string) => {
    //

    console.log(`Adding ${image.id} favoruite to email: ${email}`);

    try {
      const response = await axios.post(base_url + "/add-to-tagged" + `/${email}/${image.id}`);
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log(error.response.data.message);
      }
      throw error.response ? (error.response.status === 400 ? (error.response.data && error.response.data.message && error.response.data.message.includes("this email is already in use.") ? "email-in-use" : error.response.data.message) : error.response.data.message) : "Error while registering user.";
    }
  };

  return Object.freeze({
    addToTagged,
    getCollections,
    uploadContribution,
    getContribution,
    getCollectionImages,
    getUserContributions,
    registerUser,
    registerVisitor,
    checkIfEmailExists,
    getTaggedImages,
    requestPasswordReset,
    confirmPasswordReset,
    updateUser,
    loginUser,
    getUser,
    getUserByID,
    deleteUser,
    getLicences,
    getRoles,
    getOrganizations,
    getOrganization,
    getSimilarFiles,
    getCollection,
    editContribution,
    deleteContribution,
    getOrganizationHelpPages,
    getSystempPages,
    sendClientError,
  });
})();
