import { BiCrop, BiTrash } from 'react-icons/bi'
import { AiFillPlayCircle } from 'react-icons/ai'
import { MediaType, MediaListItem, KeyString, EditFileType } from '../../../types'
import { getFileExtension, getType } from '../../../helpers/mediaFileFunctions'


type MediaPreviewProps = {
  file: MediaListItem | EditFileType
  selectedMediaIdentifier: string
  // setFileAsSelected: Function
  errorFiles: string[]
  onCropSelect: () => void;
  showCropper?: boolean
  deleteFile?: Function
}


export const MediaPreview = ({ file, errorFiles, deleteFile, onCropSelect, selectedMediaIdentifier, showCropper = true }: MediaPreviewProps) => {

  const getUrl = (file: MediaListItem | EditFileType) => {
    if ('formValues' in file) {
      return (file as MediaListItem).mediaFile.URL
    } else {
      return (file as EditFileType).mediaFile.imgUrl
    }
  }

  const getFileTitle = (file: MediaListItem | EditFileType) => {
    if ('formValues' in file) {
      return (file as MediaListItem).formValues?.title || 'image'
    }
    return (file as EditFileType).mediaFile.title || 'image'
  }

  const getPreviewMediaClasess = (file: MediaListItem | EditFileType) => {
    let classes = 'mediaPreview-container'

    if (errorFiles.includes(getUrl(file))) {
      classes = classes + ' media-with-error'
    }
    let identifier = 'type' in file ? file.mediaFile.id : getUrl(file)
    if (identifier === selectedMediaIdentifier) {
      classes = classes + ' selected-preview-media'
    }

    return classes
  }

  if (getType(file) === MediaType.Video) {
    return (
      <div className={getPreviewMediaClasess(file)}>
        <div className="">
          <div className="preview-media preview-media-video">
            <video style={{ opacity: "0.4", width: 'inherit', height: 'inherit' }} >
              <source src={getUrl(file)} type={getFileExtension(file)} />
              Your browser does not support the video tag.
            </video>
            <AiFillPlayCircle size="2rem" style={{ position: 'absolute', top: '30%', left: '35%' }} />
          </div>
        </div>
      </div>
    )
  }


  return (
    <div className={getPreviewMediaClasess(file)}>
      <div className="">
        <img key={getUrl(file)} className="preview-media" src={getUrl(file)} alt={getFileTitle(file)} />
      </div>
      {showCropper && (
        <div id="bottom-me" style={{ position: 'absolute', bottom: '20%', left: '0%', right: '0%' }}>
          <button className="crop-btn" onClick={() => onCropSelect()}><BiCrop size="1.1rem" /></button>
          {deleteFile && <button className="crop-btn" onClick={() => deleteFile()}><BiTrash size="1.1rem" /></button>}
        </div>
      )}
    </div >
  )
}