import '../../css/Collection.css';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { CollectionType } from '../../types'
import { useOrganizationContext } from '../../context/OrganizationContext';
import { getCollectionKeyInLang, getCollectionTitleOrDescriptionInLang } from '../../helpers/helperFunctions'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'

type Props = {
  collections: CollectionType[];
  organizationId: string
  organizationName: string
}

export const CollectionList = ({ collections, organizationId, organizationName }: Props) => {
  const maxAboutLength = 190
  document.title = organizationName
  const { i18n } = useTranslation()
  const organizationCtx = useOrganizationContext()
  const { getTranslation } = useCustomTranslation(organizationId)

  if (!collections || collections.length === 0) {
    return (<div style={{ color: '#ffffff', marginTop: '20px' }}>No available collections at the moment.</div>)
  }

  const clickForMore = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      let textLength = text.substring(0, maxLength)
      textLength = textLength + '...'
      return (
        <div>
          <div className="collection-description">
            <div dangerouslySetInnerHTML={{ __html: textLength }} />
            <br />
          </div>
          <div className="collection-read-more">
            <span className="click-for-more" >{getTranslation('COLLECTIONS_PAGE.READ_MORE')}</span>
          </div>
        </div>)
    }

    return (
      <div className="collection-description">
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>)
  }

  const collectionInformation = (collection: CollectionType) => {

    return (
      <>
        <div className="collection-banner-text" onClick={(e) => { document.getElementById(collection.id)?.click(); }}>
          <h1>{getCollectionKeyInLang(collection, 'title', i18n.language)}</h1>
          {clickForMore(getCollectionTitleOrDescriptionInLang(collection, 'description', i18n.language), maxAboutLength)}
        </div>
        <div className="m-1 collection-banner-buttons">
          <Link hidden={true} id={collection.id} className="btn link-text" style={{ backgroundColor: `${organizationCtx?.organization?.color}` }} to={{ pathname: `/organization/${organizationId}/collections/${collection.id}`, state: { collectionData: collection } }}>{getTranslation('COLLECTIONS_PAGE.EXPLORE')}</Link>
        </div>
      </>
    )
  }

  const sortCollections = (a: CollectionType, b: CollectionType) => {
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    return 0;
  }

  return (
    <div className="collection-list">

      {collections.sort(sortCollections).map(c => (
        <div key={c.id} onClick={(e) => { document.getElementById(c.id)?.click(); }} className="collection">
          <div className="collection-img" style={{
            minHeight: '35vh',
            backgroundImage: "url(" + c.imgUrl + ")"
          }}>
          </div>
          {collectionInformation(c)}
        </div>
      ))
      }
    </div >
  );
}


