import '../../css/Home.css';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { getOrganizationKeyInLang } from '../../helpers/helperFunctions'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'

const OrganizationAboutComponent = () => {
  const { i18n } = useTranslation()
  const organizationContext = useOrganizationContext();



  return (
    <div className="App">
      <div className="app-content">
        <div className="org-banner" >
          {organizationContext?.organization?.logo_url !== "" ? <img src={organizationContext?.organization?.logo_url} alt="organization logo" /> : ""}

          <h1 className="">{organizationContext?.organization?.name}</h1>
          <div className="about-organization-text">{organizationContext && organizationContext.organization ?
            <div dangerouslySetInnerHTML={{ __html: getOrganizationKeyInLang(organizationContext.organization, 'about', i18n.language) }} /> : ""}</div>
        </div>
      </div >
    </div >
  );
}

export const OrganizationAbout = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="organizationAbout" router={history}>
        <OrganizationAboutComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <OrganizationAboutComponent {...props} />
  )
}
