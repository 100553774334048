import { useEffect, useState } from "react"
import { BiUpArrow } from 'react-icons/bi'
import { ApplicationTranslationSchema } from "../../types";
import { toastNotifyError, toastNotifySuccess } from '../common/ToastMessage'


type ObjectEditorProps = {
  sectionTitle: string
  translations: ApplicationTranslationSchema
  organizationColor: string
  updateSectionTranslations: Function
}
export const ObjectEditor = ({ sectionTitle, translations, updateSectionTranslations, organizationColor }: ObjectEditorProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const [localTranslations, setLocalTranslations] = useState<ApplicationTranslationSchema | null>(null)


  const updateTranslationObject = (updatePath: string, newValue: string) => {
    if (!localTranslations) return
    let prev = localTranslations ? localTranslations : {}
    setLocalTranslations({ ...prev, [updatePath]: newValue })
  }

  const updateNestedTranslationObject = (updatePath: string, newValue: ApplicationTranslationSchema) => {
    if (!localTranslations) return
    let prev = localTranslations ? localTranslations : {}
    setLocalTranslations({ ...prev, [updatePath]: newValue })
  }

  const renderTranslations = (xTranslations: ApplicationTranslationSchema) => {
    // This function is based on that we do not nest more than once 
    const nestedRender = (subpath: string, nestedTranlations: any) => {
      let nestedEntries = Object.entries(nestedTranlations)
      return nestedEntries.map((nestedEntry: Array<any>) => {

        if (typeof nestedEntry[1] === "string") {
          return (
            <div className="custom-translations-filed-pair">
              <label>{nestedEntry[0]}</label>
              <input
                type="text"
                value={nestedEntry[1] as string}
                onChange={e => {
                  let entryValue = xTranslations[subpath] as ApplicationTranslationSchema
                  let nesteEntryKey = nestedEntry[0] as string
                  entryValue[nesteEntryKey] = e.target.value

                  updateNestedTranslationObject(`${subpath}`, entryValue)
                }}
              />
            </div>
          )
        } else {
          return (
            <div className="nested-translations">
              <h4 style={{ textAlign: 'left' }}>{nestedEntry[0]}</h4>
              {nestedRender(`${subpath}.${nestedEntry[0]}`, nestedEntry[1])}
            </div>
          )
        }
      })
    }

    let translationsEntries = Object.entries(xTranslations)
    return translationsEntries.map((entry: Array<any>) => {
      if (typeof entry[1] === "string") {
        return (
          <div className="custom-translations-filed-pair" key={entry[0]}>
            <label>{entry[0]}</label>
            <input value={entry[1] as string} type="text" onChange={e => updateTranslationObject(`${entry[0]}`, e.target.value)} />
          </div>)
      } else {
        return (
          <div className="nested-translations">
            <h4 style={{ textAlign: 'left' }}>{entry[0]}</h4>
            {nestedRender(entry[0], entry[1])}
          </div>
        )
      }
    })
  }


  const verifyAndSaveTranslations = () => {
    const verifyTranslations = (translations: ApplicationTranslationSchema) => {
      let translationsEntries = Object.entries(translations)
      let isValid = true
      translationsEntries.forEach((section) => {
        if (typeof section[1] === "string") {
          isValid = isValid && section[1].length > 0
        } else {
          isValid = isValid && verifyTranslations(section[1])
        }
      })
      return isValid
    }
    console.log('localTransalations', localTranslations)
    if (!localTranslations) {
      toastNotifyError('Could not find translations to validate and save.')
      return
    }
    let isValid = verifyTranslations(localTranslations)

    if (isValid) {
      updateSectionTranslations(sectionTitle, localTranslations)
      return
    }
    toastNotifyError('An empty field was found, please fill in all translations.')

  }

  useEffect(() => {
    setLocalTranslations(translations)
  }, [translations])

  const classes = collapsed ? "rotate-up" : "rotate-down"

  return (
    <div className="app-translation-card" id={sectionTitle} >
      <div className="flex-center-align" style={{ justifyContent: 'space-between' }}>
        <h3>{sectionTitle}</h3>
        <BiUpArrow className={classes} color="black" onClick={() => setCollapsed(!collapsed)} />
      </div>
      <div style={{ padding: '20px' }}>
        {collapsed ? null : localTranslations ? renderTranslations(localTranslations) : null}
        {!collapsed && localTranslations ? (
          <button className="btn" style={{ background: organizationColor }} onClick={() => {
            verifyAndSaveTranslations()
          }
          }>Save</button>
        ) : null}
      </div>
    </div>
  )
}