import { useState, useEffect } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

type DialogProps = {
  title: string
  dialogContent: any
  buttonActionText: string
  open: boolean
  closeText: string
  cancelFunction: Function
  actionFunction: Function
  organizationColor?: string
  onClickOutSide?: Function
  showAction?: boolean
}
export const SimpleDialog = ({ open, title, dialogContent, buttonActionText, closeText, cancelFunction, actionFunction, organizationColor, onClickOutSide, showAction = true }: DialogProps) => {

  const DialogContent = DialogPrimitive.Content;
  const DialogTitle = DialogPrimitive.Title;
  const DialogDescription = DialogPrimitive.Description;
  const DialogClose = DialogPrimitive.Close;
  const [openDialog, setDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    setDialogOpen(open)
  }, [open])

  const handdleOutsideClick = () => {
    if (onClickOutSide) {
      onClickOutSide()
    }
  }
  const classes = organizationColor ? "btn" : "btn btn-default"
  const styles = organizationColor ? { backgroundColor: organizationColor } : {}
  return (
    <DialogPrimitive.Root open={openDialog} onOpenChange={setDialogOpen}>
      <DialogPrimitive.Overlay className="dialogOverlay" />
      <DialogContent className="dialogContent confirm-type-dialog" onPointerDownOutside={handdleOutsideClick}>
        <DialogTitle className="dialogTitle">{title}</DialogTitle>
        <DialogDescription >
          {dialogContent}
        </DialogDescription>
        <DialogClose asChild onClick={() => cancelFunction()}>
          <button className={classes} style={styles}>{closeText}</button>
        </DialogClose>
        {showAction ? <DialogClose asChild>
          <button className={classes} style={styles} onClick={() => actionFunction()}>{buttonActionText}</button>
        </DialogClose> : null}
      </DialogContent>
    </DialogPrimitive.Root >
  )
}