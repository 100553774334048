export const ROUTE = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  PASSWORDRESET: "/organization/:orgId/password-reset",
  PROFILE: "/organization/:orgId/profile/:userId",
  EDITPROFILE: "/organization/:orgId/edit-profile",
  ABOUT: "/about",
  ORGANIZATION_HELP_PAGE: "/organization/:orgId/help",
  ORGANIZATION_EDIT_HELP_PAGE: "/organization/:orgId/authenticated/edit-help-page",
  ORGANIZATION: "/organization/:orgId",
  ORGANIZATIONABOUT: "/organization/:orgId/about",
  GROUPS: "/organization/:orgId/authenticated/groups",
  COLLECTION: "/organization/:orgId/collections/:collectionId",
  TERMS_CONDITIONS: "/organization/:orgId/terms-and-conditions",
  GALLERYSHOW: "/organization/:orgId/collections/:collectionId/gallery",
  PROJECTOR: "/organization/:orgId/collections/:collectionId/projector",
  CONTRIBUTION: "/organization/:orgId/collections/:collectionId/contribution/:id",
  EDIT_CONTRIBUTION: "/organization/:orgId/authenticated/:collectionId/contribution/:id/edit-contribution",
  AUTH_ROUTES_PREFIX: "/organization/:orgId/authenticated",
  CREATE_CONTRIBUTION: "/organization/:orgId/authenticated/collections/:collectionId/create-contribution",
  CREATE_COLLECTION: "/organization/:orgId/authenticated/collections/create-collection",
  EDIT_COLLECTION: "/organization/:orgId/authenticated/collections/edit-collection",
  EDIT_ORGANIZATION: "/organization/:orgId/authenticated/edit-organization",
  EDIT_ORGANIZATION_TRANSLATIONS: "/organization/:orgId/authenticated/edit-organization-translations",
  USER_ADMINISTRATION: "/organization/:orgId/authenticated/user-administration",
  EDIT_PLATFORM: "/edit-platform",
  TAGGED: "/organization/:orgId/tagged",
};
