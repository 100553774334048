import "./translations/i18n";
import "./css/App.css";
import { BrowserRouter, Switch, Route, Redirect, useHistory } from "react-router-dom";
import Layout from "./components/layout/Layout";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useEffect, useState, useCallback } from "react";
import { ROUTE } from "./routes";
import { Collections } from "./components/collections/Collections";
import { CreateContribution } from "./components/CreateContribution/CreateContribution";
import { Collection } from "./components/collection/Collection";
import { UserProvider, useUserContext } from "./context/UserContext";
import { ApplicationProvider, useApplicationContext } from "./context/ApplicationContext";
import { OrganizationProvider, useOrganizationContext } from "./context/OrganizationContext";
import { Contribution } from "./components/contribution/Contribution";
import { EditContribution } from "./components/contribution/EditContribution";
import { Groups } from "./components/admin/Groups";
import { OrganizationAbout } from "./components/organizations/About";
import { Organizations } from "./components/organizations/Organizations";
import { CreateCollection } from "./components/admin/CreateCollection";
import { Login } from "./components/login/Login";
import { Register } from "./components/login/Register";
import { PasswordReset } from "./components/login/PwReset";
import { ProfilePage } from "./components/profile/Profile";
import { EditProfile } from "./components/profile/EditProfile";
import { ApplicationStorage } from "./api/applicationStorage";
import ScrollToTop from "./ScrollToTop";
import { Api } from "./api/api";
import { About } from "./components/informationPages/About";
import { Guides } from "./components/informationPages/HelpGuides";
import { EditCollection } from "./components/admin/EditCollection";
import { ToastContainer } from "react-toastify";
import { TermsAndConditions } from "./components/organization/TermsAndConditions";
import { EditOrganization } from "./components/admin/EditOrganization";
import { UserAdministration } from "./components/admin/UserAdministration";
import { KeyString } from "./types/types";
import { EditPlatformPages } from "./components/platformAdmin/EditPlatformPages";
import { Gallery } from "./components/gallery/Gallery";
import { EditHelpPages } from "./components/admin/EditHelpPage";
import { EditOrganizationApplicationTranslations } from "./components/admin/EditOrganizationApplicationTrans";
import { Projector } from "./components/gallery/Projector";
import { Tagged } from "./components/tagged/Tagged";

const runEnv = process.env.REACT_APP_RUN_ENV || "dev";
interface AuthRouteParams {
  component: any;
  path: any;
}

const AuthenticatedRoute = ({ component, path }: AuthRouteParams) => {
  const userContext = useUserContext();
  if (userContext?.isFetchingUser) {
    return <div className="loader--loading" style={{ height: "100vh" }}></div>;
  }
  if (!userContext || !userContext.loggedInUser) {
    return <Redirect to={ROUTE.LOGIN} />;
  }

  return <Route {...path} component={component} />;
};

const RestrictedRoutes = () => {
  return (
    <Switch>
      <Route exact path={ROUTE.CREATE_CONTRIBUTION} component={() => <CreateContribution environment={runEnv} />} />
      <Route exact path={ROUTE.EDIT_CONTRIBUTION} component={() => <EditContribution environment={runEnv} />} />
      <Route exact path={ROUTE.CREATE_COLLECTION} component={() => <CreateCollection environment={runEnv} />} />
      <Route exact path={ROUTE.EDIT_COLLECTION} component={() => <EditCollection environment={runEnv} />} />
      <Route exact path={ROUTE.EDIT_ORGANIZATION} component={() => <EditOrganization environment={runEnv} />} />
      <Route exact path={ROUTE.USER_ADMINISTRATION} component={() => <UserAdministration environment={runEnv} />} />
      <Route exact path={ROUTE.ORGANIZATION_EDIT_HELP_PAGE} component={() => <EditHelpPages environment={runEnv} />} />
      <Route exact path={ROUTE.EDIT_ORGANIZATION_TRANSLATIONS} component={() => <EditOrganizationApplicationTranslations environment={runEnv} />} />
    </Switch>
  );
};

const Organization = ({ match, location }: any) => {
  const orgContext = useOrganizationContext();
  const AppCtx = useApplicationContext();
  const history = useHistory();
  const [doHomeRedirect, setDoHomeRedirect] = useState<boolean>(false);
  const [isGettingOrg, setIsGettingOrg] = useState<boolean>(true);

  const setLanguage = useCallback(
    async (orgLanguage: string) => {
      let userLangSelection = ApplicationStorage.getUserLanguageSelection();
      if (userLangSelection) {
        AppCtx.setLanguageInContext(userLangSelection);
      } else {
        AppCtx.setLanguageInContext(orgLanguage);
      }
    },
    [AppCtx]
  );

  const getOrganization = useCallback(
    async (orgId: string) => {
      try {
        const response = await Api.getOrganization(orgId);
        orgContext?.addOrganizationToContext(response);
        setLanguage(response.language);
        setIsGettingOrg(false);
      } catch (error: any) {
        console.error(error);
      }
    },
    [orgContext, setLanguage]
  );

  useEffect(() => {
    const pathMatch = /\/organization\/([^]+)(\/|$)/.test(match.path);
    if (pathMatch) {
      const pathParams = match.params;
      const orgId = pathParams.orgId;
      if (!orgContext || !orgContext.organization) {
        getOrganization(orgId);
      } else {
        if (orgContext.organization?.id !== orgId) {
          setDoHomeRedirect(true);
          return;
        }
        setLanguage(orgContext.organization.language);
        setIsGettingOrg(false);
      }
    }
  }, [history, match, orgContext, getOrganization, setLanguage]);

  if (isGettingOrg) {
    return <div className="loader--loading" style={{ height: "100vh" }}></div>;
  }
  if (doHomeRedirect) {
    return <Redirect to={ROUTE.HOME} />;
  }

  const timeout = { enter: 3000, exit: 2000 };
  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={location.key}
        in={true}
        timeout={timeout}
        classNames="fade"
        addEndListener={(node: HTMLElement, done: () => void) => {
          node.addEventListener("transitionend", done, false);
        }}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <div>
          <Switch location={location}>
            <Route exact path={ROUTE.GROUPS} component={() => <Groups environment={runEnv} />} />
            <Route exact path={ROUTE.ORGANIZATIONABOUT} component={() => <OrganizationAbout environment={runEnv} />} />
            <Route exact path={ROUTE.ORGANIZATION_HELP_PAGE} component={() => <Guides environment={runEnv} />} />
            <Route exact path={ROUTE.CONTRIBUTION} component={() => <Contribution environment={runEnv} />} />
            <Route exact path={ROUTE.TERMS_CONDITIONS} component={() => <TermsAndConditions environment={runEnv} />} />
            <Route exact path={ROUTE.COLLECTION} component={() => <Collection environment={runEnv} />} />
            <Route exact path={ROUTE.ORGANIZATION} component={() => <Collections environment={runEnv} />} />
            <Route exact path={ROUTE.TAGGED} component={() => <Tagged environment={runEnv} />} />

            <Route exact path={ROUTE.PROFILE} component={() => <ProfilePage environment={runEnv} />} />

            <AuthenticatedRoute path={ROUTE.AUTH_ROUTES_PREFIX} component={RestrictedRoutes} />
          </Switch>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

const App = () => {
  const [roles, setRoles] = useState<KeyString | null>(null);
  const userIdToken = ApplicationStorage.getToken();
  useEffect(() => {
    const getRoles = async () => {
      let r = await Api.getRoles();
      setRoles(r);
    };
    if (!roles) {
      getRoles();
    }
  }, [roles]);

  if (!roles) {
    return <div className="loader--loading" style={{ height: "100vh" }}></div>;
  }

  return (
    <BrowserRouter>
      <ApplicationProvider>
        <OrganizationProvider>
          <ToastContainer />
          <Switch>
            <Route exact path={ROUTE.GALLERYSHOW} component={Gallery} />
            <Route exact path={ROUTE.PROJECTOR} component={Projector} />

            <Route>
              <ScrollToTop />
              <UserProvider token={userIdToken} roles={roles}>
                <Layout>
                  <Switch>
                    <Route exact path={ROUTE.LOGIN} component={() => <Login environment={runEnv} />} />
                    <Route exact path={ROUTE.REGISTER} component={() => <Register environment={runEnv} />} />
                    <Route exact path={ROUTE.PASSWORDRESET} component={() => <PasswordReset environment={runEnv} />} />
                    <Route exact path={ROUTE.HOME} component={() => <Organizations environment={runEnv} />} />
                    <Route exact path={ROUTE.EDITPROFILE} component={() => <EditProfile environment={runEnv} />} />

                    <Route exact path={ROUTE.ABOUT} component={() => <About environment={runEnv} />} />
                    <Route path={ROUTE.ORGANIZATION} component={Organization} />
                    <AuthenticatedRoute path={ROUTE.EDIT_PLATFORM} component={() => <EditPlatformPages environment={runEnv} />} />
                  </Switch>
                </Layout>
              </UserProvider>
            </Route>
          </Switch>
        </OrganizationProvider>
      </ApplicationProvider>
    </BrowserRouter>
  );
};

export default App;
