import RichTextEditor, { EditorValue } from 'react-rte';
import { useState } from "react"

import { textEditorConfig, simpleEditorConfig } from "../../helpers/constants"

type EditorProps = {
  onChange: (value: string) => void
  initialValue: string
  simple?: true
  disabled?: boolean
}

export const TextEditor = ({ onChange, simple, initialValue, disabled = false }: EditorProps) => {
  const [editorState, setEditorState] = useState<EditorValue>(
    initialValue ? RichTextEditor.createValueFromString(initialValue, 'html') : RichTextEditor.createEmptyValue());

  const config = simple ? simpleEditorConfig : textEditorConfig

  const onEditorChange = (value: EditorValue) => {
    setEditorState(value)
    onChange(editorState.toString("html"))
  }

  return (
    <div className="text-editor">
      <RichTextEditor toolbarConfig={config} readOnly={disabled}
        value={editorState}
        onChange={onEditorChange}
      />
    </div>
  )
}

