import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useOrganizationContext } from "../../context/OrganizationContext"
import { getHelpPageTranslation } from '../../helpers/helperFunctions'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'

const GuidesComponent = () => {
  const { i18n } = useTranslation()
  const orgCtx = useOrganizationContext()
  const [translatedText, setTranslatedText] = useState<string>()
  const [lastLang, setLastLang] = useState<string>()
  const { getTranslation } = useCustomTranslation(orgCtx?.organization?.id || '')

  useEffect(() => {
    if (orgCtx?.helpPageTranslations && !translatedText) {
      let text = getHelpPageTranslation(orgCtx.helpPageTranslations, i18n.language)
      if (text) {
        setTranslatedText(text)
        setLastLang(i18n.language)
      }
    }
  }, [orgCtx?.helpPageTranslations, i18n, translatedText])


  useEffect(() => {
    if (translatedText && i18n.language !== lastLang && orgCtx?.helpPageTranslations) {
      let text = getHelpPageTranslation(orgCtx.helpPageTranslations, i18n.language)
      if (text) {
        setTranslatedText(text)
        setLastLang(i18n.language)
      }
    }
  }, [i18n.language, orgCtx, translatedText, lastLang])

  const renderHelpPage = () => {
    if (!orgCtx || !orgCtx.organization) return null
    let text = translatedText || ' <p>Coming soon...</p>'
    return (
      <div dangerouslySetInnerHTML={{ __html: text }} />
    )
  }

  return (
    <div className="app-content text text-pages help-page">
      <h1 className="organization-help-page-header">{getTranslation('HELP.HEADING')}</h1>
      <div className="about-organization-text">
        {renderHelpPage()}
      </div>
    </div>
  )
}

export const Guides = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="helpGuides" router={history}>
        <GuidesComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <GuidesComponent {...props} />
  )
}