import { useState } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { CustomCheckbox } from './CustomCheckbox'


type DialogProps = {
  title: string
  subTitle?: string
  buttonConfirmText: string
  checkboxText: string
  danger?: boolean
  triggerButtonText: string
  onConfirmClick: Function
  translationFunction: Function
}
export const ConfirmationDialog = ({ title, subTitle, buttonConfirmText, triggerButtonText, checkboxText, onConfirmClick, translationFunction, danger = false }: DialogProps) => {
  const DialogTrigger = DialogPrimitive.Trigger;
  const DialogContent = DialogPrimitive.Content;
  const DialogTitle = DialogPrimitive.Title;
  const DialogDescription = DialogPrimitive.Description;
  const DialogClose = DialogPrimitive.Close;

  const [userConfirmed, setUserConfirmed] = useState<boolean>(false)

  const confirmClasses = danger ? 'btn-danger' : 'btn btn-default'

  return (
    <DialogPrimitive.Root>
      <DialogPrimitive.Overlay className="dialogOverlay" />
      <DialogTrigger asChild >
        <button className={confirmClasses}>{triggerButtonText}</button>
      </DialogTrigger>
      <DialogContent className="dialogContent confirm-type-dialog">
        <DialogTitle className="dialogTitle">{title}</DialogTitle>
        {subTitle && (
          <DialogDescription>
            {subTitle}
          </DialogDescription>)}
        <div style={{ padding: '0 30%' }}>
          <CustomCheckbox text={checkboxText} value={userConfirmed} onUpdate={(checked: boolean) => { setUserConfirmed(checked) }} />
        </div>
        <DialogClose asChild>
          <button className="btn btn-default" onClick={() => {
            setUserConfirmed(false)
          }}>{translationFunction('GLOBAL.CANCEL')}</button>
        </DialogClose>
        <DialogClose asChild disabled={!userConfirmed}>
          <button className={confirmClasses} onClick={() => {
            setUserConfirmed(false)
            onConfirmClick()
          }}>{buttonConfirmText}</button>
        </DialogClose>

      </DialogContent>
    </DialogPrimitive.Root >
  )
}