import "../../css/Collection.css";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef, useCallback } from "react";
import { CollectionType, CollectionMediaType } from "../../types";
import { getCollectionKeyInLang, getCollectionTitleOrDescriptionInLang } from "../../helpers/helperFunctions";
import { useUserContext } from "../../context/UserContext";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { ROUTE } from "../../routes";
import { MediaFileRenderer } from "../common/MediaFileRenderer";
import { useFetch } from "../../hooks/customScrollHook";
import { Can } from "../../helpers/permissionsHelper";
import { AdminApi } from "../../api/admin";
import { Api } from "../../api/api";
import { toastNotifyError, toastNotifySuccess } from "../common/ToastMessage";
import { useCustomTranslation } from "../../helpers/CustomTranslationsComps";
import ErrorBoundary from "../../errorHandlers/ErrorBoundry";

const CollectionComponent = () => {
  const { i18n } = useTranslation();
  const { orgId, collectionId }: any = useParams();
  const userCtx = useUserContext();
  const organizationCtx = useOrganizationContext();
  let history = useHistory();

  const location = useLocation();
  const [collection, setCollection] = useState<CollectionType | undefined>(undefined);
  const [images, setImages] = useState<CollectionMediaType[]>([]);
  const [pagniationId, setPaginationId] = useState<number>(0);
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const observer = useRef<IntersectionObserver>();
  const { loading, list } = useFetch(pagniationId, collectionId);

  const { getTranslation } = useCustomTranslation(orgId);

  const lastImageElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPaginationId(images[images.length - 1].paginationId);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, images]
  );

  const fetchData = useCallback(async () => {
    try {
      let collectionData = await Api.getCollection(collectionId);
      setCollection(collectionData);
    } catch (error) {
      history.push(ROUTE.HOME);
    }
  }, [collectionId, history]);
  useEffect(() => {
    if (!list.length) return;
    setImages(list);
  }, [list]);

  useEffect(() => {
    if (!location.state) {
      fetchData();
    } else {
      const { collectionData }: any = location.state;
      setCollection(collectionData);
    }
  }, [location, fetchData]);

  const clickedContribution = (contributionId: String, collection: CollectionType) => {
    history.push({ pathname: `/organization/${orgId}/collections/${collectionId}/contribution/${contributionId}`, state: { collectionData: collection } });
  };

  const clickedExport = async () => {
    if (!collection || !organizationCtx || !organizationCtx.organization) {
      return;
    }
    setIsExporting(true);

    try {
      await AdminApi.createCollectionExport(organizationCtx.organization.id, collection.id);
      toastNotifySuccess("Export successfully created.");
    } catch (error: any) {
      toastNotifyError("Could not generate export");
    } finally {
      setIsExporting(false);
    }
  };

  const submitButtonClasses = isExporting ? "loader-btn loader-btn--loading " : "loader-btn btn";

  const renderButtons = () => {
    if (!collection || !organizationCtx || !organizationCtx.organization) return null;
    const slideShow =
      images.length > 1 ? (
        <Link className="btn link-text" style={{ backgroundColor: `${organizationCtx.organization.color}` }} to={{ pathname: `${collection.id}/gallery`, state: { collectionId: collection.id, orgId: collection.organizationId } }}>
          {getTranslation("COLLECTIONS_PAGE.START_GALLERY")}
        </Link>
      ) : null;
    if (!userCtx || !userCtx.loggedInUser) {
      return (
        <>
          <Link className="btn btn-contribute-big link-text" style={{ backgroundColor: `${organizationCtx.organization.color}` }} to={{ pathname: ROUTE.LOGIN, state: { redirectOrg: orgId } }}>
            {getTranslation("COLLECTIONS_PAGE.NOT_SIGNED_IN_CONTRIBUTE")}
          </Link>
          {slideShow}
        </>
      );
    }

    return (
      <>
        <Can
          userRoles={userCtx?.loggedInUser?.roles}
          requiredPermission={userCtx?.actionRoles?.SUPER_ADMIN}
          organizationId={organizationCtx?.organization?.id}
          yes={() => (
            <button className={submitButtonClasses} onClick={clickedExport} style={{ backgroundColor: `${organizationCtx?.organization?.color || " #556271"}`, margin: "15px" }}>
              <span className="loader-btn__text">{getTranslation("ADMIN.COLLECTION.EXPORT_BUTTON")}</span>
            </button>
          )}
        />
        {collection.status === "ACTIVE" && (
          <Link className="btn btn-contribute link-text" style={{ backgroundColor: `${organizationCtx.organization.color} ` }} to={{ pathname: `/organization/${orgId}/authenticated/collections/${collectionId}/create-contribution`, state: { collectionData: collection } }}>
            {getTranslation("GLOBAL.CONTRIBUTE")}
          </Link>
        )}

        {slideShow}
      </>
    );
  };

  const getMediaPhotographerName = (media: CollectionMediaType) => {
    if (media.isPhotographer) {
      return media.contributionUserAlias && media.contributionUserAlias.length ? media.contributionUserAlias : media.contributionUserFirstName + " " + media.contributionUserLastName;
    }

    if (media.otherPhotographer) {
      return media.otherPhotographer;
    }

    return "-";
  };

  if (collection === undefined) {
    return <div className="loader--loading" style={{ height: "100vh" }}></div>;
  }

  return (
    <div className="App">
      <div className="collection-header" style={{ backgroundImage: `url(${collection.imgUrl})` }}>
        <div className="collection-header-content">
          <div className="collection-header-wrapper">
            <h1>{getCollectionKeyInLang(collection, "title", i18n.language)}</h1>
          </div>
          <div className="collection-header-content-right">{renderButtons()}</div>
        </div>
      </div>
      <div className="app-content collection-page">
        <div className="collection-page-description">
          <h4 dangerouslySetInnerHTML={{ __html: getCollectionTitleOrDescriptionInLang(collection, "description", i18n.language) }} />
          {collection.status === "ACTIVE" ? (
            userCtx && userCtx.loggedInUser ? (
              <Link className="btn btn-contribute-big link-text" style={{ backgroundColor: `${organizationCtx?.organization?.color} ` }} to={{ pathname: `/organization/${orgId}/authenticated/collections/${collectionId}/create-contribution`, state: { collectionData: collection } }}>
                {getTranslation("GLOBAL.CONTRIBUTE")}
              </Link>
            ) : (
              <Link className="btn btn-contribute-big link-text" style={{ backgroundColor: `${organizationCtx?.organization?.color}` }} to={{ pathname: ROUTE.LOGIN, state: { redirectOrg: orgId } }}>
                {getTranslation("COLLECTIONS_PAGE.NOT_SIGNED_IN_CONTRIBUTE")}
              </Link>
            )
          ) : null}
        </div>
        <div className="contributions-container">
          <section className="collection-contributions-list">
            {images.map((image: CollectionMediaType, i: number) => {
              const isLastElement = images.length === i + 1;
              return (
                <div ref={isLastElement ? lastImageElementRef : null} key={image.id}>
                  <MediaFileRenderer mediaFile={image} fileId={image.id} idKey={image.id} videoStyles={{ opacity: "0.4" }} classes="collection-contribution" onClickHandler={() => clickedContribution(image.contributionId, collection)} showInformationOnHover={{ showHover: true, photographer: getMediaPhotographerName(image) }} />
                </div>
              );
            })}
          </section>
        </div>
      </div>
    </div>
  );
};

export const Collection = ({ ...props }) => {
  const history = useHistory();
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="Collection" router={history}>
        <CollectionComponent {...props} />
      </ErrorBoundary>
    );
  }
  return <CollectionComponent {...props} />;
};
