import "../../css/TextPages.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GoSettings } from "react-icons/go";
import { AiOutlineCloudServer, AiOutlineExport } from "react-icons/ai";
import { IoMdPeople } from "react-icons/io";
import { useApplicationContext } from "../../context/ApplicationContext";
import { getSystemPageTranslation } from "../../helpers/helperFunctions";
import { TranslationType } from "../../types";
import { Carousel } from "./Carousel";
import ErrorBoundary from "../../errorHandlers/ErrorBoundry";

const galleryImages = [
  "https://s3.se-east-1.ip-only.cloud:443/museet_prod/system/MAS-AboutSystem-Top01.png",
  "https://s3.se-east-1.ip-only.cloud:443/museet_prod/system/MAS-AboutSystem-Top02.png",
  "https://s3.se-east-1.ip-only.cloud:443/museet_prod/system/MAS-AboutSystem-Top03.png",
];

const leftImage =
  "https://s3.se-east-1.ip-only.cloud:443/museet_prod/system/MAS-AboutSystem-Left01.png";
const AboutComponent = () => {
  const { i18n } = useTranslation();
  const appCtx = useApplicationContext();
  const [pageTranslations, setPageTranslations] = useState<TranslationType>();
  const [lastLang, setLastLang] = useState<string>();

  useEffect(() => {
    if (appCtx.systemPageTranslations && !pageTranslations) {
      let translations = getSystemPageTranslation(appCtx.systemPageTranslations, i18n.language);
      if (translations) {
        setPageTranslations(translations);
        setLastLang(i18n.language);
      }
    }
  }, [appCtx.systemPageTranslations, i18n, pageTranslations]);

  useEffect(() => {
    if (pageTranslations && i18n.language !== lastLang && appCtx.systemPageTranslations) {
      let translations = getSystemPageTranslation(appCtx.systemPageTranslations, i18n.language);
      if (translations) {
        setPageTranslations(translations);
        setLastLang(i18n.language);
      }
    }
  }, [i18n.language, appCtx, pageTranslations, lastLang]);

  if (!pageTranslations) {
    return <div className="loader--loading" style={{ height: "100vh" }}></div>;
  }

  return (
    <div className="app-content">
      <div className="">
        <div className="about-page-hero-section">
          <div className="hero-row">
            <div className="hero-text">
              <h1>Connect To Collect</h1>
              <p> {pageTranslations.whatIs}</p>
            </div>
          </div>
          <div className="hero-row hero-picure-area">
            <Carousel images={galleryImages} />
          </div>
        </div>
        <div className="about-page-medium-row">
          <div className="about-page-text-section">
            <div className="text-img-section">
              <div className="hero-row hero-picure-area">
                <img
                  style={{ width: "100%", height: 500 }}
                  src={leftImage}
                  alt="How it works"
                  key="howitWorksImage"
                />
              </div>
              <div>
                <h2>{pageTranslations.howItWorks.title}</h2>
                <p className="formatted-text-class">{pageTranslations.howItWorks.text}</p>
              </div>
            </div>
            <div className="divider" />
            <div className="about-feautures-title">
              <h3>{pageTranslations.featuresTitle}</h3>
            </div>
            <div className="about-page-features">
              <div className="about-page-features-group">
                <div className="feature-icon">
                  <GoSettings size="2em" className="feature-icon-icon" color="rgb(94, 136, 158)" />
                </div>
                <div className="about-page-features-group-text">
                  <h4>{pageTranslations.features.dynamic.title}</h4>
                  <p>{pageTranslations.features.dynamic.text}</p>
                </div>
              </div>
              <div className="about-page-features-group">
                <div className="feature-icon">
                  <AiOutlineCloudServer size="2em" color="rgb(204, 93, 18)" />
                </div>
                <div className="about-page-features-group-text">
                  <h4>{pageTranslations.features.hosting.title}</h4>
                  <p>{pageTranslations.features.hosting.text}</p>
                </div>
              </div>
              <div className="about-page-features-group">
                <div className="feature-icon">
                  <AiOutlineExport size="2em" color="rgb(136, 136, 50)" />
                </div>
                <div className="about-page-features-group-text">
                  <h4>{pageTranslations.features.export.title}</h4>
                  <p>{pageTranslations.features.export.text}</p>
                </div>
              </div>
              <div className="about-page-features-group">
                <div className="feature-icon">
                  <IoMdPeople size="2em" color="rgb(124, 105, 158)" />
                </div>
                <div className="about-page-features-group-text">
                  <h4>{pageTranslations.features.privacy.title}</h4>
                  <p>{pageTranslations.features.privacy.text}</p>
                </div>
              </div>
            </div>
            <div className="divider" />
            <h2>{pageTranslations.origin.title}</h2>
            <div
              className="system-editor-text"
              dangerouslySetInnerHTML={{ __html: pageTranslations.origin.text }}
            />
            <h2>{pageTranslations.about.title}</h2>
            <div
              className="system-editor-text"
              dangerouslySetInnerHTML={{ __html: pageTranslations.about.text }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const About = ({ ...props }) => {
  const history = useHistory();
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="About" router={history}>
        <AboutComponent {...props} />
      </ErrorBoundary>
    );
  }
  return <AboutComponent {...props} />;
};
