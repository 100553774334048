import '../../css/EditOrganization.css'
import { useEffect, useState, useCallback } from "react"
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import { toastNotifyError, toastNotifySuccess } from '../common/ToastMessage'
import {
  getDefaultSchema,
  getFullSchema
} from '../../helpers/CustomTranslationsComps'
import { AdminApi } from '../../api/admin';
import { ObjectEditor } from './CustomObjectEditor'
import { useOrganizationContext } from '../../context/OrganizationContext';
import { useUserContext } from '../../context/UserContext';
import { LanguageSelector } from '../common/LanguageSelector'
import {
  ApplicationTranslationSchema,
  OrganizationAppTranslations,
  UpdateOrganizationAppTranslationPayload
} from '../../types'
import { ROUTE } from '../../routes'
import { userHasPermission } from '../../helpers/permissionsHelper'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'


const EditOrganizationApplicationTranslationsComponent = () => {
  const { t } = useTranslation()
  const orgCtx = useOrganizationContext()
  const userCtx = useUserContext()
  const history = useHistory()
  const [translationsObject, setTranslationsObject] = useState<ApplicationTranslationSchema | null>(null)
  const [updateHasOccured, setUpdateHasOccured] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [lang, setLang] = useState<string>('en')

  const renderObjects = () => {
    if (!translationsObject) return null
    const tableEntries = Object.entries(translationsObject)

    return tableEntries.map((entry: any) => {
      return <ObjectEditor sectionTitle={entry[0]} translations={entry[1]} updateSectionTranslations={updateTranslationObject} organizationColor={getOrganizationColor} />
    })
  }

  const updateTranslationObject = (updatePath: string, newSectionTranslations: ApplicationTranslationSchema) => {
    if (!translationsObject) return

    setTranslationsObject(prev => ({ ...prev, [updatePath]: newSectionTranslations }))
    if (!updateHasOccured) {
      setUpdateHasOccured(true)
    }
    toastNotifySuccess('Local save successful, ready to submit!')

  }

  const submitTranslations = async () => {
    if (!orgCtx || !orgCtx.organization) return
    let orgId = orgCtx!.organization!.id
    setIsSubmitting(true)

    try {
      let test = JSON.parse(JSON.stringify(translationsObject))
      const data: UpdateOrganizationAppTranslationPayload = {
        languageCode: lang,
        translations: JSON.stringify(test)
      }
      const response = await AdminApi.updateOrganizationAppTranslations(orgId, data)
      orgCtx?.updateOrganizationAppTranslations(response)
      toastNotifySuccess('Successfully Updated Translations')
    } catch (error) {
      console.error(error)
      toastNotifyError('Something went wrong, could not save translations')
    }
    finally {
      setIsSubmitting(false)
    }
  }


  const setUpInitialSchema = useCallback((appTranslations: OrganizationAppTranslations[]) => {
    let translations = appTranslations.filter((t: OrganizationAppTranslations) => t.languageCode === lang)

    if (!translations.length) {
      let defaultSchema = getDefaultSchema(lang)
      setTranslationsObject(defaultSchema)
    } else {

      let existingTranslationsForLang: ApplicationTranslationSchema = JSON.parse(JSON.parse(translations[0].translation))
      let updatedCustomerTranslations = getFullSchema(existingTranslationsForLang, lang)
      setTranslationsObject(updatedCustomerTranslations)
    }

  }, [lang])

  useEffect(() => {

    if (!userCtx || !userCtx.loggedInUser || !orgCtx || !orgCtx.organization) {
      history.push(ROUTE.HOME)
    } else {

      const orgId = orgCtx.organization.id
      const userRoles = userCtx.loggedInUser.roles

      if (!(userHasPermission(userRoles, userCtx.actionRoles.SUPER_ADMIN, orgId, userCtx.userIsPlaformAdmin) || userHasPermission(userRoles, userCtx.actionRoles.ADMIN, orgId, userCtx.userIsPlaformAdmin))) {
        history.push(ROUTE.HOME)
      }
      if (!translationsObject) {
        setUpInitialSchema(orgCtx.organization.appTranslations)
      }
    }
  }, [userCtx, orgCtx, history, setUpInitialSchema])


  useEffect(() => {
    let translatons = orgCtx && orgCtx?.organization ? orgCtx.organization.appTranslations : []
    setUpInitialSchema(translatons)
    setUpdateHasOccured(false)
  }, [lang, orgCtx, setUpInitialSchema])

  const submitButtonClasses = isSubmitting ? 'loader-btn loader-btn--loading btn-stnd-al' : 'loader-btn btn-stnd-al'
  const getOrganizationColor = orgCtx && orgCtx.organization ? orgCtx.organization.color : '#556271'
  if (!translationsObject) {
    return <div className="loader--loading" style={{ height: '100vh' }}></div>
  }
  return (
    <div className="App">
      <div className="app-content edit-organization-page">
        <h2>{t('ADMIN.EDIT_ORG_TRANSLATIONS.HEADING')}</h2>
        <div className="edit-app-translations-container">
          <div className="edit-organization-lang-selector-wrapper">
            <label style={{ marginRight: '10px' }}>Language</label>
            <LanguageSelector
              onLanguageUpdate={(lang: string) => setLang(lang)}
              customClasses="edit-organization-lang-selector-custom-class"
              customLang={lang} />
          </div>

          {renderObjects()}
          <button className={submitButtonClasses} onClick={submitTranslations} disabled={!updateHasOccured} style={{ marginTop: '30px', backgroundColor: getOrganizationColor }}>
            <span className="loader-btn__text">{t('CREATE_CONTRIBUTION_PAGE.SUBMIT_BUTTON')}</span>
          </button>
        </div>
      </div>
    </div>
  )
}


export const EditOrganizationApplicationTranslations = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="EditOrganizationTranslations" router={history}>
        <EditOrganizationApplicationTranslationsComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <EditOrganizationApplicationTranslationsComponent {...props} />
  )
}