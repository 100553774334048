import { Header } from './Header'
import { Footer } from './Footer'


const Layout = ({ children }: any) => (
  <section>
    <Header />
    {children}
    <Footer />
  </section>
)

export default Layout