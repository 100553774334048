import axios from 'axios'
import {
  UpdateCollectionType,
  UpdateOrganizationPayload,
  UpdateUserRolesForOrganizationType,
  UpdatePlatformPayload,
  UpdateHelpPagePayload,
  UpdateOrganizationAppTranslationPayload
} from '../types'
import { CreateAuthHeaders } from './createAuthString'

const base_url = process.env.REACT_APP_API_URL || ""

export const AdminApi = (() => {

  const uploadCollection = async (postdata: FormData): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const respone = await axios.post(base_url + '/upload-collection', postdata, { headers: { ...authorizationHeader } })
      return respone.status
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'Error while submitting contribution'
    }
  }

  const getAllCollections = async (organizationId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.get(base_url + '/all-collections', { headers: { ...authorizationHeader }, params: { organizationId } })
      return response
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while fetching collections'
    }
  }
  const updateCollectionImage = async (collectionId: string, putData: FormData): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.put(base_url + '/update-collection-image', putData, { headers: { ...authorizationHeader }, params: { collectionId } })
      return response
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while fetching collections'
    }
  }
  const updateCollection = async (putData: UpdateCollectionType): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.put(base_url + '/update-collection', putData, { headers: { ...authorizationHeader } })
      return response
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while updateing collection image'
    }
  }
  const getLanguages = async (): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.get(base_url + '/languages', { headers: { ...authorizationHeader } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while '
    }
  }
  const getGroups = async (organizationId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.get(base_url + '/organization/' + organizationId + '/groups', { headers: { ...authorizationHeader }, params: { organizationId } })
      return response
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while fetching groups'
    }
  }
  const postGroup = async (organizationId: string, putData: any): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.post(base_url + '/organization/' + organizationId + '/groups', putData, { headers: { ...authorizationHeader } })
      return response
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while posting groups'
    }
  }
  const deleteGroup = async (groupId: string, organizationId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.delete(base_url + '/organization/' + organizationId + '/group/' + groupId, { headers: { ...authorizationHeader } })
      return response
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while deleting groups'
    }
  }
  const getOrgUsers = async (organizationId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.get(base_url + '/organization/' + organizationId + '/users', { headers: { ...authorizationHeader } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while getting org users'
    }
  }

  const getOrganizationContributions = async (organizationId: string, paginationId: number, direction: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.get(base_url + '/organization-contributions', { headers: { ...authorizationHeader }, params: { organizationId, paginationId, direction } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while getting organization contributions'
    }
  }
  const updateOrganizationLogo = async (organizationId: string, putData: FormData): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.put(base_url + '/update-organization-logo', putData, { headers: { ...authorizationHeader }, params: { organizationId } })
      return response
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while updating organization logo.'
    }
  }
  const updateOrganization = async (organizationId: string, putData: UpdateOrganizationPayload): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.put(base_url + '/update-organization', putData, { headers: { ...authorizationHeader }, params: { organizationId } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while updating organization.'
    }
  }

  const updateUserOrganizationRoles = async (organizationId: string, rolesData: UpdateUserRolesForOrganizationType): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.put(base_url + '/organization/user-roles', rolesData, { headers: { ...authorizationHeader }, params: { organizationId } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An unexpected error occured while trying to update user roles.'
    }
  }
  const suspendUser = async (userId: string, organizationId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.post(base_url + '/user/suspend', null, { headers: { ...authorizationHeader }, params: { organizationId, userId } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An unexpected error occured while trying to update user status.'
    }
  }
  const activateUser = async (userId: string, organizationId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.post(base_url + '/user/activate', null, { headers: { ...authorizationHeader }, params: { organizationId, userId } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An unexpected error occured while trying to update user status.'
    }
  }

  const getContributionFields = async (organizationId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.get(base_url + '/contribution-fields', { headers: { ...authorizationHeader }, params: { organizationId } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while getting contributions fields'
    }
  }

  const getOrganizationContributionsSummary = async (organizationId: string,): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      let urlString = base_url + '/organization/' + organizationId + '/contributions/summary'
      const response = await axios.get(urlString, { headers: { ...authorizationHeader } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while getting organization contributions'
    }
  }
  const createCollectionExport = async (organizationId: string, collectionId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      let urlString = base_url + '/organization/' + organizationId + '/collection/' + collectionId + '/export'
      const response = await axios.post(urlString, null, { headers: { ...authorizationHeader } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while creating collectoin export'
    }
  }

  const updateOrganizationHelpPages = async (organizationId: string, data: UpdateHelpPagePayload): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      let urlString = base_url + '/organization/' + organizationId + '/help-page'
      const response = await axios.put(urlString, data, { headers: { ...authorizationHeader } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while updating organization help page translations'
    }
  }
  const updateSystemPages = async (data: UpdatePlatformPayload): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      let urlString = base_url + '/system-page'
      const response = await axios.put(urlString, data, { headers: { ...authorizationHeader } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while updating organization help page translations'
    }
  }
  const getCollectionStatusList = async (organizationId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      let urlString = base_url + '/collection/status-list'
      const response = await axios.get(urlString, { headers: { ...authorizationHeader }, params: { organizationId } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while fetching collection status list'
    }
  }

  const deleteCollection = async (organizationId: string, collectionId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      let urlString = base_url + "/organization/" + organizationId + "/collection/" + collectionId
      const response = await axios.delete(urlString, { headers: { ...authorizationHeader } })
      return response
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while deleting collection'
    }
  }

  const getRegistrationFields = async (organizationId: string): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.get(base_url + '/registration-fields', { headers: { ...authorizationHeader }, params: { organizationId } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while getting registration fields'
    }
  }

  const updateOrganizationAppTranslations = async (organizationId: string, putData: UpdateOrganizationAppTranslationPayload): Promise<any> => {
    try {
      const authorizationHeader = CreateAuthHeaders(false)
      const response = await axios.put(base_url + '/organization/' + organizationId + '/apptranslations', putData, { headers: { ...authorizationHeader } })
      return response.data
    } catch (error: any) {
      throw error.response ? error.response.data.message : 'An error occured while updating organization app translations.'
    }
  }

  return Object.freeze({
    getAllCollections,
    uploadCollection,
    updateCollectionImage,
    updateCollection,
    getLanguages,
    getGroups,
    postGroup,
    deleteGroup,
    getOrgUsers,
    getOrganizationContributions,
    updateOrganizationLogo,
    updateOrganization,
    updateUserOrganizationRoles,
    suspendUser,
    getContributionFields,
    getOrganizationContributionsSummary,
    createCollectionExport,
    updateOrganizationHelpPages,
    updateSystemPages,
    getCollectionStatusList,
    deleteCollection,
    getRegistrationFields,
    activateUser,
    updateOrganizationAppTranslations
  })
})()