import '../../css/EditContribution.css'
import { useEffect, useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { AiFillFile } from 'react-icons/ai'
import {
  ContributionType,
  EditFileType,
  ImageType,
  LicenseType,
  ContributionFormType,
  FileType,
  CollectionType,
  RoutingProps,
  UpdateFilesType,
  UpdateMediaType,
  SelectedItem
} from '../../types'
import { useUserContext } from '../../context/UserContext';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { Api } from '../../api/api'
import { MediaPreview } from '../CreateContribution/formComponents/MediaPreview';
import { findExistingMapScript, createMapScriptUrl, loadMapApi } from '../../helpers/mapFunctions'
import { TextArea } from '../CreateContribution/formComponents/FormComponents';

import { userHasPermission, Can } from '../../helpers/permissionsHelper';
import { AddComment } from '../admin/AddComment'
import { ShowQuestions } from '../admin/ShowQuestions'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'
import { UploadProcess } from '../CreateContribution/formComponents/UploadProcess';
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'

const EditContributionComponent = () => {
  const location = useLocation()
  const history = useHistory()
  const userCtx = useUserContext()
  const orgCtx = useOrganizationContext()
  const { orgId, collectionId, id } = useParams<{ orgId: string, collectionId: string, id: string }>();
  const [contribution, setContribution] = useState<ContributionType | null>(null)
  const [collection, setCollection] = useState<CollectionType | null>(null)
  const [previewFiles, setPreviewFiles] = useState<EditFileType[]>([])
  const [licences, setLicenses] = useState<LicenseType[]>([])
  const [selectedMediaItem, setSelectedMediaItem] = useState<SelectedItem | null>(null)

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [relatedFilesComment, setRelatedFilesComment] = useState<string | null>(null)
  const [relatedFiles, setRelatedFiles] = useState<FileType[] | null>(null)
  const [isPublic, setIsPublic] = useState<boolean>(true)
  const [submitEditLoading, setSubmitEditLoading] = useState<boolean>(false)
  const [deleteInProcess, setDeleteInProcess] = useState<boolean>(false)
  const [adminComments, setAdminComments] = useState<string[] | null>(null)

  const { getTranslation } = useCustomTranslation(orgId)

  useEffect(() => {
    const setUpStates = (contribution: ContributionType) => {
      if (contribution.relatedFiles && contribution.relatedFiles.length > 0) {
        setRelatedFiles(contribution.relatedFiles)
        setRelatedFilesComment(contribution.relatedFiles[0].comment)
      }

      setIsPublic(contribution.isPublic)
      createPreviewFiles(contribution.images)
      setContribution(contribution)

    }
    const getLicences = async () => {
      try {
        const response = await Api.getLicences()
        setLicenses(response)
      } catch (error: any) {
        console.error('error', error)
      }
    }
    const createPreviewFiles = (images: ImageType[]) => {
      let pf: EditFileType[] = []
      images.forEach((i: ImageType) => {
        pf.push({ mediaFile: i, type: 'edit' })
      })
      setPreviewFiles(pf)
      setSelectedMediaItem({ mediaItem: pf[0], itemIndx: 0 })
    }

    const getContribution = async (id: string) => {
      const c = await Api.getContribution(id)
      setUpStates(c)
    }

    const getCollection = async () => {
      try {
        const response = await Api.getCollection(id)
        setCollection(response)
      } catch (error: any) {
        console.error('Failed to fetch collection', error)
        history.push(`/organization/${orgId}/collections/${collectionId}/contribution/${id}`)
      }
    }

    if (!userCtx || !userCtx.loggedInUser) {
      history.push(`/organization/${orgId}/collections/${collectionId}`)
    }

    if (location.state) {
      const { contributionData, collectionData }: any = location.state
      setUpStates(contributionData)
      setCollection(collectionData)
    } else {
      getContribution(id)
      getCollection()
    }

    getLicences()
    const url = createMapScriptUrl()
    if (findExistingMapScript(url)) {
      setScriptLoaded(true);
    } else {
      const googleMapScript = loadMapApi(url);
      googleMapScript.addEventListener('load', function () {
        setScriptLoaded(true);
      });
    }


  }, [location, history, userCtx, collectionId, id, setCollection, orgId])

  useEffect(() => {
    if (!userCtx || !userCtx.loggedInUser) {
      history.push(`/organization/${orgId}/collections/${collectionId}`)
    }
    if (contribution && userCtx?.loggedInUser?.id !== contribution.user.userId && (userCtx && userCtx.loggedInUser && !userHasPermission(userCtx?.loggedInUser?.roles, userCtx.actionRoles.ADMIN, orgId, userCtx.userIsPlaformAdmin))) {
      history.push(`/organization/${orgId}/collections/${collectionId}`)
    }
  }, [userCtx, contribution, orgId, collectionId, history])

  const adminCommentAdded = (comment: string) => {
    let prevComments = adminComments ? [...adminComments] : []
    setAdminComments([...prevComments, comment])
  }

  const deleteAdminComment = (comment: string) => {
    let filtered = adminComments ? (adminComments.filter(c => c.toLowerCase() !== comment.toLowerCase())) as string[] : null
    setAdminComments(filtered)
  }

  const updateFileFields = (file: EditFileType, values: ContributionFormType) => {
    file.mediaFile.title = values.title ? values.title : ''
    file.mediaFile.description = values.description ? values.description : ''
    file.mediaFile.tags = values.tags ? values.tags : []
    file.mediaFile.date = values.date ? new Date(values.date) : new Date()
    file.mediaFile.location = values.location ? values.location : ''
    file.mediaFile.locationLat = values.locationLat ? values.locationLat : 0
    file.mediaFile.locationLng = values.locationLng ? values.locationLng : 0
    file.mediaFile.socials = values.socials ? values.socials : []
    file.mediaFile.isPhotographer = values.isPhotographer ? values.isPhotographer : false
    file.mediaFile.license = values.license ? values.license : ''
    file.mediaFile.otherPhotographer = values.otherPhotographer ? values.otherPhotographer : ''
    file.mediaFile.openDate = values.openDate ? values.openDate : ''
  }

  const updateMediaInformation = (values: ContributionFormType) => {
    if (!previewFiles || !selectedMediaItem) {
      return
    }
    let listToUpdate: EditFileType[] = [...previewFiles]
    listToUpdate.forEach((file: EditFileType) => {
      let mediaFile = selectedMediaItem.mediaItem.mediaFile as ImageType
      if (file.mediaFile.id === mediaFile.id) {
        updateFileFields(file, values)
      }
    })
    setPreviewFiles(listToUpdate)
  }


  const renderRelatedFiles = () => {
    if (!contribution || !contribution.relatedFiles || contribution.relatedFiles.length === 0) {
      return null
    }
    const files = (relatedFiles: FileType[]) => {
      return (
        <>
          <div style={{ marginTop: '20px' }}>
            {
              relatedFiles.map((file: any) => (
                <div className="flex">
                  <AiFillFile />
                  <span className="file-name">{file.originalFileName}</span>
                </div>
              ))
            }
            <br />
            <TextArea
              title={getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_RELATED_FILES_COMMENT')}
              name="filesComment"
              placeholder=""
              onUpdate={(name: string, value: string) => {
                setRelatedFilesComment(value)
              }}
              handleKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                }
              }}
              value={relatedFilesComment} />
          </div>
        </>
      )
    }

    return (
      <div>
        {files(contribution.relatedFiles)}
      </div>
    )
  }

  const deleteContribution = async () => {
    if (!contribution) {
      return
    }
    setDeleteInProcess(true)
    try {
      await Api.deleteContribution(contribution.contributionId)
      history.push(`/organization/${orgCtx?.organization?.id}`)
    } catch (error: any) {
      alert(error)
    }
    finally {
      setDeleteInProcess(false)
    }
  }

  const photgrapherVerification = (mediaFile: ImageType) => {
    let isPhotographer = mediaFile.isPhotographer
    if (isPhotographer) return true
    let otherPhotographer = mediaFile.otherPhotographer || ''

    if (otherPhotographer.length === 0) return false
    return true
  }


  const verifyAllFiles = (files: EditFileType[]) => {
    const requiredFields: string[] = []
    if (collection?.contributionFields) {
      for (const [key, value] of Object.entries(collection!.contributionFields)) {
        if (value) {
          requiredFields.push(key)
        }
      }
    }
    if (requiredFields.length === 0) {
      return true
    }

    const filesValid = files.reduce((acc: boolean, currentFile) => {
      for (const field of requiredFields) {
        let value = field in currentFile.mediaFile ? currentFile.mediaFile[field as keyof ContributionFormType] : ''
        switch (typeof value) {
          case 'string':
            if (value.length === 0) {
              acc = acc && false
            }
            break
          case 'object':
            if (!value) {
              acc = acc && false
            }

            break
          // case 'boolean':
          //   if (!value) {
          //     acc = acc && false
          //   }
        }
      }

      if (requiredFields.includes('isPhotographer') && !photgrapherVerification(currentFile.mediaFile)) {
        acc = acc && false
      }

      return acc
    }, true)

    return filesValid
  }

  const handleSubmit = async () => {
    if (!userCtx || !userCtx.loggedInUser || !contribution) {
      return
    }

    const areAllFilesValid = verifyAllFiles(previewFiles)
    if (!areAllFilesValid) {
      const alertMessage = `${getTranslation('CREATE_CONTRIBUTION_PAGE.MISSING_VALUE_ERROR')}`
      alert(alertMessage)
      return
    }
    const data: any = { contributionId: contribution.contributionId }

    let mediaData: UpdateMediaType[] = []
    previewFiles.forEach((f: EditFileType) => {
      let copy = JSON.parse(JSON.stringify(f.mediaFile));
      let d = new Date(copy.date)
      mediaData.push({
        id: copy.id,
        contributionId: copy.contributionId,
        title: copy.title,
        description: copy.description,
        date: d,
        location: copy.location,
        locationLat: copy.locationLat,
        locationLng: copy.locationLng,
        socials: copy.socials,
        tags: copy.tags,
        isPhotographer: copy.isPhotographer,
        license: copy.license,
        otherPhotographer: copy.isPhotographer ? null : copy.otherPhotographer,
        openDate: copy.openDate
      })
    })
    data['media'] = mediaData
    if (relatedFiles && relatedFilesComment) {
      let filesToSend: UpdateFilesType[] = []
      relatedFiles.forEach(f => {
        filesToSend.push({ id: f.id, contribution_id: contribution.contributionId, comment: relatedFilesComment })
      })

      data['files'] = filesToSend
    }

    if (adminComments) {
      data['adminComments'] = adminComments
    }
    data['isPublic'] = isPublic
    setSubmitEditLoading(true)

    try {
      const response = (await Api.editContribution(data)).data
      if ('contributionId' in response) {
        const contribuitonId = response.contributionId
        let routingProps: RoutingProps = { pathname: `/organization/${orgId}/collections/${collectionId}/contribution/${contribuitonId}` }
        if (collection) {
          routingProps.state = { collectionData: collection }
        }
        history.push({ ...routingProps })
      }
      else {
        alert(getTranslation('GLOBAL.UNKNOW_ERROR'))
      }
    } catch (error: any) {
      alert(error)
    }
    finally {
      setSubmitEditLoading(false)
    }
  }

  const getSelectedMediaId = () => {
    if (!selectedMediaItem) return ''
    let mediaFile = selectedMediaItem.mediaItem.mediaFile as ImageType
    return mediaFile.id
  }

  const getOrganizationColor = orgCtx && orgCtx.organization ? orgCtx.organization.color : '#556271'
  if (!contribution || !collection || deleteInProcess) {
    return (<div className="loader--loading" style={{ height: '100vh' }}></div>)
  }

  return (
    <div className="App">
      <div className="app-content edit-contribution-page">
        <h1>{getTranslation('EDIT_CONTRIBUTION.HEADING')}</h1>
        <div className="media-preview-wrapper">
          {previewFiles.map((file: EditFileType, idx: number) => {
            return (
              <MediaPreview
                key={idx}
                file={file}
                selectedMediaIdentifier={getSelectedMediaId()}
                errorFiles={[]}
                onCropSelect={() => { }}
                showCropper={false}
              />
            )
          })}
        </div>
        <div className="create-contribution-lower">
          <div className="fields-container">
            <UploadProcess
              isEdit={true}
              initalStep={2}
              collection={collection}
              mediaFiles={previewFiles}
              selectedMediaItem={selectedMediaItem}
              updateSelectediaItem={(newSelectedItem: SelectedItem | null) => setSelectedMediaItem(newSelectedItem)}
              licenses={licences}
              relatedFiles={null}
              filesComment={''}
              renderRelatedFilesForEdit={contribution.relatedFiles && contribution.relatedFiles.length > 0 ? renderRelatedFiles : undefined}
              termsAndConditionsAccepted={true}
              organizationColor={getOrganizationColor}
              submitLoading={submitEditLoading}
              mapScripLoaded={scriptLoaded}
              contributionIsPublic={isPublic}
              renderCollectionQuestions={() => { }}
              updateContributionIsPublic={(contributionIsPublic: boolean) => setIsPublic(contributionIsPublic)}
              onRelatedCommentUpdateHandler={() => { }}
              onRelatedFilesInputChangeHandler={() => { }}
              acceptTermsAndConditionsUpdate={() => { }}
              saveMediaFormValues={updateMediaInformation}
              verifyMediaFormValues={verifyAllFiles}
              disableMediaUpload={(disable: boolean) => { }}
              deleteContribution={deleteContribution}
              submitContribution={handleSubmit}
              adminActions={() => (
                <Can
                  userRoles={userCtx?.loggedInUser?.roles}
                  requiredPermission={userCtx?.actionRoles?.ADMIN}
                  organizationId={orgId}
                  isPlatformAdmin={userCtx?.userIsPlaformAdmin}
                  yes={() => (
                    <div className="edit-contribution-admin-section-container">
                      <h2>{getTranslation('ADMIN.EDIT_CONTRIBUTION.ADMIN_AREA')}</h2>
                      <div className="m-1">
                        <AddComment
                          title={getTranslation('ADMIN.EDIT_CONTRIBUTION.ADD_COMMENT_TITEL')}
                          addComment={adminCommentAdded}
                          buttonColor={getOrganizationColor}
                          addButtonText={getTranslation('ADMIN.EDIT_CONTRIBUTION.ADD_COMMENT_BUTTON')}
                        />
                        <ShowQuestions
                          title={getTranslation('ADMIN.EDIT_CONTRIBUTION.SHOW_COMMENTS_TITLE')}
                          questions={adminComments}
                          deleteQuestion={deleteAdminComment}
                          buttonColor={getOrganizationColor}
                        />
                      </div>
                    </div>
                  )}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const EditContribution = ({ ...props }) => {
  const history = useHistory();
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="editContribution" router={history}>
        <EditContributionComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <EditContributionComponent {...props} />
  )
}