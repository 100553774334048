import '../../css/Login.css';
import { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom'
import { FcApproval } from 'react-icons/fc'
import {
  RegistrationFormType,
  RegistrationCountryFieldType,
  RegistrationFieldType,
  RegistrationDateFieldType
} from '../../types'
import { DatePicker, Text, TextArea, PasswordField, NumberField } from '../CreateContribution/formComponents/FormComponents'
import { Api } from '../../api/api'
import { ROUTE } from '../../routes';
import { CountriesDropDown } from './CountriesDropDown'
import { DropDownSelect } from '../common/DropDownSelect'
import { CustomCheckbox } from '../common/CustomCheckbox'
import { SimpleDialog } from '../common/SimpleDialog'
import { useOrganizationContext } from '../../context/OrganizationContext'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'

type RegisterError = {
  show: boolean
  message: string
}

type GenderOptionType = {
  value: string
  label: string
}


const MIN_PW_LEN = 8
const RegisterComponent = () => {
  const location = useLocation()
  const history = useHistory()
  const orgCtx = useOrganizationContext()
  const [registrationForm, setRegistrationForm] = useState<RegistrationFormType>({
    firstName: { required: true, value: '' },
    lastName: { required: true, value: '' },
    email: { required: true, value: '' },
    password: { required: true, value: '' },
    passwordConfirmation: { required: true, value: '' },
  })
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState<boolean>(false)
  const [showError, setShowError] = useState<RegisterError>({ show: false, message: '' })
  const [registrationSuccess, setRegistrationSuccess] = useState<boolean>(false)
  const [genderOptions, setGenderOptions] = useState<GenderOptionType[]>([])
  const [redirectOrg, setRedirectOrg] = useState<string>('')
  const [openUserExistsDialog, setOpenUserExistsDialog] = useState<boolean>(false)

  const { getTranslation } = useCustomTranslation(orgCtx?.organization?.id || '')

  const createStringField = (isReguired: boolean): RegistrationFieldType => {
    return { required: isReguired, value: '' }
  }

  const createCountryField = (isReguired: boolean): RegistrationCountryFieldType => {
    return { required: isReguired, value: null }
  }

  const createDateField = (isReguired: boolean): RegistrationDateFieldType => {
    return { required: isReguired, value: null, stringValue: '' }
  }
  useEffect(() => {
    const getGenderTranslation = (key: string) => {
      switch (key) {
        case 'Female':
          return getTranslation('REGISTRATION.GENDER_FEMALE')
        case 'Male':
          return getTranslation('REGISTRATION.GENDER_MALE')
        default:
          return getTranslation('REGISTRATION.GENDER_OTHER')
      }
    }
    if (!genderOptions.length) {
      const genderList = [
        { value: "FEMALE", label: getGenderTranslation('Female') },
        { value: "MALE", label: getGenderTranslation('Male') },
        { value: "OTHER", label: getGenderTranslation('Other') }
      ]
      setGenderOptions(genderList)
    }

  }, [genderOptions, getTranslation])

  useEffect(() => {

    if (location.state && orgCtx && orgCtx.organization) {
      const { redirectOrg }: any = location.state
      setRedirectOrg(redirectOrg)

      let copy = registrationForm
      for (const [key, value] of Object.entries(orgCtx.organization.registrationFields)) {
        switch (key) {
          case 'place_of_birth':
            copy['placeOfBirth'] = createStringField(value)
            break
          case 'street_address':
            copy['streetAddress'] = createStringField(value)
            break
          case 'alias':
          case 'phone':
          case 'bio':
          case 'postalcode':
          case 'occupation':
          case 'gender':
            let fieldObject: RegistrationFieldType = createStringField(value)
            copy[key] = fieldObject
            break
          case 'country':
            let countryObject: RegistrationCountryFieldType = createCountryField(value)
            copy[key] = countryObject
            break

          case 'date_of_birth':
            let dateObject: RegistrationDateFieldType = createDateField(value)
            copy['dateOfBirth'] = dateObject
            break
        }
      }
      setRegistrationForm(prev => ({ ...prev, ...copy }))

    } else {
      history.push(ROUTE.HOME)
    }
  }, [location, history, orgCtx])

  const onInputChange = (key: keyof RegistrationFormType, value: string) => {
    if (showError.show) {
      setShowError({ show: false, message: '' })
    }
    let updatedValue: any
    switch (key) {
      case 'dateOfBirth':
        updatedValue = registrationForm[key]
        updatedValue.value = new Date(value)
        updatedValue.stringValue = value
        break
      default:
        updatedValue = registrationForm[key]
        updatedValue.value = value
    }
    let prevForm = registrationForm || {}
    setRegistrationForm({ ...prevForm, [key]: updatedValue })
  }

  const validateForm = () => {
    if (!registrationForm) return false
    let isValid = true
    for (const value of Object.values(registrationForm)) {
      if (value.required) {
        if (typeof value.value === "string") {
          let valueCopy = value.value
          valueCopy = valueCopy.trim();
          isValid = isValid && valueCopy !== ''
        } else {
          isValid = isValid && value.value !== null
        }

      }
    }

    return isValid
  }

  const validatePassword = () => {
    if (!registrationForm) return false
    // password and passwordConfirmation match
    if (registrationForm.password.value !== registrationForm.passwordConfirmation.value) {
      return false
    }
    // if password is not long enough
    // TODO: Fix length
    if (registrationForm.password.value.length < MIN_PW_LEN) {
      return false
    }
    return true
  }

  const validateEmail = () => {
    if (!registrationForm) return false
    const regex =
      /* eslint-disable */
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !(!registrationForm.email.value || regex.test(registrationForm.email.value) === false);
  }

  const handleRegistrationSubmit = async () => {
    if (!validateForm() || !registrationForm) {
      setShowError({ show: true, message: getTranslation('REGISTRATION.MISSING_FILEDS_ERROR') })
      return
    }

    if (!validatePassword()) {
      setShowError({ show: true, message: `Make sure the passwords match and that they are at leat ${MIN_PW_LEN} charcters long.` })
      return
    }

    if (!validateEmail()) {
      setShowError({ show: true, message: getTranslation('REGISTRATION.NOT_VALID_EMAIL_ERROR') })
      return
    }
    if (!termsAndConditionsAccepted) {
      setShowError({ show: true, message: getTranslation('REGISTRATION.TERMS_CONDITIONS_NOT_ACCEPPTET_ERROR') })
      return
    }
    // format data to send
    const registrationData: any = {}
    for (const [key, v] of Object.entries(registrationForm)) {
      if (key === 'passwordConfirmation') {
        continue
      }

      if (key === 'country') {
        if ((v as RegistrationCountryFieldType).value) {
          registrationData[key] = (v as RegistrationCountryFieldType).value?.value
        } else {
          registrationData[key] = ''
        }
        continue
      }
      registrationData[key] = v.value
    }

    try {
      // use response to set Context ? 
      await Api.registerUser(registrationData, redirectOrg)
      setRegistrationSuccess(true)
    } catch (err: any) {
      if (err === 'email-in-use') {
        setOpenUserExistsDialog(true)
        return
      }
      setShowError({ show: true, message: err })
    }
  }

  const _hanldeKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const getRedirectToLoginProps = () => {
    const routeprop: any = {
      pathname: ROUTE.LOGIN,
      state: { redirectOrg: redirectOrg }
    }
    return routeprop
  }

  const closeUserExistsModal = (attemptLogin: boolean) => {
    setOpenUserExistsDialog(false)
    if (attemptLogin) {
      history.push(getRedirectToLoginProps())
    }
  }

  if (!registrationForm) {
    return (<div className="loader--loading" style={{ height: '100vh' }}></div>)
  }

  return (
    <div className="App">
      <div className="login-container">
        {!registrationSuccess ? (
          <>
            <h1>{getTranslation('REGISTRATION.TITLE')}</h1>
            <div className="login-content">
              <Text title={`${getTranslation('REGISTRATION.FIRST_NAME')}${registrationForm.firstName.required ? '*' : ''}`} name="firstName" placeholder="First name.." onUpdate={onInputChange} handleKeyDown={_hanldeKeyDown} value={registrationForm.firstName.value} />
              <Text
                title={`${getTranslation('REGISTRATION.LAST_NAME')}${registrationForm.lastName.required ? '*' : ''}`}
                name="lastName"
                placeholder="Last name..."
                onUpdate={onInputChange}
                handleKeyDown={_hanldeKeyDown}
                value={registrationForm.lastName.value} />
              <Text title={`${getTranslation('REGISTRATION.EMAIL')}${registrationForm.email.required ? '*' : ''}`} name="email" placeholder="" onUpdate={onInputChange} handleKeyDown={_hanldeKeyDown} value={registrationForm.email.value} />
              {registrationForm.alias &&
                (<Text
                  title={`${getTranslation('REGISTRATION.ALIAS')}${registrationForm.alias.required ? '*' : ''}`}
                  name="alias"
                  placeholder=""
                  onUpdate={onInputChange}
                  handleKeyDown={_hanldeKeyDown}
                  value={registrationForm.alias.value}
                  toolTip={getTranslation('REGISTRATION.ALIAS_TOOLTIP_TEXT')} />)}

              {/* <Text
                title={`${t('REGISTRATION.RECOVERY_EMAIL')}${registrationForm.recoveryEmail.required ? '*' : ''}`}
                name="recoveryEmail"
                placeholder=""
                onUpdate={onInputChange}
                handleKeyDown={_hanldeKeyDown}
                value={registrationForm.recoveryEmail.value}
                toolTip="The recovery email is a way of being able to recover you account in case you forget your password." /> */}
              {/* <Text title={`${t('REGISTRATION.PHONE')}${registrationForm.phone.required ? '*' : ''}`} name="phone" placeholder="" onUpdate={onInputChange} handleKeyDown={_hanldeKeyDown} value={registrationForm.phone.value} /> */}
              {registrationForm.phone &&
                (<NumberField title={`${getTranslation('REGISTRATION.PHONE')}${registrationForm.phone.required ? '*' : ''}`} name="phone" placeholder="" onUpdate={onInputChange} handleKeyDown={_hanldeKeyDown} value={registrationForm.phone.value} />)}
              {registrationForm.dateOfBirth &&
                (<DatePicker
                  title={`${getTranslation('REGISTRATION.DATEOFBIRTH')}${registrationForm.dateOfBirth.required ? '*' : ''}`}
                  name="dateOfBirth"
                  placeholder="yyyy-mm-dd"
                  onUpdate={(key: keyof RegistrationFormType, value: string) => onInputChange(key, value)}
                  value={registrationForm.dateOfBirth.stringValue}
                  handleKeyDown={_hanldeKeyDown} />)}
              {registrationForm.placeOfBirth &&
                (<Text title={`${getTranslation('REGISTRATION.PLACEOFBIRTH')}${registrationForm.placeOfBirth.required ? '*' : ''}`} name="placeOfBirth" placeholder="" onUpdate={onInputChange} handleKeyDown={_hanldeKeyDown} value={registrationForm.placeOfBirth.value} />)
              }
              {registrationForm.bio &&
                (<TextArea
                  title={`${getTranslation('REGISTRATION.BIO')}${registrationForm.bio.required ? '*' : ''}`}
                  name="bio" placeholder="" onUpdate={onInputChange}
                  handleKeyDown={_hanldeKeyDown}
                  value={registrationForm.bio.value} />)}
              {registrationForm.gender &&
                (<DropDownSelect title={`${getTranslation('REGISTRATION.GENDER')}${registrationForm.gender.required ? '*' : ''}`} options={genderOptions} onUpdate={(opt: GenderOptionType) => onInputChange('gender', opt.label)} selectedValue={registrationForm.gender.value} closeOnSelect={true} />)}
              {registrationForm.country &&
                (<CountriesDropDown
                  title={`${getTranslation('REGISTRATION.COUNTRY')}${registrationForm.country.required ? '*' : ''}`}
                  onUpdate={(country: any) => {
                    onInputChange('country', country)
                  }}
                  selectedCountry={registrationForm.country.value ? registrationForm.country.value.label : null} />)}

              {registrationForm.streetAddress &&
                (<Text title={`${getTranslation('REGISTRATION.STREET_ADDRESS')}${registrationForm.streetAddress.required ? '*' : ''}`} name="streetAddress" placeholder="" onUpdate={onInputChange} handleKeyDown={_hanldeKeyDown} value={registrationForm.streetAddress.value} />)}
              {registrationForm.postalcode && (<Text title={`${getTranslation('REGISTRATION.POSTAL_CODE')}${registrationForm.postalcode.required ? '*' : ''}`} name="postalcode" placeholder="" onUpdate={onInputChange} handleKeyDown={_hanldeKeyDown} value={registrationForm.postalcode.value} />)}
              {registrationForm.occupation && (<Text title={`${getTranslation('REGISTRATION.OCCUPATION')}${registrationForm.occupation.required ? '*' : ''}`} name="occupation" placeholder="" onUpdate={onInputChange} handleKeyDown={_hanldeKeyDown} value={registrationForm.occupation.value} />)}
              <PasswordField title={`${getTranslation('REGISTRATION.PASSWORD')}*`} name="password" placeholder="" onUpdate={onInputChange} handleKeyDown={_hanldeKeyDown} value={registrationForm.password.value} />
              <PasswordField title={`${getTranslation('REGISTRATION.PASSWORD_CONFIRMATION')}*`} name="passwordConfirmation" placeholder="" onUpdate={onInputChange} handleKeyDown={_hanldeKeyDown} value={registrationForm.passwordConfirmation.value} />
              <CustomCheckbox
                text={<a id="register-terms-conditions" href={`/organization/${redirectOrg}/terms-and-conditions`} target="_blank" rel="noreferrer">{getTranslation('REGISTRATION.ACCEPT_TERMS_CONDITIONS')}</a>}
                value={termsAndConditionsAccepted}
                onUpdate={() => {
                  setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
                }}
              />
              <div className="login-error-div">
                {showError.show && (
                  <p className="login-error">* {showError.message}</p>
                )}
              </div>
              <button className="btn btn-default btn-stnd-al" onClick={handleRegistrationSubmit}>{getTranslation('LOGIN.REGISTER')}</button>

            </div>
          </>) : (
          <div className="login-content">
            <div style={{ display: 'flex' }}>
              <h1>{getTranslation('REGISTRATION.REGISTRATION_SUCCESS')}</h1>
              <FcApproval size="3em" className="register-success-icon" />
            </div>
            <p>{getTranslation('REGISTRATION.REGISTRATION_SUCCESS_SUB')}</p>
            <Link className="btn btn-default btn-link-stnd-al link-text" to={getRedirectToLoginProps()}>{getTranslation('LOGIN.LOGIN')}</Link>
          </div>
        )}
      </div>
      <SimpleDialog
        open={openUserExistsDialog}
        title={getTranslation('REGISTRATION.USER_EXISTS_DIALOG_TITLE')}
        dialogContent={
          (
            <p>{getTranslation('REGISTRATION.USER_EXISTS_DIALOG_TEXT', { email: registrationForm.email.value })}</p>
          )
        }
        closeText={getTranslation('REGISTRATION.USER_EXISTS_DIALOG_CANCEL')}
        buttonActionText={getTranslation('REGISTRATION.USER_EXISTS_DIALOG_ACTION')}
        cancelFunction={() => closeUserExistsModal(false)}
        actionFunction={() => closeUserExistsModal(true)}
      />
    </div>
  )
}

export const Register = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="Register" router={history}>
        <RegisterComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <RegisterComponent {...props} />
  )
}