import '../../css/EditOrganization.css'
import { useEffect, useState } from "react"
import { useLocation, useHistory } from 'react-router-dom'
import {
  OrganizationType,
  OrganizationUser,
  KeyBoolean,
  KeyString,
  UpdateUserRolesForOrganizationType,
  UserRoleToUpdateType,
} from "../../types"
import { ROUTE } from '../../routes'
import { useUserContext } from '../../context/UserContext';
import { AdminApi } from '../../api/admin';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { toastNotifyError, toastNotifySuccess, toastNotifyInfo } from '../common/ToastMessage'
import { userHasPermission } from '../../helpers/permissionsHelper'
import { SimpleDialog } from '../common/SimpleDialog';
import { ToggleSlider } from '../common/ToggleSlider'
import { Table, Cell, Row } from '../common/TableComponents'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'



type RolesUpdated = {
  type: 'ROLES',
  value: string[]
}

type StatusChanged = {
  type: 'STATUS',
  value: string
}

type updateOrganizationUserType = RolesUpdated | StatusChanged

const UserAdministrationComponent = () => {
  const location = useLocation()
  const history = useHistory()
  const userCtx = useUserContext()
  const orgCtx = useOrganizationContext()

  const [organization, setOrganization] = useState<OrganizationType | null>(null)
  const [openDialg, setOpenDialog] = useState<boolean>(false)
  const [users, setUsers] = useState<OrganizationUser[]>([])
  const [selectedUser, setSelectedUser] = useState<OrganizationUser | null>(null)
  const [selectedUserRoles, setSelectedUserRoles] = useState<Array<KeyBoolean>>([])
  const { getTranslation } = useCustomTranslation(orgCtx?.organization?.id || '')

  const handleRoleUpdate = (role: string, value: boolean) => {
    let updatedValues = JSON.parse(JSON.stringify(selectedUserRoles))

    updatedValues.forEach((r: KeyBoolean) => {
      let [entires] = Object.entries(r)
      if (entires[0] === role) {
        r[role] = value
      }
    });
    setSelectedUserRoles(updatedValues)
  }

  const getDialogContent = () => {
    return (
      <div className="organization-user-admin-role-status-container">
        {selectedUserRoles.map((r: KeyBoolean) => {
          const [entries] = Object.entries(r)
          if (entries[0] === 'USER') return null
          return (
            <div className="organization-user-admin-role-status-pair">
              <div>{entries[0]}</div>
              <div style={{
                justifyContent: 'center',
                display: 'flex'
              }}>
                <ToggleSlider
                  onText={""}
                  offText={""}
                  on={entries[1]}
                  updateState={() => handleRoleUpdate(entries[0], !entries[1])}
                  colorState
                />
              </div>
            </div>
          )
        })
        }
      </div >
    )
  }

  const formatRoles = (actionRoles: KeyString, user: OrganizationUser) => {
    let formattedRoles: Array<KeyBoolean> = []
    for (const [key, value] of Object.entries(actionRoles)) {
      let r = { [key]: user.roles.includes(value) }
      formattedRoles.push(r)
    }

    return formattedRoles
  }

  const prepareForUserRoleEdit = (user: OrganizationUser) => {
    if (!userCtx || !userCtx.actionRoles) return

    let roleStatsus = formatRoles(userCtx.actionRoles, user)
    setSelectedUserRoles(roleStatsus)
    setSelectedUser(user)
    setOpenDialog(true)
  }

  const updateOrganizationUser = (updates: updateOrganizationUserType, targetUser: string) => {
    let orgUsers = JSON.parse(JSON.stringify(users))
    if (updates.type === 'ROLES') {
      orgUsers.forEach((user: OrganizationUser) => {
        if (user.userId === targetUser) {
          user.roles = updates.value
        }
      });

    } else {
      orgUsers.forEach((user: OrganizationUser) => {
        if (user.userId === targetUser) {
          user.status = updates.value
        }
      });
    }

    setUsers(orgUsers)
    setSelectedUser(null)
    setSelectedUserRoles([])

  }

  const verifyChanges = (actionRoles: KeyString, user: OrganizationUser) => {
    const originalRoles = formatRoles(actionRoles, user)
    if (JSON.stringify(selectedUserRoles) === JSON.stringify(originalRoles)) {
      toastNotifyInfo(getTranslation('ADMIN.USER_ADMINISTRATION.NO_CHANGES_TO_SAVE'))
      return false
    }
    return true
  }

  const handleUserRoleUpdate = async () => {
    setOpenDialog(false)
    if (!selectedUserRoles || !selectedUser || !userCtx || !userCtx.actionRoles || !organization) return
    if (!verifyChanges(userCtx.actionRoles, selectedUser)) return

    let data: UpdateUserRolesForOrganizationType = {
      userId: selectedUser.userId,
      roles: []
    }
    const targetUser = JSON.parse(JSON.stringify(selectedUser.userId))
    selectedUserRoles.forEach(r => {
      let [entires] = Object.entries(r)
      let roleToUpdate: UserRoleToUpdateType = {
        role: entires[0],
        attained: entires[1]
      }
      data.roles.push(roleToUpdate)
    })

    try {
      const response = await AdminApi.updateUserOrganizationRoles(organization.id, data)
      updateOrganizationUser({ type: 'ROLES', value: response.roles }, targetUser)
      toastNotifySuccess(getTranslation('GLOBAL.UPDATE_SUCCESS'))
    } catch (error: any) {
      console.error(error)
      let message = error.response ? error.response.data.message : getTranslation('GLOBAL.UNKNOW_ERROR')
      toastNotifyError(message)
    }
  }

  const handleSuspenUser = async (user: OrganizationUser) => {

    try {

      const response = await AdminApi.suspendUser(user.userId, organization!.id)
      updateOrganizationUser({ type: 'STATUS', value: response.status }, user.userId)
      toastNotifySuccess(getTranslation('GLOBAL.UPDATE_SUCCESS'))
    } catch (error: any) {
      let message = error.response ? error.response.data.message : getTranslation('GLOBAL.UNKNOW_ERROR')
      toastNotifyError(message)
    }
  }

  const handleReActivateUser = async (user: OrganizationUser) => {

    try {

      const response = await AdminApi.activateUser(user.userId, organization!.id)
      updateOrganizationUser({ type: 'STATUS', value: response.status }, user.userId)
      toastNotifySuccess(getTranslation('GLOBAL.UPDATE_SUCCESS'))
    } catch (error: any) {
      let message = error.response ? error.response.data.message : getTranslation('GLOBAL.UNKNOW_ERROR')
      toastNotifyError(message)
    }
  }

  useEffect(() => {

    const getUsers = async (organizationId: string) => {
      const response = await AdminApi.getOrgUsers(organizationId)
      setUsers(response)
    }

    if (!userCtx || !userCtx.loggedInUser || !orgCtx || !orgCtx.organization) {
      history.push(ROUTE.HOME)
    } else {
      const orgId = orgCtx.organization.id
      const userRoles = userCtx.loggedInUser.roles

      if (!(userHasPermission(userRoles, userCtx.actionRoles.SUPER_ADMIN, orgId, userCtx.userIsPlaformAdmin) || userHasPermission(userRoles, userCtx.actionRoles.ADMIN, orgId, userCtx.userIsPlaformAdmin))) {
        history.push(ROUTE.HOME)
      }
      setOrganization(orgCtx.organization)
      if (!users.length) {
        getUsers(orgId)
      }
    }

  }, [location, history, userCtx, orgCtx, users.length])

  const disableActionButtons = userCtx && userCtx.loggedInUser && organization ? !userHasPermission(userCtx.loggedInUser.roles, userCtx.actionRoles.SUPER_ADMIN, organization.id, userCtx.userIsPlaformAdmin) : true
  const getStatusColor = (currentClasses: string, status: string) => {
    if (status === 'active') {
      return currentClasses + ' user-active'
    }

    if (status === 'suspended') {
      return currentClasses + ' user-suspended'
    }

    return currentClasses
  }
  return (
    <div className="App">
      <div className="app-content user-administration-page">
        {!organization || !users.length ? <div className="loader--loading"></div> : null}
        <h1>{organization?.name}</h1>
        <h2>{getTranslation('ADMIN.USER_ADMINISTRATION.HEADING')}</h2>
        <div className="organization-user-admin-container">
          <h3>{getTranslation('ADMIN.USER_ADMINISTRATION.TABLE_HEADING')}</h3>
          <Table>
            <Row>
              <Cell>{getTranslation('REGISTRATION.EMAIL')}</Cell>
              <Cell>{getTranslation('REGISTRATION.FIRST_NAME')}</Cell>
              <Cell>{getTranslation('REGISTRATION.LAST_NAME')}</Cell>
              <Cell>{getTranslation('ADMIN.USER_ADMINISTRATION.TABLE_COLUMNS.ROLES')}</Cell>
              <Cell>{getTranslation('ADMIN.USER_ADMINISTRATION.TABLE_COLUMNS.CONTRIBUTIONS')}</Cell>
              <Cell>{getTranslation('ADMIN.USER_ADMINISTRATION.TABLE_COLUMNS.STATUS')}</Cell>
              <Cell>{getTranslation('ADMIN.USER_ADMINISTRATION.TABLE_COLUMNS.ACTIONS')}</Cell>
            </Row>
            {users?.map(user => {
              return (
                <Row>
                  <Cell>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/organization/${organization?.id}/profile/${user.userId}`)}>
                      {user.email}
                    </div>
                  </Cell>
                  <Cell>
                    {user.firstName}
                  </Cell>
                  <Cell>{user.lastName}</Cell>
                  <Cell>{user.roles.map(role => {
                    if (role === 'USER') return null
                    return (
                      <div className="role-pill">
                        {role}
                      </div>
                    )
                  })}</Cell>
                  <Cell>{user.contributions}</Cell>
                  <Cell><div className={getStatusColor('status-pill', user.status)}>{user.status}</div></Cell>
                  <Cell>
                    <button
                      className="btn"
                      style={{ backgroundColor: orgCtx?.organization?.color, padding: '10px 20px', margin: '5px' }}
                      onClick={() => prepareForUserRoleEdit(user)}
                      disabled={disableActionButtons}
                    >
                      {getTranslation('ADMIN.USER_ADMINISTRATION.TABLE_ACTION_EDIT')}
                    </button>
                    {user.status === 'active' ?
                      (<button
                        className="btn-danger"
                        style={{ padding: '10px 25px', margin: '5px' }}
                        onClick={() => {
                          handleSuspenUser(user)
                        }}
                        disabled={disableActionButtons}
                      >
                        {getTranslation('ADMIN.USER_ADMINISTRATION.TABLE_ACTION_SUSPEND')}
                      </button>) : (
                        <button
                          className="btn-danger"
                          style={{ padding: '10px 25px', margin: '5px' }}
                          onClick={() => {
                            handleReActivateUser(user)
                          }}
                          disabled={disableActionButtons}
                        >
                          {getTranslation('ADMIN.USER_ADMINISTRATION.TABLE_ACTION_ACTIVATE')}
                        </button>
                      )

                    }
                  </Cell>
                </Row>
              )
            })}
          </Table>
        </div>
        <SimpleDialog
          open={openDialg}
          title={getTranslation('ADMIN.USER_ADMINISTRATION.EDIT_ROLES_DIALOG_TITLE', { userName: selectedUser?.firstName + ' ' + selectedUser?.lastName })}
          dialogContent={getDialogContent()}
          closeText={getTranslation('ADMIN.USER_ADMINISTRATION.EDIT_ROLES_DIALOG_CLOSE')}
          buttonActionText={getTranslation('ADMIN.USER_ADMINISTRATION.EDIT_ROLES_DIALOG_SAVE_ROLES')}
          cancelFunction={() => setOpenDialog(false)}
          actionFunction={handleUserRoleUpdate}
          organizationColor={orgCtx?.organization?.color}
          onClickOutSide={() => setOpenDialog(false)}
        />

      </div>
    </div >
  )
}

export const UserAdministration = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="userAdministration" router={history}>
        <UserAdministrationComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <UserAdministrationComponent {...props} />
  )
}