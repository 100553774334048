type ToggleProps = {
  onText: string
  offText: string
  on: boolean
  updateState: Function
  colorState?: true
}
export const ToggleSlider = ({ onText, offText, on, updateState, colorState }: ToggleProps) => {

  const classes = on ? colorState ? "round-slider toggle-checked toggle-with-colors-on" : "round-slider toggle-checked" : colorState ? "round-slider toggle-with-colors-off" : "round-slider"
  return (
    <div id="toggle-btn" onClick={() => updateState()}>
      <label htmlFor="toggle-checkbox" className="round-slider-container">
        <div>{onText}</div>
        <div>{offText}</div>
        <div className={classes}></div>
      </label>
    </div>
  )
}