import '../../css/Contribution.css';
import { useEffect, useState } from 'react';
import { CgProfile } from 'react-icons/cg'
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Api } from '../../api/api'
import { CollectionType, ContributionType, FileType, SimilarMedia } from '../../types'
import { getCollectionKeyInLang } from '../../helpers/helperFunctions'
import { userHasPermission, Can } from '../../helpers/permissionsHelper';
import { ImageSlider } from './ImageSlider'
import { useOrganizationContext } from '../../context/OrganizationContext';
import { useUserContext } from '../../context/UserContext';
import { ShowQuestions } from '../admin/ShowQuestions';
import { MediaFileRenderer } from '../common/MediaFileRenderer'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'


const ContributionComponent = () => {
  const { i18n } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { orgId, collectionId, id } = useParams<{ orgId: string, collectionId: string, id: string }>();
  const orgContext = useOrganizationContext()
  const userCtx = useUserContext()
  const [collection, setCollection] = useState<CollectionType | null>(null)
  const [contribution, setContribution] = useState<ContributionType | null>(null)
  const [similarMediaFiles, setSimilarMediaFiles] = useState<SimilarMedia[] | null>(null)
  const [uniqueTags, setUniqueTags] = useState<Array<string>>([])
  const [showContributeButton, setShowContributeButton] = useState<boolean>(false)

  const { getTranslation } = useCustomTranslation(orgId)
  useEffect(() => {
    const getContribution = async (id: string) => {
      const c = await Api.getContribution(id)
      setContribution(c)
      const uTags = c.images.reduce((tags: string[], img: any) => {
        if (!img.tags) {
          return tags
        }
        for (const tag of img.tags) {
          if (!tags.includes(tag.toLowerCase()) && !tags.includes(tag)) {
            tags.push(tag)
          }
        }

        return tags
      }, [] as string[])
      setUniqueTags(uTags)
      const similarFiles = await Api.getSimilarFiles(uTags, c.contributionId)
      setSimilarMediaFiles(similarFiles)
    }

    const getCollection = async (id: string) => {
      try {
        const response = await Api.getCollection(id)
        setCollection(response)
      } catch (error: any) {
        console.error('Failed to fetch collection', error)
      }
    }
    if (location.state) {
      const { collectionData, showContribute }: any = location.state
      setCollection(collectionData)
      if (showContribute) {
        setShowContributeButton(true)
      }

    } else {
      getCollection(collectionId)
    }

    if (id) {
      getContribution(id)
    }
  }, [id, collectionId, location])

  const renderImageTags = () => {
    if (!contribution || uniqueTags.length === 0) {
      return null
    }

    return (
      <div className="contribution-tags" >
        {uniqueTags.map((t: string) => (
          <div className="contribution-tag" style={orgContext?.organization ? { backgroundColor: orgContext.organization.color } : { backgroundColor: '#000', border: ' 2px solid #ffff' }} key={t}>
            <div className="option-text">{t}</div>
          </div>
        ))}

      </div>
    )
  }

  const renderBreadCrumbs = () => {
    if (!orgContext || !orgContext.organization || !collection) return null
    return (
      <div className="breadcrumbs">
        <ul className="breadcrumb">
          <li><Link to={{ pathname: `/organization/${orgId}` }}>{`${orgContext.organization.name}`}</Link></li>
          <li><Link to={{
            pathname: `/organization/${orgId}/collections/${collection.id}`,
            state: { collectionData: collection }
          }}>{`${getCollectionKeyInLang(collection, 'title', i18n.language)}`}
          </Link></li>
        </ul>
      </div>
    )
  }

  const renderRelatedFiles = () => {
    if (!contribution?.relatedFiles || !contribution.relatedFiles.length) {
      return null
    }

    return (
      <>
        <h2>{getTranslation('CONTRIBUTION_PAGE.RELATED_FILES')}</h2>
        <span>{contribution.relatedFiles[0].comment}</span>
        <div className="related-files">
          {contribution?.relatedFiles?.map((f: FileType) => {
            return (
              <a key={f.id} href={f.fileUrl}>{f.originalFileName}</a>
            )
          })}
        </div>
      </>
    )
  }

  const navigateToSimilarContribution = (contributionId: string, cId: string) => {
    history.push({ pathname: `/organization/${orgId}/collections/${cId}/contribution/${contributionId}` })
  }



  const lessThan24HoursAgo = (dateString: string) => {
    const twentyfourHours = 1000 * 60 * 60 * 24;
    const date1 = new Date(dateString)
    const now = new Date()
    let diff: number = Math.abs(now.valueOf() - date1.valueOf());
    return diff < twentyfourHours
  }
  const isCurrentUser = () => {
    if (!contribution || !userCtx || !userCtx.loggedInUser) return null
    if (userHasPermission(userCtx.loggedInUser.roles, userCtx.actionRoles.ADMIN, orgId, userCtx.userIsPlaformAdmin)) {
      return (
        <div className="user-edit-options">
          <button
            className="btn"
            style={{ backgroundColor: orgContext?.organization?.color, padding: '5px 25px' }}
            onClick={() => {
              history.push({
                pathname: `/organization/${orgId}/authenticated/${collectionId}/contribution/${id}/edit-contribution`,
                state: { contributionData: contribution, collectionData: collection }
              })
            }}
          >{getTranslation('ADMIN.EDIT_CONTRIBUTION.BUTTON_LINK')}</button>
        </div>
      )
    }
    if (contribution.user.userId === userCtx.loggedInUser.id) {

      return (
        <div>
          <div className="user-edit-options">
            <button
              className="btn"
              style={{ backgroundColor: orgContext?.organization?.color, padding: '5px 25px 5px 25px', margin: '15px 15px 15px 0px' }}
              onClick={() => {
                history.push({
                  pathname: `/organization/${orgId}/authenticated/${collectionId}/contribution/${id}/edit-contribution`,
                  state: { contributionData: contribution, collectionData: collection }
                })
              }}
              disabled={!lessThan24HoursAgo(contribution.publishedAt)}>{getTranslation('CONTRIBUTION_PAGE.EDIT_CONTRIBUTION')}</button>
            {showContributeButton && (
              <button
                className="btn"
                style={{ backgroundColor: orgContext?.organization?.color, padding: '5px 25px 5px 25px', margin: '15px 15px 15px 0px' }}
                onClick={() => {
                  history.push({
                    pathname: `/organization/${orgId}/authenticated/collections/${collectionId}/create-contribution`,
                    state: { collectionData: collection }
                  })
                }}>
                {getTranslation('CONTRIBUTION_PAGE.UPLOAD_MORE_CONTRIBUTIONS')}</button>
            )}
          </div>
          {
            lessThan24HoursAgo(contribution.publishedAt) ? <span>{getTranslation('CONTRIBUTION_PAGE.CAN_EDIT_HELP_TEXT')}</span> :
              <span>{getTranslation('CONTRIBUTION_PAGE.CAN_NOT_EDIT_HELP_TEXT')}</span>
          }
        </div >
      )
    }
  }

  const renderSimilarFilesSection = () => {
    if (!similarMediaFiles || similarMediaFiles.length === 0) return null
    return (
      <>
        <h2>{getTranslation('CONTRIBUTION_PAGE.SIMILAR_IMGAGES')}</h2>
        <div className="similar-files">
          {similarMediaFiles.map((file) => (
            <MediaFileRenderer
              mediaFile={file}
              fileId={file.id}
              idKey={file.id}
              videoStyles={{ opacity: "0.4", width: 'inherit', height: 'inherit' }}
              onClickHandler={() => navigateToSimilarContribution(file.contributionId, file.collectionId)}
            />
          ))}
        </div>
      </>
    )
  }

  const formAdminQuestions = () => {
    if (!contribution || !contribution.adminComments) {
      return null
    }

    return contribution.adminComments.comments.map(c => c.comment)
  }

  if (contribution == null || contribution.images.length <= 0) {
    return (<div className="loader--loading" style={{ height: '100vh' }}></div>)
  }

  return (
    <div className="App">
      <div className="app-content contribution-page">
        <div className="">
          {renderBreadCrumbs()}

          <div className="contribution-top">
            <div className="profile-container">
              <CgProfile fill="#000" size="2em" />
              <Link to={{ pathname: `/organization/${orgId}/profile/${contribution.user.userId}` }} className="slide-content-text-pair-text contribution-username">{contribution.user.name}</Link>
            </div>

            <div className="profile-container">
              {isCurrentUser()}
            </div>
          </div>



          <ImageSlider images={contribution.images} userName={contribution.user.name} translationFunction={getTranslation} />
          <div className="contribution-elements">
            {uniqueTags.length > 0 && <h2>{getTranslation('CONTRIBUTION_PAGE.TAGS')}</h2>}
            {renderImageTags()}
            {renderSimilarFilesSection()}
            {renderRelatedFiles()}
          </div>
          <Can
            userRoles={userCtx?.loggedInUser?.roles}
            requiredPermission={userCtx?.actionRoles?.ADMIN}
            organizationId={orgId}
            isPlatformAdmin={userCtx?.userIsPlaformAdmin}
            yes={() => {
              return contribution.adminComments ? (
                <div className="contribution-admin-section-contianer">
                  <h2>{getTranslation('ADMIN.CONTRIBUTION.ADMIN_AREA')}</h2>
                  <div className="m-1">
                    <ShowQuestions
                      title={getTranslation('ADMIN.CONTRIBUTION.COMMENTS_TITLE')}
                      questions={formAdminQuestions()}
                      deleteQuestion={() => { }}
                      buttonColor={orgContext?.organization?.color || '#556271'}
                      canDelete={false}
                    />
                  </div>
                </div>
              ) : null
            }}
          />
        </div>
      </div>
    </div>
  )
}


export const Contribution = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="contribution" router={history}>
        <ContributionComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <ContributionComponent {...props} />
  )
}