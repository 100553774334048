import { Component } from 'react'
import { OrganizationContext } from '../context/OrganizationContext';
import { Api } from '../api/api'



class ErrorBoundary extends Component<{ location: string, router?: any }, { hasError: boolean, errorData: { error: any } }> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      errorData: {
        error: null,
      }
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState(prev => ({
      ...prev, errorData: {
        error,
      }
    }))

    let windowLocation = window.location.href
    let location = `URL: ${windowLocation} | Route: ${this.props.location} `

    let deviceInformation = `Device Data: ${navigator.userAgent}.`
    let clientError = {
      error: error.toString(),
      location,
      deviceInformation
    }

    let data = JSON.stringify(clientError)
    Api.sendClientError(data)
  }

  goHome(passedRouter: any, organizationId: string) {
    if (passedRouter) {
      passedRouter.push(`/organization/${organizationId}`)
    }
  }
  static contextType = OrganizationContext;


  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="App">
          <div className="error-page-content">
            <h1>Something went wrong.</h1>
            {this.context.organization && this.context.organization.id ? (
              <button className="btn" style={{ background: `${this.context.organization.color}` }} onClick={() => this.goHome(this.props?.router, this.context.organization.id)}>Back to start page</button>
            ) : null}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary