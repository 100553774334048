import '../../../css/App.css';
import { FormTexInputType, CheckBoxInputType } from '../../../types'
import { AiOutlineQuestion, AiOutlineCheck } from 'react-icons/ai'
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as ACheckbox from '@radix-ui/react-checkbox';



export const Text = ({ title, name, placeholder, onUpdate, handleKeyDown, toolTip, value, subText }: FormTexInputType) => {
  if (toolTip) {
    const Tooltip = TooltipPrimitive.Root;
    const TooltipTrigger = TooltipPrimitive.Trigger;
    const TooltipContent = TooltipPrimitive.Content;
    return (
      <div className="field-pair">
        <div>
          <label htmlFor={name}>{title}</label>
          <Tooltip>
            <TooltipTrigger asChild>
              <button className="tooltip-button">
                <AiOutlineQuestion size="2em" />
              </button>
            </TooltipTrigger>
            <TooltipContent className="tooltip-content" side="right">
              <div>{toolTip}</div>
            </TooltipContent>
          </Tooltip>
          {subText && <p>{subText}</p>}
        </div>
        <input value={value} type="text" id={name} name={name} placeholder={placeholder} onChange={e => onUpdate(name, e.target.value)} onKeyDown={(e) => handleKeyDown(e)} />
      </div >
    )
  }
  return (
    <div className="field-pair">
      <label htmlFor={name}>{title}</label>
      {subText && <p>{subText}</p>}
      <input value={value} type="text" id={name} name={name} placeholder={placeholder} onChange={e => onUpdate(name, e.target.value)} onKeyDown={(e) => handleKeyDown(e)} />
    </div >)
}
export const NumberField = ({ title, name, placeholder, onUpdate, handleKeyDown, value, subText }: FormTexInputType) => {
  return (
    <div className="field-pair">
      <label htmlFor={name}>{title}</label>
      {subText && <p>{subText}</p>}
      <input value={value} type="tel" id={name} name={name} placeholder={placeholder} onChange={e => onUpdate(name, e.target.value)} onKeyDown={(e) => handleKeyDown(e)} />
    </div >)
}

export const PasswordField = ({ title, name, placeholder, onUpdate, handleKeyDown }: FormTexInputType) => (
  <div className="field-pair">
    <label htmlFor={name}>{title}</label>
    <input type="password" id={name} name={name} placeholder={placeholder} onChange={e => onUpdate(name, e.target.value)} onKeyDown={(e) => handleKeyDown(e)} />
  </div>
)
export const TextArea = ({ title, name, placeholder, onUpdate, handleKeyDown, value, subText }: FormTexInputType) => (
  <div className="field-pair">
    <label htmlFor={name}>{title}</label>
    {subText && <p>{subText}</p>}
    <textarea value={value} id={name} name={name} placeholder={placeholder} onChange={e => onUpdate(name, e.target.value)} onKeyDown={(e) => handleKeyDown(e)} />
  </div>
)

export const Checkbox = ({ title, name, value, onUpdate }: CheckBoxInputType) => (
  <div className="m-2">
    <ACheckbox.Root className="customCheckbox-rot">
      <ACheckbox.Indicator>
        {<AiOutlineCheck size="1.5em" />}
      </ACheckbox.Indicator>
    </ACheckbox.Root>
    <label className="customCheckbox-label">
      {title}
    </label>
  </div>
)


export const DatePicker = ({ title, name, placeholder, onUpdate, value, subText }: FormTexInputType) => {
  let todayLong = new Date().toISOString()
  let todayFormatted = todayLong.substr(0, todayLong.indexOf('T'))

  return (
    <div className="field-pair">
      <label htmlFor={name}>{title}</label>
      {subText && <p>{subText}</p>}
      <input type="date" id={name} name={name}
        value={value}
        onChange={e => onUpdate(name, e.target.value)}
        // min="1910-01-01"
        max={todayFormatted}
      />
    </div>
  )
}

