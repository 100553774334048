import '../../css/EditOrganization.css'
import { useEffect, useState, useCallback } from "react"
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import {
  OrganizationType,
  UpdateOrganizationPayload,
  Language,
  OrganizationSettingsTranslations,
  DynamicField,
  KeyBoolean
} from "../../types"
import { ROUTE } from '../../routes'
import { useUserContext } from '../../context/UserContext';

import { AdminApi } from '../../api/admin';
import { preventDefault, getOrganizationKeyInLangWithDefault } from '../../helpers/helperFunctions'
import { useOrganizationContext } from '../../context/OrganizationContext';
import { ImageEdit } from "./ImageEdit";
import { Text } from '../CreateContribution/formComponents/FormComponents'
import { toastNotifyError, toastNotifySuccess } from '../common/ToastMessage'
import { CustomCheckbox } from '../common/CustomCheckbox'
import { OrganizationTranslations } from './OrganizationTranslations'
import { userHasPermission } from '../../helpers/permissionsHelper'
import { LanguageSelector } from '../common/LanguageSelector'
import { DynamicFieldsPicker, SelectedField, PickerOptionType, getFieldInLang } from './DynamicFieldsPicker'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'



type OrganizationFormField = {
  field: string
  value: string
  displayName: string
}


type UpdateOrganizationType = {
  name: OrganizationFormField
  color: OrganizationFormField
  contactEmail: OrganizationFormField
  language: OrganizationFormField
}

const EditOrganizationComponent = () => {
  const history = useHistory()
  const userCtx = useUserContext()
  const orgCtx = useOrganizationContext()
  const { i18n } = useTranslation()

  const { getTranslation } = useCustomTranslation(orgCtx?.organization?.id || '')
  const [apiLanguages, setApiLanguages] = useState<Language[] | null>(null)
  const [languages, setLanguages] = useState<OrganizationSettingsTranslations | null>(null)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [organization, setOrganization] = useState<OrganizationType | null>(null)
  const [registrationFields, setRegistrationFields] = useState<DynamicField[]>([])
  const [selectedRegistrationFields, setSelectedRegistrationFields] = useState<SelectedField[]>([])
  const [organizationForm, setOrganizationForm] = useState<UpdateOrganizationType>({
    name: {
      field: 'name',
      value: '',
      displayName: getTranslation('ADMIN.EDIT_ORGANIZATION.FORM_NAME')
    },
    color: {
      field: 'color',
      value: '',
      displayName: getTranslation('ADMIN.EDIT_ORGANIZATION.FORM_COLOR')
    },
    contactEmail: {
      field: 'contactEmail',
      value: '',
      displayName: getTranslation('ADMIN.EDIT_ORGANIZATION.FORM_CONTACT_EMAIL')
    },
    language: {
      field: 'language',
      value: '',
      displayName: getTranslation('ADMIN.EDIT_ORGANIZATION.FORM_LANGUAGE')
    }
  })


  const handleTranslationUpdate = (language: string, key: 'about' | 'description' | 'terms', value: string) => {
    if (languages && language in languages) {
      let prevLangValues = languages[language]
      prevLangValues[key] = value
      setLanguages(prev => ({ ...prev, [language]: prevLangValues }))
    }
  }

  const handleUpdate = (key: 'name' | 'color' | 'contactEmail' | 'language', value: string) => {
    let values = organizationForm[key]
    values.value = value
    setOrganizationForm(prev => ({ ...prev, [key]: values }))
  }

  const isColor = (strColor: string) => {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== '';
  }

  const verifyTranslations = () => {
    if (!languages) return false
    let entries = Object.entries(languages)
    let isValid = entries.reduce((acc, current) => {
      if (current[1].required) {
        return acc && (current[1].about.length > 0 && current[1].description.length > 0 && current[1].terms.length > 0)
      }
      return acc && true
    }, true)
    return isValid


  }
  const verifyForm = () => {
    let isValid = true
    isValid = isValid && organizationForm.name.value !== ''
    isValid = isValid && organizationForm.contactEmail.value !== ''
    isValid = isValid && organizationForm.color.value !== ''
    if (!isValid) {
      toastNotifyError('Please fill in all fields')
      return isValid
    }
    isValid = isValid && isColor(organizationForm.color.value)

    if (!isValid) {
      toastNotifyError('The color is not valid')
      return isValid
    }

    let areTranslationsValid = verifyTranslations()
    isValid = isValid && areTranslationsValid
    if (!isValid) {
      toastNotifyError(getTranslation('ADMIN.MISSING_TRANSLATIONS_ERROR'))
      return isValid
    }

    return isValid
  }

  const submissionFormatTranslations = () => {
    if (!languages) return ""
    let formatted: any = []
    for (const [key, value] of Object.entries(languages)) {
      if (value.about === '' || value.description === '' || value.terms === '') {
        continue
      }

      let langObject = {
        languageCode: key,
        about: value.about,
        description: value.description,
        terms: value.terms
      }
      formatted.push(langObject)
    }
    return formatted
  }

  const formatRegistrationFields = () => {
    let cfields: KeyBoolean = {}
    selectedRegistrationFields.forEach((f: SelectedField) => {
      const entries = Object.entries(f)
      let fieldId = entries[0][1]
      cfields[fieldId as string] = entries[2][1] as boolean
    })
    return cfields
  }

  const updateOrganizationContext = (updates: OrganizationType) => {
    orgCtx?.addOrganizationToContext(updates)
  }

  const handleSubmit = async () => {
    if (!organization || !verifyForm()) {
      return
    }

    let formatted = submissionFormatTranslations()
    let formattedRegistrationFields = formatRegistrationFields()
    const data: UpdateOrganizationPayload = {
      name: organizationForm.name.value,
      color: organizationForm.color.value,
      contactEmail: organizationForm.contactEmail.value,
      language: organizationForm.language.value,
      organizationTranslations: formatted,
      registrationFields: formattedRegistrationFields
    }
    setIsUpdating(true)
    try {
      const response = await AdminApi.updateOrganization(organization.id, data)
      toastNotifySuccess(getTranslation('GLOBAL.UPDATE_SUCCESS'))
      updateOrganizationContext(response)
      setOrganizationForm({
        name: {
          field: 'name',
          value: response.name,
          displayName: getTranslation('ADMIN.EDIT_ORGANIZATION.FORM_NAME')
        },
        color: {
          field: 'color',
          value: response.color,
          displayName: getTranslation('ADMIN.EDIT_ORGANIZATION.FORM_COLOR')
        },
        contactEmail: {
          field: 'contactEmail',
          value: response.contactEmail,
          displayName: getTranslation('ADMIN.EDIT_ORGANIZATION.FORM_CONTACT_EMAIL')
        },
        language: {
          field: 'language',
          value: response.language,
          displayName: getTranslation('ADMIN.EDIT_ORGANIZATION.FORM_LANGUAGE')
        }
      })

    } catch (error: any) {
      toastNotifyError(error.respone ? error.response.data.message : 'Something went wrong, could not update organization')

    } finally {
      setIsUpdating(false)
    }
  }

  const formatLangugages = (organization: OrganizationType, fetchedLanguages: Language[]) => {
    let x: OrganizationSettingsTranslations = {}
    fetchedLanguages.forEach((l: Language) => {
      x[l.language] = {
        required: l.required,
        language: l.language,
        description: getOrganizationKeyInLangWithDefault(organization, 'description', l.language),
        about: getOrganizationKeyInLangWithDefault(organization, 'about', l.language),
        terms: getOrganizationKeyInLangWithDefault(organization, 'terms', l.language),
      }
    });
    return x

  }

  const fetcheLagunages = useCallback(async (organization: OrganizationType) => {
    let fetchedApiLanguages: Language[] = apiLanguages || []
    if (fetchedApiLanguages.length) {
      fetchedApiLanguages = await AdminApi.getLanguages()
    }

    let formattedLanguages = formatLangugages(organization, fetchedApiLanguages)
    setApiLanguages(fetchedApiLanguages)
    setLanguages(formattedLanguages)
  }, [])


  const setAllStates = useCallback(async (organization: OrganizationType) => {
    try {
      const response = await AdminApi.getRegistrationFields(organization.id)
      let keyValues = Object.entries(organization.registrationFields)
      const organizationRegistrationFields = keyValues.map((value: [string, boolean]) => {
        let f: DynamicField[] = response.filter((elem: DynamicField) => elem.field === value[0])
        return {
          id: f[0].field,
          name: getFieldInLang(f[0], i18n.language),
          required: organization.registrationFields[f[0].field]
        }
      })

      if (!apiLanguages || languages) {
        await fetcheLagunages(organization)
      }

      setRegistrationFields(response)
      setSelectedRegistrationFields(organizationRegistrationFields)
      setOrganization(organization)
      setOrganizationForm({
        name: {
          field: 'name',
          value: organization.name,
          displayName: getTranslation('ADMIN.EDIT_ORGANIZATION.FORM_NAME')
        },
        color: {
          field: 'color',
          value: organization.color,
          displayName: getTranslation('ADMIN.EDIT_ORGANIZATION.FORM_COLOR')
        },
        contactEmail: {
          field: 'contactEmail',
          value: organization.contactEmail,
          displayName: getTranslation('ADMIN.EDIT_ORGANIZATION.FORM_CONTACT_EMAIL')
        },
        language: {
          field: 'language',
          value: organization.language,
          displayName: getTranslation('ADMIN.EDIT_ORGANIZATION.FORM_LANGUAGE')
        }
      })
    } catch (error) {
      console.error(error)
    }
  }, [apiLanguages, fetcheLagunages, getTranslation, i18n.language, languages])

  useEffect(() => {
    if (!userCtx || !userCtx.loggedInUser || !orgCtx || !orgCtx.organization) {
      history.push(ROUTE.HOME)
    } else {


      const orgId = orgCtx.organization.id
      const userRoles = userCtx.loggedInUser.roles

      if (!(userHasPermission(userRoles, userCtx.actionRoles.SUPER_ADMIN, orgId, userCtx.userIsPlaformAdmin) || userHasPermission(userRoles, userCtx.actionRoles.ADMIN, orgId, userCtx.userIsPlaformAdmin))) {
        history.push(ROUTE.HOME)
      }
      if (registrationFields.length === 0) {
        setAllStates(orgCtx.organization)
      }
    }

  }, [])


  const submitButtonClasses = isUpdating ? 'loader-btn loader-btn--loading btn-stnd-al m-t-50' : 'loader-btn btn-stnd-al m-t-50'

  if (!organization || !organizationForm || !languages || registrationFields.length === 0) {
    return (<div className="loader--loading" style={{ height: '100vh' }}></div>)
  }
  return (
    <div className="App">
      <div className="app-content edit-organization-page">
        <h1>{organization?.name}</h1>
        <h2>{getTranslation('ADMIN.EDIT_ORGANIZATION.HEADING')}</h2>
        <div className="edit-organization-container">
          <ImageEdit
            translationFunction={getTranslation}
            entityId={organization.id}
            currentImageUrl={organization.logo_url}
            buttonColor={organization.color}
            updateFunction={AdminApi.updateOrganizationLogo}
            onSuccess={(imgUrl: string) => {
              if (organization && orgCtx && orgCtx.organization) {
                let prev = organization
                prev.logo_url = imgUrl
                setOrganization(prev)
                orgCtx?.addOrganizationToContext(prev)
              }
            }}
            updateText={getTranslation('ADMIN.EDIT_ORGANIZATION.EDIT_IMG_BUTTON')} />

          <div className="edit-organization-form-container">
            <form onSubmit={e => {
              e.preventDefault()
            }} name="createCollectionForm">
              <fieldset>
                <Text
                  title={organizationForm.name.displayName}
                  name={organizationForm.name.field}
                  value={organizationForm.name.value}
                  onUpdate={handleUpdate}
                  handleKeyDown={preventDefault}
                />
                <Text
                  title={organizationForm.color.displayName}
                  name={organizationForm.color.field}
                  value={organizationForm.color.value}
                  onUpdate={handleUpdate}
                  handleKeyDown={preventDefault}
                />
                <Text
                  title={organizationForm.contactEmail.displayName}
                  name={organizationForm.contactEmail.field}
                  value={organizationForm.contactEmail.value}
                  onUpdate={handleUpdate}
                  handleKeyDown={preventDefault}
                />
              </fieldset>
            </form>
            <div className="edit-organization-lang-selector-wrapper">
              <label style={{ marginRight: '10px' }}>{organizationForm.language.displayName}</label>
              <LanguageSelector
                onLanguageUpdate={(lang: string) => handleUpdate('language', lang)}
                customClasses="edit-organization-lang-selector-custom-class"
                customLang={organizationForm.language.value} />
            </div>
            <OrganizationTranslations languages={languages} onTranslationUpdate={handleTranslationUpdate} translationFunction={getTranslation} />
            <div style={{ marginBottom: '40px', marginTop: '40px' }}></div>
            <DynamicFieldsPicker
              title={getTranslation('ADMIN.EDIT_ORGANIZATION.REGISTATION_FIELDS')}
              fields={registrationFields || []}
              removeField={(fieldId: string) => {
                let filtered = selectedRegistrationFields.filter(f => f.id !== fieldId)
                setSelectedRegistrationFields(filtered)
              }}
              onUpdate={(option: PickerOptionType) => {
                let n = {
                  id: option.id,
                  name: option.name,
                  required: false
                }
                setSelectedRegistrationFields(prev => ([...prev, n]))
                // setHasBeenChanged(true)
              }}
              currentFields={selectedRegistrationFields}
            />
            <div style={{ padding: '10px', width: '300px' }}>
              {selectedRegistrationFields.map((f: SelectedField) => {
                return (
                  <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr 1fr' }}>
                    <div style={{ marginRight: '10px' }}>{f.name}</div>
                    <CustomCheckbox
                      text={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.REQUIRED_FIELD')}
                      value={f.required}
                      onUpdate={() => {
                        f.required = !f.required
                        // setHasBeenChanged(true)
                        setSelectedRegistrationFields([...selectedRegistrationFields])
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
          <button className={submitButtonClasses} style={{ backgroundColor: `${orgCtx?.organization?.color}` }} onClick={handleSubmit} >
            <span className="loader-btn__text">{getTranslation('ADMIN.EDIT_ORGANIZATION.SUBMIT_EDIT')}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export const EditOrganization = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="editOrganization" router={history}>
        <EditOrganizationComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <EditOrganizationComponent {...props} />
  )
}