import { ApplicationStorage } from "./applicationStorage";

export const CreateAuthHeaders = (allowEmpty: boolean): any => {
  const token = ApplicationStorage.getToken()
  if (!token && !allowEmpty) {
    throw new Error('No token found to use for request.')
  }

  if (token) {
    let bearer = 'Bearer '.concat(token)
    return { Authorization: bearer };
  }
  return {}
}
export const CreateAuthString = (allowEmpty: boolean) => {
  const token = ApplicationStorage.getToken()
  if (!token && !allowEmpty) {
    throw new Error('No token found to use for request.')
  }

  if (token) {
    return 'Bearer '.concat(token)
  }
  return ''
}
