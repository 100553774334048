
import '../../css/App.css';
import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
export type OptionType = {
  value: string
  label: string
}
export type DropDownProps = {
  title: string
  onUpdate: Function
  options: OptionType[]
  selectedValue: string | null
  closeOnSelect: boolean
  disabled?: boolean
}

export const DropDownSelect = ({ title, onUpdate, options, selectedValue, closeOnSelect, disabled = false }: DropDownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const renderOptions = () => {
    return (
      options.map((opt: any) => (
        <div
          className="dd-list-item-test"
          key={opt.value}
          onClick={() => {
            onUpdate(opt)
            if (closeOnSelect) {
              setIsOpen(false)
            }
          }}
        >
          {opt.label}
        </div>
      ))

    )
  }

  return (
    <div className="field-pair" onClick={() => setIsOpen(!isOpen)}>
      <label>{title}</label>
      <div className="wrapper">
        <button
          type="button"
          className="header-test header-test-c "
          disabled={disabled}
        >
          <div className="header-title-test">
            <div>{selectedValue}</div>
          </div>
          <div style={{ lineHeight: '4px' }} onClick={() => setIsOpen(!isOpen)}>
            {isOpen
              ? <IoIosArrowUp size="2em" />
              : <IoIosArrowDown size="2em" />
            }
          </div>
        </button>
        {isOpen && (
          <div
            role="list"
            className="dd-list-test"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="dd-scroll-list-test">
              {renderOptions()}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}