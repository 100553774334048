
type CellProps = {
  children?: React.ReactNode;
  grow?: boolean;
  className?: string;
};

export const Cell = ({ children, className, grow = false }: CellProps) => {
  return (
    <div className="table-comp-cell">{children}</div>
    // <div className={classnames("cell", className, { grow })}>{children}</div>
  );
}


type TableProps = {
  children?: React.ReactNode;
  className?: string;
};

// Table
export const Table = ({ children, className }: TableProps) => {
  return (
    <div className={'table-comp'}>
      {children}
    </div>
    // <div className={classnames("table", className)}>
    //   {children}
    // </div>
  );
}

type RowProps = {
  children: React.ReactNode;
};

// Row
export const Row = ({ children }: RowProps) => {
  return <div className="table-comp-row">{children}</div>;
}