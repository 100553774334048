import '../../css/Home.css';
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Api } from '../../api/api'
import { OrganizationsList } from './OrganizationsList'
import { OrganizationType } from '../../types'
import { useOrganizationContext } from '../../context/OrganizationContext';
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'


const OrganizationsComponent = () => {
  document.title = 'Home'
  const organizationContext = useOrganizationContext()
  const [organizations, setOrganizatios] = useState<Array<OrganizationType>>([])

  const getOrganizations = async () => {
    try {
      const orgs = await Api.getOrganizations()
      setOrganizatios(orgs)
    } catch (error) {
      console.error('There was an error!', error)
    }
  }

  useEffect(() => {
    if (organizationContext && organizationContext.organization) {
      organizationContext.unsetOrganization()
    }
    getOrganizations()

  }, [organizationContext])

  return (
    <div className="App">
      <div className="app-content home">
        <h1>Explore museums and archives – contribute with your digital content</h1>
        <OrganizationsList organizations={organizations} />
      </div>
    </div>
  );
}


export const Organizations = ({ ...props }) => {
  const history = useHistory();
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="organizations" router={history}>
        <OrganizationsComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <OrganizationsComponent {...props} />
  )
}