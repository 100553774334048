import '../../css/Home.css';
import { useHistory } from 'react-router-dom'
import { OrganizationType } from '../../types'
import { useOrganizationContext } from '../../context/OrganizationContext';


type Props = {
  organizations: OrganizationType[];
}

export const OrganizationsList = ({ organizations }: Props) => {
  const history = useHistory()
  const orgContext = useOrganizationContext()
  if (organizations.length === 0) {
    return (<div className="loader--loading" style={{ height: '100vh' }}></div>)
  }

  return (
    <div className="organizations">
      {organizations.map(organization => (
        <div key={organization.id} className="organzation" style={{ backgroundColor: `${organization.color}` }} onClick={() => {
          orgContext?.addOrganizationToContext(organization)
          history.push(`organization/${organization.id}`)
        }}>
          <h3>{organization.name}</h3>
        </div>
      ))}

    </div>
  );
}


