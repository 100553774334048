import '../../css/Profile.css';
import { useParams, useHistory } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react';
import { ProfileDetailsList } from './ProfileDetailsList'
import { Api } from '../../api/api'
import { UserContributions } from './UserContributions';
import { toastNotifyError } from '../common/ToastMessage';
import { useUserContext } from '../../context/UserContext'
import { userHasPermission } from '../../helpers/permissionsHelper'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'

type Profile = {
  id: string
  firstName: string
  lastName: string
  email: string
  alias: string
  phone: string
  dateOfBirth: string
  placeOfBirth: string
  bio: string
  gender: string
  country: string
  city: string
  streetAddress: string
  streetNumber: string
  postalcode: string
  occupation: string
  isPublic: boolean
}


const ProfilePageComponent = () => {
  const userCtx = useUserContext()
  const { userId, orgId }: any = useParams()
  const [user, setUser] = useState<Profile | null>()
  const [organizationId, setOrganizationId] = useState<string>("")
  const { getTranslation } = useCustomTranslation(organizationId)

  // const memoizedGetTranslation = useCallback(getTranslation, [getTranslation])


  useEffect(() => {
    const getUserByID = async (id: string, organizationId: string) => {
      try {
        const u = await Api.getUserByID(userId, organizationId)

        if (u.statusCode) {
          if (u.statusCode === 404) {
            toastNotifyError('User not found')
            return
          } else {
            toastNotifyError(getTranslation('GLOBAL.UNKNOW_ERROR'))
            return
          }
        }
        setUser(u)
      } catch (error: any) {
        toastNotifyError('could not fetch user information')
      }

    }

    try {
      if (userId && orgId) {
        setOrganizationId(orgId)
        getUserByID(userId, orgId)
      }
    } catch (error) {
      alert(error)
    }
  }, [userId, orgId])
  const renderGeneralProfile = () => {
    if (!user) {
      return null
    }

    if (userCtx && userCtx.loggedInUser && userHasPermission(userCtx.loggedInUser.roles, 'ADMIN', organizationId, userCtx.userIsPlaformAdmin)) {
      // loggedin user has rights to see everything about this user
      return (
        <ProfileDetailsList subtitle={getTranslation('PROFILE.USER_PROFILE')} header={user.alias} fields={{
          "firstName": user.firstName,
          "lastName": user.lastName,
          "email": user.email,
          "alias": user.alias,
          "phone": user.phone,
          "dateOfBirth": new Date(user.dateOfBirth).toISOString().split('T')[0],
          "placeOfBirth": user.placeOfBirth,
          "bio": user.bio,
          "gender": user.gender,
          "country": user.country,
          "city": user.city,
          "streetAddress": user.streetAddress,
          "streetNumber": user.streetNumber,
          "postalcode": user.postalcode,
          "occupation": user.occupation,
          "isPublic": (user.isPublic).toString()

        }} />)

    }
    if (!user.isPublic) {
      return (
        <ProfileDetailsList subtitle={getTranslation('PROFILE.USER_PROFILE')} header={user.alias} fields={{
          "alias": user.alias,
          // "bio": user.bio,
        }} />)
    }

    return (
      <ProfileDetailsList subtitle={getTranslation('PROFILE.USER_PROFILE')} header={user.alias} fields={{
        // "alias": user.alias,
        "name": user.firstName + " " + user.lastName,
        "bio": user.bio,
      }} />
    )
  }

  const userContributions = (userId: string, orgId: string) => {
    if (orgId === "") {
      return ("")
    }
    return (<UserContributions userId={userId} organizationId={orgId} />)
  }



  if (!user) {
    return <div className="loader--loading" style={{ height: '100vh' }}></div>
  }

  return (
    <div className="App">
      <div className="app-content">
        <h1 className="profile-header">{getTranslation('PROFILE.USER_PROFILE')}</h1>
        {renderGeneralProfile()}
        {/* {renderExtendedProfile()} // maybe this would be useful to admins?*/}
        {userContributions(user.id, organizationId)}
      </div >
    </div >
  );
}

export const ProfilePage = ({ ...props }) => {
  const history = useHistory();
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="Profile" router={history}>
        <ProfilePageComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <ProfilePageComponent {...props} />
  )
}

