import '../../css/Platform.css'
import { useHistory } from 'react-router-dom';
import { FiMoreVertical } from 'react-icons/fi'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from "react-i18next";
import { useUserContext } from '../../context/UserContext';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { ROUTE } from '../../routes'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'

const developerMenuItems = {
  informationPage: 'Edit system pages',
}

type DeveloperMenuProps = {
  customClasses?: string
}
export const AdminMenu = ({ customClasses }: DeveloperMenuProps) => {
  const userCtx = useUserContext()
  const orgCtx = useOrganizationContext()
  const history = useHistory()

  const { t } = useTranslation()
  const { getTranslation } = useCustomTranslation(orgCtx?.organization?.id || '')

  const redirectToEditOrganizationRoute = (organizationId: string) => {
    const redirectUrl = `/organization/${organizationId}/authenticated/edit-organization`
    history.push(redirectUrl)
  }
  const redirectToEditOrganizationTranslationsRoute = (organizationId: string) => {
    const redirectUrl = `/organization/${organizationId}/authenticated/edit-organization-translations`
    history.push(redirectUrl)
  }

  const redirectToCreateCollectionRoute = (organizationId: string) => {
    const redirectUrl = `/organization/${organizationId}/authenticated/collections/create-collection`
    history.push(redirectUrl)
  }

  const redirectToGroups = (organizationId: string) => {
    const redirectUrl = `/organization/${organizationId}/authenticated/groups`
    history.push(redirectUrl)
  }

  const redirectToUserAdministration = (organizationId: string) => {
    const redirectUrl = `/organization/${organizationId}/authenticated/user-administration`
    history.push(redirectUrl)
  }

  const redirectToHelpPageEdit = (organizationId: string) => {
    const redirectUrl = `/organization/${organizationId}/authenticated/edit-help-page`
    history.push(redirectUrl)
  }


  const options = () => {
    const langs = []
    for (const [key, value] of Object.entries(developerMenuItems)) {
      langs.push([key, value])
    }

    return langs.map((l: string[]) => (
      <DropdownMenu.Item key={'edit-platform'} className="lang-select-item" onSelect={() => history.push(ROUTE.EDIT_PLATFORM)}>
        {l[1]}
      </DropdownMenu.Item>
    ))
  }

  return (
    <div>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger className="platform-developer-menu-trigger">
          <FiMoreVertical size="1.5em" />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className="platform-developer-menu-content">
          {orgCtx && orgCtx.organization && orgCtx.organization.id ? (
            <>
              <DropdownMenu.Item className="lang-select-item" onSelect={() => redirectToEditOrganizationRoute(orgCtx.organization!.id)}>
                {getTranslation('ADMIN.EDIT_ORGANIZATION.HEADING')}
              </DropdownMenu.Item>
              <DropdownMenu.Item className="lang-select-item" onSelect={() => redirectToEditOrganizationTranslationsRoute(orgCtx.organization!.id)}>
                {getTranslation('ADMIN.EDIT_ORG_TRANSLATIONS.HEADING')}
              </DropdownMenu.Item>
              <DropdownMenu.Item className="lang-select-item" onSelect={() => redirectToCreateCollectionRoute(orgCtx.organization!.id)}>
                {getTranslation('ADMIN.CREATE_COLLECTION')}
              </DropdownMenu.Item>
              <DropdownMenu.Item className="lang-select-item" onSelect={() => redirectToGroups(orgCtx.organization!.id)}>
                {getTranslation('GROUPS.TITLE')}
              </DropdownMenu.Item>
              <DropdownMenu.Item className="lang-select-item" onSelect={() => redirectToUserAdministration(orgCtx.organization!.id)}>
                {getTranslation('ADMIN.USER_ADMINISTRATION.LINK')}
              </DropdownMenu.Item>
              <DropdownMenu.Item className="lang-select-item" onSelect={() => redirectToHelpPageEdit(orgCtx.organization!.id)}>
                {getTranslation('ADMIN.HELP_PAGE.MENU_HELP_PAGE')}
              </DropdownMenu.Item>
            </>
          ) : null}
          {userCtx && userCtx.userIsPlaformAdmin ? (
            <>
              <DropdownMenu.Separator className="menu-separator" />

              <DropdownMenu.Label className="menu-label">
                {t('PLATFORM.MENU_DEVELOPER_AREA')}
              </DropdownMenu.Label>
              {options()}
            </>
          ) : null}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div >
  )
}