import '../../css/Login.css';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Api } from '../../api/api'
import { ApplicationStorage } from '../../api/applicationStorage';
import { useUserContext } from '../../context/UserContext'
import { ROUTE } from '../../routes'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'

type LoginError = {
  show: boolean
  message: string
}

const LoginComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const userContext = useUserContext()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [showError, setShowError] = useState<LoginError>({ show: false, message: '' })
  const [redirectOrg, setRedirectOrg] = useState<any>({
    orgId: '',
    route: ''
  })

  const { getTranslation } = useCustomTranslation(redirectOrg.orgId)
  const onInputChange = (key: string, value: string) => {
    if (showError.show) {
      setShowError({ show: false, message: '' })
    }

    if (key === 'username') {
      setUsername(value)
    } else {
      setPassword(value)
    }
  }

  const handeLogin = async () => {
    if (username.length === 0 || password.length === 0) {
      setShowError({ show: true, message: getTranslation('LOGIN.FILL_IN_ALL_FIELDS_ERROR') })
    }
    setLoading(true)

    try {
      const credentials = {
        email: username,
        password,
        organizationId: redirectOrg.orgId
      }
      const response = await Api.loginUser(credentials)
      ApplicationStorage.setToken(response['token'])

      userContext?.setUser({ ...response.user.user, roles: response.user.userRoles }, response.user.isPlatformAdmin)
      history.push(redirectOrg.route)
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        setShowError({ show: true, message: getTranslation('LOGIN.USER_INACTIVE_ERROR'), })
      } else {
        setShowError({ show: true, message: getTranslation('LOGIN.LOGIN_ERROR') })
      }

    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (location.state) {
      const { redirectOrg }: any = location.state
      setRedirectOrg({ orgId: redirectOrg, route: `/organization/${redirectOrg}` })
    }
    else {
      history.push(ROUTE.HOME)
    }
  }, [location, history])
  const loginButtonClasses = loading ? 'loader-btn btn-default loader-btn--loading btn-stnd-al' : 'loader-btn btn-default btn-stnd-al'

  const shouldBeDisabled = username.length === 0 || password.length === 0

  const getRegisterRedirectProps = () => {
    const routeprop: any = {
      pathname: ROUTE.REGISTER,
      state: { redirectOrg: redirectOrg.orgId }
    }

    return routeprop
  }

  return (
    <div className="App">
      <div className="login-container">
        <h1>{getTranslation("LOGIN.LOGIN")}</h1>
        <div className="login-content">
          <form onSubmit={e => {
            e.preventDefault()
          }} name="login form">
            <div className="field-pair">
              <label htmlFor="username">{getTranslation("LOGIN.USERNAME")}</label>
              <input type="text" id="username" name="username" onChange={e => onInputChange('username', e.target.value)} />
            </div>
            <div className="field-pair">
              <label htmlFor="pw">{getTranslation("LOGIN.PASSWORD")}</label>
              <input type="password" id="pw" name="pw" onChange={e => onInputChange('pw', e.target.value)} />
            </div>
            <div className="login-error-div">
              {showError.show && (
                <p className="login-error">* {showError.message}</p>
              )}
            </div>
            <button className={loginButtonClasses} onClick={handeLogin} disabled={shouldBeDisabled}>
              <span className="loader-btn__text">{getTranslation('LOGIN.LOGIN')}</span>
            </button>
            <Link className="password-reset-link" to={{ pathname: `/organization/${redirectOrg.orgId}/password-reset`, state: { redirectOrg: redirectOrg.orgId } }} > {getTranslation("LOGIN.FORGOT_PASSWORD")}</Link>
          </form>

        </div>
        <div className="register-div">
          <p className="register-text">{getTranslation("LOGIN.NOT_REGISTERED")}</p>
          <Link className="btn btn-default btn-link-stnd-al link-text" to={getRegisterRedirectProps()}>{getTranslation("LOGIN.REGISTER")}</Link>
        </div>
      </div>
    </div>
  )
}

export const Login = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="Login" router={history}>
        <LoginComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <LoginComponent {...props} />
  )
}