import '../../css/TextPages.css'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ROUTE } from '../../routes'
import { useOrganizationContext } from '../../context/OrganizationContext'
import { getOrganizationKeyInLang } from '../../helpers/helperFunctions'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'

const TermsAndConditionsComponent = () => {
  const orgCtx = useOrganizationContext()
  const history = useHistory()
  const { i18n } = useTranslation()

  const renderTermsAndConditions = () => {
    if (!orgCtx || !orgCtx.organization) return null

    let language = i18n.language
    return (
      <div dangerouslySetInnerHTML={{ __html: getOrganizationKeyInLang(orgCtx.organization, 'terms', language) }} />
    )
  }

  if (!orgCtx || !orgCtx.organization) {
    history.push(ROUTE.HOME)
  }
  return (
    <div className="App">
      <div className="app-content text-pages">
        <div className="about-organization-text">
          {renderTermsAndConditions()}
        </div>
      </div>
    </div >
  )
}

export const TermsAndConditions = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="TermsAndConditions" router={history}>
        <TermsAndConditionsComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <TermsAndConditionsComponent {...props} />
  )
}