import '../../../css/MediaForm.css';
import '../../../css/CreateContribution.css'
import { useState, useEffect } from 'react';

import { AiOutlineQuestion } from 'react-icons/ai'
import {
  ContributionFormType,
  LicenseType,
  MediaListItem,
  EditFileType,
  ImageType,
  KeyBoolean
} from '../../../types'
import { Text, TextArea, DatePicker } from './FormComponents'
import { SocialsPicker } from './SocialsPicker'
import { TagsInput } from './TagsInput'
import { Map } from './Map'
import { CustomToolTip } from '../../common/CustomToolTip'
import { CustomCheckbox } from '../../common/CustomCheckbox'
import { ApplicationStorage } from '../../../api/applicationStorage'
import { getInitialMediaFormValuesForm, getDefaultPropertyValue, getFilledValueEditFileType } from '../../../helpers/mediaFileFunctions'
import { useCustomTranslation } from '../../../helpers/CustomTranslationsComps'
import { toastNotifyError } from '../../common/ToastMessage'

type MediaFormProps = {
  selectedMedia: MediaListItem | EditFileType | null
  licences: LicenseType[]
  mapIsLoaded: boolean
  onFormSave: Function
  organizationColor: string
  organizationId: string
  contributionFields: KeyBoolean
  isAllowedToMoveToNextStep: Function
  isFirst: boolean
  getPrevMediaFormValues: Function
  translationFunction: Function
}


export const MediaForm = ({ selectedMedia, licences, mapIsLoaded, onFormSave, organizationColor, organizationId, contributionFields, isAllowedToMoveToNextStep, isFirst, getPrevMediaFormValues }: MediaFormProps) => {


  const [formValues, setFormValues] = useState<ContributionFormType>({})
  const [copyFromPrevMedia, setCopyPrevMedia] = useState<boolean>(false)

  const { getTranslation } = useCustomTranslation(organizationId)
  const removeSocialOrTag = (key: "socials" | "tags", name: string) => {
    if (formValues && key in formValues) {
      const updatedValues = formValues[key]!.filter(v => v !== name)
      setFormValues(prev => ({ ...prev, [key]: updatedValues }))
    }
  }


  const handleCoordinatesUpdate = (coordinates: [number, number]) => {
    setFormValues(prev => ({ ...prev, locationLat: coordinates[0], locationLng: coordinates[1] }))
  }

  const updateMainFormSingleValue = (key: string, value: string) => setFormValues(prev => ({ ...prev, [key]: value }))

  const updateMainFormArrayValue = (key: "socials" | "tags", value: string) => {
    if (formValues) {

      let prevValues = formValues[key] || []
      const updated = [...prevValues, value]

      setFormValues(prev => ({ ...prev, [key]: [...updated] }))
    }
  }

  const onFormUpdate = (key: string, value: string) => {
    if (!formValues) return
    switch (key) {
      case 'title':
      case 'description':
      case 'location':
      case 'date':
      case 'license':
      case 'otherPhotographer':
      case 'openDate':
        updateMainFormSingleValue(key, value)
        break
      case 'isPhotographer':
        setFormValues(prev => ({ ...prev, isPhotographer: value === 'true' }))
        break
      case 'socials':
      case 'tags':

        if (formValues[key] && formValues[key]!.includes(value)) {
          break
        }
        updateMainFormArrayValue(key, value)
    }
  }

  const _hanldeKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const getFilledValueMediaFileItem = (field: keyof ContributionFormType, file: MediaListItem) => {

    if (!file.formValues || file.formValues[field] === undefined) {
      return getDefaultPropertyValue(field)
    }

    return file.formValues[field]
  }

  const copyPrevMediaFormValues = () => {
    let prevFormValues = getPrevMediaFormValues()
    let copy = JSON.stringify(prevFormValues)
    if (copy === '{}') {
      toastNotifyError(getTranslation('CREATE_CONTRIBUTION_PAGE.FORM_COPY_VALUES_FROM_PREV_MEDIA_FAILED'))
    }

    setFormValues(prevFormValues)

  }



  const setUpForm = () => {
    if (!selectedMedia) return
    let initalForm = getInitialMediaFormValuesForm(contributionFields)

    const keys = Object.keys(initalForm)

    let type = 'type' in selectedMedia ? 'editFile' : 'mediaList'

    for (const k of keys) {

      let field = k as keyof ContributionFormType
      let initialFieldValue = type === 'mediaList' ? getFilledValueMediaFileItem(field, selectedMedia as MediaListItem) : getFilledValueEditFileType(field as keyof ImageType, selectedMedia as EditFileType)

      initalForm[field] = initialFieldValue
    }
    setFormValues(initalForm as ContributionFormType)
  }

  useEffect(() => {
    setUpForm()
    setCopyPrevMedia(false)
  }, [selectedMedia])

  useEffect(() => {
    const photgrapherVerification = (form: ContributionFormType) => {

      let isPhotographer = form.isPhotographer
      if (isPhotographer) return true
      let otherPhotographer = form.otherPhotographer || ''

      if (otherPhotographer.length === 0) return false
      return true
    }

    const verifyForm = () => {
      const requiredFields = []
      for (const [key, value] of Object.entries(contributionFields)) {
        if (value) {
          requiredFields.push(key)
        }
      }
      let valid = true
      const keys = Object.keys(formValues)
      requiredFields.forEach((reqField: string) => {
        if (!keys.includes(reqField)) {
          valid = false
        }

        if (reqField === 'isPhotographer') {

          valid = valid && photgrapherVerification(formValues)
        } else {
          let value = formValues[reqField as keyof ContributionFormType]
          switch (typeof value) {
            case 'string':
              valid = valid && value.length > 0
              break
            case 'object':
              valid = valid && (value != null && value.length > 0)
              break
          }
        }
      })
      return valid
    }

    if (formValues) {
      let isValid = verifyForm()
      isAllowedToMoveToNextStep(isValid)
      onFormSave(formValues)
    }
  }, [formValues, contributionFields])

  return (
    <div>
      {!isFirst &&
        (<div className="copy-prev-media-checkbox-container">
          <CustomCheckbox
            text={getTranslation('CREATE_CONTRIBUTION_PAGE.FORM_COPY_VALUES_FROM_PREV_MEDIA')}
            value={copyFromPrevMedia}
            onUpdate={(checked: boolean) => {
              setCopyPrevMedia(checked)
              if (checked) {
                copyPrevMediaFormValues()
              }
            }} />
        </div>
        )}
      <form onSubmit={e => {
        e.preventDefault()
      }} name="mediaForm" id="contrib-form">
        <fieldset disabled={!selectedMedia}>
          {'title' in formValues ? (
            <Text
              title={`${getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_TITLE')}${contributionFields.title ? '*' : ''}`}
              name="title" placeholder={getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_TITLE_PLACE_HOLDER')}
              onUpdate={onFormUpdate}
              handleKeyDown={_hanldeKeyDown}
              value={formValues.title} />) : null}
          {'description' in formValues ? (
            <TextArea
              title={`${getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_DESCRIPTION')}${contributionFields.description ? '*' : ''}`}
              name="description"
              placeholder={getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_DESCRIPTION_PLACE_HOLDER')}
              onUpdate={onFormUpdate}
              handleKeyDown={() => { }}
              value={formValues.description}
              subText={getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_DESCRIPTION_SUB_TEXT')} />
          ) : null}
          {'tags' in formValues ? (
            <TagsInput
              title={`${getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_TAGS')}${contributionFields.tags ? '*' : ''}`}
              name="tags"
              currentTags={formValues.tags!}
              onAddTag={onFormUpdate}
              onRemoveTag={removeSocialOrTag} />
          ) : null}
          {'date' in formValues ? (
            <DatePicker
              title={`${getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_DATE')}${contributionFields.date ? '*' : ''}`}
              name="date"
              placeholder="yyyy-mm-dd"
              onUpdate={onFormUpdate}
              handleKeyDown={_hanldeKeyDown}
              value={formValues.date} />
          ) : null}

          {'openDate' in formValues ? (
            <Text
              title={`${getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_DATE')}${contributionFields.openDate ? '*' : ''}`}
              name="openDate"
              placeholder={getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_OPEN_DATE_PLACE_HOLDER')}
              onUpdate={onFormUpdate}
              handleKeyDown={_hanldeKeyDown}
              value={formValues.openDate} />) : null}

          {'location' in formValues && mapIsLoaded && (
            <Map
              mapTypeControl={true}
              title={`${getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_LOCATION')}${contributionFields.location ? '*' : ''}`}
              name="location"
              placeholder={getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_LOCATION_PLACE_HOLDER')}
              value={formValues.location!}
              onUpdateLocation={(location: string) => {
                onFormUpdate('location', location)
              }}
              onUpdateCoordinates={(coordinates: [number, number]) => {
                handleCoordinatesUpdate(coordinates)
              }}
            />
          )}

          {'socials' in formValues ? (
            <SocialsPicker
              title={`${getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_SOCIALS')}${contributionFields.socials ? '*' : ''}`}
              removeSocial={removeSocialOrTag}
              onUpdate={onFormUpdate}
              currentSocials={formValues.socials!}
              organizationColor={organizationColor}
              translationFunction={getTranslation}
            />
          ) : null}

          {'license' in formValues ? (
            <div className="text-left license-container">
              <label className="text-size-medium">{`${getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_LICENSE_HEADER')}${contributionFields.license ? '*' : ''}`}</label>
              {licences.map((l: LicenseType) => {
                let localLangauge = ApplicationStorage.getLanguage()
                let name = localLangauge && localLangauge in l ? l[localLangauge as keyof typeof l] : l['en']
                let dec = localLangauge && localLangauge in l ? l[`${localLangauge}_description` as keyof typeof l] : l['en_description']
                return (
                  <div className="checkbox-with-icon" key={l.licenseId}>
                    <CustomCheckbox text={name} value={formValues.license === l.licenseId} onUpdate={(checked: boolean) => { onFormUpdate('license', l.licenseId) }} />
                    <CustomToolTip message={dec} icon={(<AiOutlineQuestion />)} />
                  </div>
                )
              })}
            </div>
          ) : null}

          {'isPhotographer' in formValues ? (
            <>
              <hr></hr>
              <CustomCheckbox
                text={`${getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_IS_PHOTOGRAPHER')}${contributionFields['isPhotographer'] ? '*' : ''}`}
                value={formValues.isPhotographer!}
                onUpdate={(checked: boolean) => { onFormUpdate('isPhotographer', checked.toString()) }} />
            </>
          ) : null}
          {'isPhotographer' in formValues && !formValues.isPhotographer ? (
            <Text
              title={`${getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_ORIGNAL_PHOTOGRAPHER')}${contributionFields['isPhotographer'] ? '*' : ''}`}
              name="otherPhotographer"
              placeholder=''
              onUpdate={onFormUpdate}
              handleKeyDown={_hanldeKeyDown}
              value={formValues.otherPhotographer} />
          ) : null}


        </fieldset>
      </form>
    </div>
  )
}
