import '../../css/App.css';
import '../../css/Contribution.css'
import { useEffect, useState } from 'react';
import { MdOutlineDateRange, MdOutlinePolicy } from 'react-icons/md'
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi'
import { VscCopy } from 'react-icons/vsc'
import { AiFillCamera } from 'react-icons/ai'
import { ImLocation2 } from 'react-icons/im'
import { SliderProps } from '../../types'
import { formatDate } from '../../helpers/helperFunctions'
import { MediaFileRenderer } from '../common/MediaFileRenderer'
import { toastNotifySuccess } from '../common/ToastMessage'

type Device = 'DESKTOP' | 'MOBILE'
type ImagesOriantationType = {
  [key: string]: string
}
export const ImageSlider = ({ images, userName, translationFunction }: SliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [device, setDevice] = useState<Device | null>(null)
  const [imagesOriantation, setImagesOrientation] = useState<ImagesOriantationType>({})
  const [imageEnlarged, setImageEnlarged] = useState<boolean>(false)

  const handleResize = () => {
    const width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    if (width >= 1199) {
      setDevice('DESKTOP')
    } else {
      setDevice('MOBILE')
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize, false)

    return () => {
      window.removeEventListener('resize', handleResize, false)
    }
  }, [])

  useEffect(() => {
    if (images) {
      let oIm: ImagesOriantationType = {}
      images.forEach(i => {
        let img = new Image();
        img.src = i.imgUrl
        let w = img.naturalWidth || img.width,
          h = img.naturalHeight || img.height;
        let mode = h > w ? 'portrait' : 'landscape'
        oIm[i.id] = mode
      })
      setImagesOrientation(oIm)
    }
  }, [images])

  const nexSlide = () => {
    setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1)
  }

  const prevslide = () => {
    setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1)
  }

  const enlargeImage = (fileId: string, device: string) => {
    let element = document.getElementById(fileId)
    if (element) {
      let type = element?.tagName
      if (type !== 'IMG') {
        return
      }
      let w = element.clientWidth
      // let height = window.innerHeight;
      let smaller = device === 'DESKTOP' ? '1.5' : '1.2'
      let width = window.innerWidth;
      let scaleValue = width > 1500 ? '2.0' : smaller
      let setTop = width > 1500 ? '100' : '10'
      let setLeft = (width - w) / 4

      element.style.transform = `scale(${scaleValue})`
      element.style.transition = "transform 0.25s ease";
      element.style.position = "fixed";
      element.style.top = `${setTop}px`;
      element.style.left = `${setLeft.toString()}px`;
      setImageEnlarged(true)
    }
  }

  const resetImage = (fileId: string) => {
    let element = document.getElementById(fileId)
    if (element) {
      let type = element?.tagName
      if (type !== 'IMG') {
        return
      }
      element.style.transform = "scale(1)";
      element.style.transition = "transform 0.25s ease";
      element.style.position = "unset";
      element.style.top = "unset";
      element.style.left = "unset";
      setImageEnlarged(false)
    }
  }

  const renderSlider = () => {
    if (!device) {
      handleResize()
      return (
        <div className="loader--loading" style={{ height: '100vh' }}></div>
      )
    }

    if (device === 'DESKTOP') {
      return (
        <section className="slider" id="dektop">
          <FiArrowLeftCircle onClick={prevslide} className="slider-arrow left slider-col-1" style={images.length > 1 ? { visibility: 'visible' } : { visibility: 'hidden' }} />
          {images.map((i, index) => {
            let photographer = i.isPhotographer ? userName : i.otherPhotographer
            let showDate = (i.date && i.date.toString() !== '0001-01-01T00:00:00Z') ? formatDate(i.date) : i.openDate ? i.openDate : null
            if (index !== currentIndex) {
              return null
            }

            return (
              <div className="slide active slider-col-2" key={i.id}>
                <>
                  <div className="focus-image-div slider-col-3">
                    <MediaFileRenderer
                      mediaFile={i}
                      fileId={i.id}
                      idKey={i.id}
                      videoStyles={{ width: 'inherit', height: 'inherit' }}
                      onClickHandler={() => imageEnlarged ? resetImage(i.id) : enlargeImage(i.id, 'DESKTOP')}
                      showVideoControls
                    />
                  </div>
                  <div className="slide-content-text slider-col-4">
                    <div className="image-text">
                      <h1 className="contribution-title">{i.title}</h1>
                      <div className="contribution-sub-title">
                        {photographer && (
                          <div className="contribution-photographer">
                            <AiFillCamera />
                            <p className="slide-content-text-pair-text">{photographer}</p>
                          </div>

                        )}

                        {showDate && (
                          <div className="contribution-date">
                            {/* <MdOutlineDateRange /> */}
                            <div>{showDate}</div>
                          </div>)}

                      </div>




                      <p className="image-description">{i.description}
                      </p>
                    </div>
                    <div className="image-information">

                      {i.location && (
                        <div className="slide-content-text-pairs">
                          <ImLocation2 />
                          <a className="location-link" href={`https://www.google.com/maps/search/?api=1&query=${i.locationLat},${i.locationLng}`} target="_blank" rel="noreferrer">{i.location}</a>
                        </div>
                      )}


                      {i.license && (
                        <div className="slide-content-text-pairs">
                          <MdOutlinePolicy />
                          <div className="slide-content-text-pair-text">{i.license}</div>
                        </div>
                      )}
                      <div className="slide-content-text-pairs" onClick={() => {
                        navigator.clipboard.writeText(window.location.href)
                        toastNotifySuccess(`${translationFunction('CONTRIBUTION_PAGE.COPIED_LINK_SUCCESS_MESSAGE')}`)
                      }} style={{ cursor: 'pointer' }}>
                        <VscCopy />
                        <div className="slide-content-text-pair-text">{translationFunction('CONTRIBUTION_PAGE.SHARE_LINK_TEXT')}</div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            )
          }
          )}
          <FiArrowRightCircle onClick={nexSlide} className="slider-arrow right slider-col-1" style={images.length > 1 ? { visibility: 'visible' } : { visibility: 'hidden' }} />
        </section >
      )
    } else {
      return (
        <section className="slider" id="mobile">
          {images.map((i, index) => {
            let photographer = i.isPhotographer ? userName : i.otherPhotographer
            let showDate = (i.date && i.date.toString() !== '0001-01-01T00:00:00Z') ? formatDate(i.date) : i.openDate ? i.openDate : null
            if (index !== currentIndex) {
              return null
            }
            const imgeClasses = imagesOriantation[i.id] === 'portrait' ? 'focus-image-div portrait' : 'focus-image-div landscape'
            return (
              <div className="slide active" key={i.id}>
                <>
                  <div className={imgeClasses}>
                    <MediaFileRenderer
                      mediaFile={i}
                      fileId={i.id}
                      idKey={i.id}
                      videoStyles={{ width: 'inherit', height: 'inherit' }}
                      onClickHandler={() => imageEnlarged ? resetImage(i.id) : enlargeImage(i.id, 'MOBILE')}
                      showVideoControls
                    />
                  </div>
                  <div className="slide-content-text">
                    <div className="image-text">
                      <h3>{i.title}</h3>
                      <div className="image-description">
                        <span>{i.description}</span>
                      </div>
                    </div>
                    <div className="image-information">
                      {i.location && (
                        <div className="slide-content-text-pairs">
                          <ImLocation2 />
                          <a className="location-link" href={`https://www.google.com/maps/search/?api=1&query=${i.locationLat},${i.locationLng}`} target="_blank" rel="noreferrer">{i.location}</a>
                        </div>
                      )}
                      {showDate && (
                        <div className="slide-content-text-pairs">
                          <MdOutlineDateRange />
                          <div className="slide-content-text-pair-text">{showDate}</div>
                        </div>
                      )}
                      {photographer && (
                        <div className="slide-content-text-pairs" >
                          <AiFillCamera />
                          <div className="slide-content-text-pair-text">{photographer}</div>
                        </div>
                      )}
                      {i.license && (
                        <div className="slide-content-text-pairs">
                          <MdOutlinePolicy />
                          <div className="slide-content-text-pair-text">{i.license}</div>
                        </div>
                      )}
                      <div className="slide-content-text-pairs" onClick={() => {
                        navigator.clipboard.writeText(window.location.href)
                        toastNotifySuccess(`${translationFunction('CONTRIBUTION_PAGE.COPIED_LINK_SUCCESS_MESSAGE')}`)
                      }}>
                        <VscCopy />
                        <div className="slide-content-text-pair-text">{translationFunction('CONTRIBUTION_PAGE.SHARE_LINK_TEXT')}</div>
                      </div>
                    </div>
                  </div>
                </>
                <div className="mobile-arrows" style={images.length > 1 ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                  <FiArrowLeftCircle id="mobileeeee" onClick={prevslide} className="slider-arrow left" />
                  <FiArrowRightCircle onClick={nexSlide} className="slider-arrow right" />
                </div>
              </div>
            )
          }
          )}

        </section>
      )
    }
  }

  return (
    <>
      {images.length > 1 ?

        <div className="thumbnails-wrapper " id="thumbnail-wrapper-div">

          <div
            className="thumbnails"
            id="thumbnail-div"
          >
            {images.length > 1 ? images.map((i, index) => {
              return (
                <MediaFileRenderer
                  mediaFile={i}
                  fileId={`thumbnail-${index}`}
                  idKey={`${index}`}
                  classes={currentIndex === index ? "thumbnail active-thumbnail" : "thumbnail"}
                  videoStyles={{ opacity: "0.4", width: 'inherit', height: 'inherit' }}
                  onClickHandler={() => {
                    setImageEnlarged(false)
                    setCurrentIndex(index)
                  }}
                />
              )
            }) : null}
          </div>
        </div> : ""}


      {renderSlider()}

    </>
  )

}