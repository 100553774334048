import { useEffect, useRef, useState, useCallback } from 'react';
import { KeyString } from '../../../types'


interface IMap {
  mapTypeControl?: boolean;
  title: string
  name: string
  placeholder: string
  value: string
  onUpdateCoordinates: Function
  onUpdateLocation: Function
}


type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;
type GoogleMarker = google.maps.Marker;

type GoogleAutoComplete = google.maps.places.Autocomplete

export const Map = ({ mapTypeControl = false, title, name, placeholder, value, onUpdateLocation, onUpdateCoordinates }: IMap) => {

  const ref = useRef<HTMLDivElement>(null);
  const autoCompleteRef = useRef<HTMLInputElement>(null);
  const markerRef = useRef<GoogleMarker | null>(null)
  const [map, setMap] = useState<GoogleMap>();
  const [hasAutoComplete, setHasAutoComplete] = useState(false)
  const [autoCompleteQuery, setAutoCompleteQuery] = useState<string>(value)

  useEffect(() => {
    setAutoCompleteQuery(value)
  }, [value])

  const createMarker = useCallback((place: google.maps.places.PlaceResult) => {

    if (!place.geometry || !place.geometry.location || !map) return;

    if (markerRef.current != null) {
      markerRef.current.setMap(null)
    }
    let options: KeyString = {}
    if (place.formatted_address) {
      options['title'] = place.formatted_address
    }
    markerRef.current = new google.maps.Marker({
      // map,
      position: place.geometry.location,
      draggable: true,
      animation: google.maps.Animation.DROP,
      ...options
    });

    map.panTo(place.geometry.location)

    google.maps.event.addListener(markerRef.current, 'dragend', function () {
      if (markerRef.current != null) {
        let lat = markerRef.current.getPosition()?.lat()
        let lng = markerRef.current.getPosition()?.lng()
        if (lat && lng) {
          let coordinates: [number, number] = [lat, lng]
          onUpdateCoordinates(coordinates)
        }
      }
    })

    markerRef.current.setMap(map)
    let coordinates: [number, number] = [place.geometry.location.lat(), place.geometry.location.lng()]
    let location = place.formatted_address ? place.formatted_address : ''
    onUpdateLocation(location)
    onUpdateCoordinates(coordinates)
  }, [onUpdateCoordinates, onUpdateLocation, map])

  const startPlaces = useCallback((map: GoogleMap) => {
    let autoComplete: GoogleAutoComplete
    if (autoCompleteRef.current && !hasAutoComplete) {
      setHasAutoComplete(true)
      autoComplete = new google.maps.places.Autocomplete(
        autoCompleteRef.current,
        {
          fields: ['address_components', 'formatted_address', 'name', 'geometry']
        }
      );
      autoComplete.addListener("place_changed", () => {
        let place = autoComplete?.getPlace()
        if (place) {
          createMarker(place)
        }
        if (place.formatted_address) {

          setAutoCompleteQuery(place.formatted_address)
        }
      })

    }
  }, [createMarker, hasAutoComplete]);


  const initMap = useCallback((zoomLevel: number, address: GoogleLatLng) => {
    if (ref.current) {
      setMap(
        new google.maps.Map(ref.current, {
          zoom: zoomLevel,
          center: address,
          mapTypeControl: mapTypeControl,
          streetViewControl: false,
          // rotateControl: false,
          // scaleControl: true,
          // fullscreenControl: false,
          // panControl: false,
          zoomControl: true,
          // gestureHandling: 'cooperative',
          mapTypeId: google.maps.MapTypeId.TERRAIN,
          // draggableCursor: 'pointer',
        })
      );
    }
  }, [mapTypeControl]);


  const defaultMapStart = useCallback(() => {
    const defaultAddress = new google.maps.LatLng(65.166013499, 13.3698147);
    initMap(4, defaultAddress);
  }, [initMap]);

  const startMap = useCallback(() => {
    if (!map) {
      defaultMapStart();
    }
  }, [map, defaultMapStart]);


  useEffect(() => {
    if (map) {
      startPlaces(map)
    }

  }, [map, startPlaces])

  useEffect(() => { startMap() }, [map, startMap]);

  return (
    <div className="field-pair">
      <label htmlFor={name}>{title}</label>
      <input
        type='text'
        ref={autoCompleteRef}
        onChange={event => setAutoCompleteQuery(event.target.value)}
        placeholder={placeholder}
        value={autoCompleteQuery}
      />

      <div className="map-container">
        <div ref={ref} className="map-container__map">
        </div>
      </div>
    </div>
  );
};