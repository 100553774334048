import '../../css/Groups.css';
import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { ROUTE } from '../../routes'
import { useOrganizationContext } from '../../context/OrganizationContext';
import { Group, OrganizationUser } from "../../types"
import { toastNotifySuccess, toastNotifyError } from '../common/ToastMessage'
import { AdminApi } from '../../api/admin';
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'

const GroupsComponent = () => {
  let history = useHistory();

  const organizationContext = useOrganizationContext()
  const [organizationId, setOrganizationId] = useState<(string)>("")


  const [touched, setTouched] = useState<boolean>(false)

  const [newGroup, setNewGroup] = useState<(string)>("")
  const [groups, setGroups] = useState<(Group[])>([])
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null)
  const [availableUsers, setAvailableUsers] = useState<string[] | null>(null)

  const [markedAvailableUsers, setMarkedAvailableUsers] = useState<string[]>([])
  const [markedSelectedUsers, setMarkedSelectedUsers] = useState<string[]>([])

  const [filter, setFilter] = useState<string>("")
  const [customUser, setCustomUser] = useState<string>("")

  const { getTranslation } = useCustomTranslation(organizationId)

  useEffect(() => {
    if (!organizationContext || !organizationContext.organization) {
      return history.push(ROUTE.HOME)
    }
    var orgId = organizationContext?.organization.id

    const getOrgUsers = async (orgId: string) => {
      try {
        const response = await AdminApi.getOrgUsers(orgId)
        let userEmails = response.map((user: OrganizationUser) => user.email)
        setAvailableUsers(userEmails)
      } catch (error: any) {
        console.error('error', error)
      }
    }

    const getGroups = async (orgId: string) => {
      try {
        const response = await AdminApi.getGroups(orgId)
        setGroups(response.data)
      } catch (error: any) {
        console.error('error', error)
      }
    }

    getOrgUsers(orgId)
    getGroups(orgId)
    setOrganizationId(orgId)

  }, [organizationId, organizationContext, history])

  // --- methods

  const updateSelectedUsers = (removeUsers: string[], addUsers: string[]) => {
    if (removeUsers === [] && addUsers === []) { return }
    if (!selectedGroup || !availableUsers) { return }

    // add & remove users from selected group
    let g = selectedGroup
    let updatedSelectedGroup = {
      id: g.id,
      name: g.name,
      users: [...g.users, ...addUsers].filter(user => !removeUsers.includes(user))
    }
    setSelectedGroup(updatedSelectedGroup)

    // add & remove users from "available" list of users
    let updatedAvailableUsers = [
      ...availableUsers,
      ...removeUsers.filter(user => !availableUsers.includes(user))
    ]
    setAvailableUsers(updatedAvailableUsers)

    setMarkedSelectedUsers([]) // clear users
    setMarkedAvailableUsers([]) // clear users
    setTouched(true)
  }

  const saveGroup = async (orgId: string) => {
    try {
      const response = await AdminApi.postGroup(orgId, selectedGroup)
      toastNotifySuccess(getTranslation('GLOBAL.UPDATE_SUCCESS'))

      var resultGroup: Group = response.data
      setSelectedGroup(resultGroup)
      var resultGroups: Group[] = groups.map((group: Group, i: number, groups: any) => {
        if (group.name === resultGroup.name) {
          return resultGroup
        }
        return group
      })
      setGroups(resultGroups)
    } catch (error: any) {
      toastNotifyError(error.respone ? error.response.data.message : toastNotifySuccess(getTranslation('GLOBAL.UNKNOW_ERROR')))
    }
  }

  const addGroup = () => {
    // Group name cannot be empty
    if (newGroup === "") { return }

    // Group already exists, select it
    var existingGroup = groups.find(g => g.name === newGroup)
    if (existingGroup) {
      setSelectedGroup(existingGroup);
      setNewGroup("")
      return
    }

    let g = {
      id: "",
      name: newGroup.replaceAll(" ", "_"),
      users: []
    }
    setGroups([...groups, g]); setNewGroup(""); setSelectedGroup(g)
  }

  const addCustomUser = () => {
    // User name cannot be empty
    if (customUser === "") { return }
    setFilter("")

    // User already exists
    var existingUser = selectedGroup?.users.find(user => user === customUser)
    if (existingUser) {
      setMarkedSelectedUsers([existingUser])
      setCustomUser("")
      return
    }

    updateSelectedUsers([], [customUser]); setCustomUser(""); setTouched(true);
    setMarkedSelectedUsers([customUser])
  }


  const deleteGroup = async (group: Group | null) => {
    if (!group) {
      return
    }
    if (group.id !== "") {
      try {
        await AdminApi.deleteGroup(group.id, organizationId)
        toastNotifySuccess(getTranslation('GROUPS.DELETED'))

      } catch (error: any) {
        console.error('error', error)
        return
      }
    }
    setGroups(groups.filter(g => g.name !== group.name))
    setSelectedGroup(null)
    setMarkedSelectedUsers([])
    setMarkedAvailableUsers([])
    setTouched(false)
  }

  return (
    <div className="App">
      <div className="app-content groups-page">
        <div className="group-select">
          <div>
            <h3>{getTranslation('GROUPS.SELECT_GROUP')}</h3>
            <select id="groups" value={selectedGroup?.name} name="groups" size={5} onChange={(e) => {
              var existingGroup = groups.find(g => g.name === e.target.value)
              setSelectedGroup(existingGroup ? existingGroup : null)
              setMarkedAvailableUsers([])
              setMarkedSelectedUsers([])
            }}>
              {groups.map((group) => {
                return (<option key={group.name} value={group.name} >{group?.name}</option>)
              })}
            </select>
          </div>

          <div className="group-create">
            <h3>{getTranslation('GROUPS.NEW_GROUP')}</h3>
            <input type="text" value={newGroup} onChange={(e) => { setNewGroup(e.target.value) }} />
            <button className="btn btn-default add-button" onClick={addGroup}>{getTranslation('GLOBAL.ADD')}</button>
          </div>
        </div>

        <div className="user-manager" style={{ opacity: selectedGroup ? "100%" : "30%" }}>
          <div className="user-filter">
            <label>filter users</label>
            <input className="user-filter" type="text" value={filter} placeholder='search..' onChange={(e) => { setFilter(e.target.value) }} />
          </div>


          <div className="user-selection">
            <div className="user-box">
              <h3>{getTranslation('GROUPS.AVAILABLE_USERS')}</h3>
              <select id="allusers" name="allusers" onChange={(e) => {
                setMarkedAvailableUsers(Array.from(e.target.selectedOptions).map(o => o.value))
              }} multiple>
                {availableUsers?.filter(user => user.includes(filter, 0) && !selectedGroup?.users.includes(user)).map((user, index) => {
                  return (<option key={user} value={user}>{user}</option>)
                })}
              </select>
            </div>

            <div className="user-selection-buttons">
              <button className="btn btn-secondary user-selection-button" onClick={() => { updateSelectedUsers([], markedAvailableUsers) }} >{getTranslation('GLOBAL.ADD')}</button>
              <button className="btn btn-secondary user-selection-button" onClick={() => { updateSelectedUsers(markedSelectedUsers, []) }} >{getTranslation('GLOBAL.REMOVE')}</button>
            </div>

            <div className="user-box">
              <h3>{selectedGroup ? <span className="group-name">{selectedGroup?.name} </span> : getTranslation('GROUPS.USERS')} </h3>
              <select id="groupusers" name="groupusers" placeholder="no users" value={markedSelectedUsers} onChange={(e) => {

                setMarkedSelectedUsers(Array.from(e.target.selectedOptions).map(o => o.value))
              }} multiple>
                {selectedGroup?.users.filter(user => user.includes(filter, 0)).map((user, index) => {
                  return (<option key={user} value={user} >{user}</option>)
                })}
              </select>

              <div className="custom-user">
                <h4>{getTranslation('GROUPS.ADD_BY_EMAIL')}</h4>
                <input type="text" placeholder={getTranslation('REGISTRATION.EMAIL')} value={customUser} onChange={(e) => { setCustomUser(e.target.value) }} />
                <button className="btn btn-secondary add-button" onClick={addCustomUser} >{getTranslation('GLOBAL.ADD')}</button>
              </div>
            </div>
          </div>

          <button className="btn btn-caution" onClick={() => { deleteGroup(selectedGroup) }} >{getTranslation('GLOBAL.DELETE')}</button>
          <button className="btn btn-default-with-border save-button" onClick={() => { saveGroup(organizationId) }} disabled={!touched}>{getTranslation('GLOBAL.SAVE')}</button>

        </div>
      </div >
    </div >
  );
}

export const Groups = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="groups" router={history}>
        <GroupsComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <GroupsComponent {...props} />
  )
}
