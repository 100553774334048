import '../../../css/App.css';
import { useState, useRef, useEffect } from 'react';
import { ImageCropper } from '../../common/ImageCropper'
import { MediaType, MediaFile, MediaListItem } from '../../../types'
import { MediaPreview } from './MediaPreview'

type ImagePreviewProps = {
  onChange: Function
  errorFiles: string[]
  selectedMediaUrl: string
  disableUpload: boolean
  translationFunction: Function
  organizationColor: string
}

export const ImageUploader = ({ onChange, errorFiles, selectedMediaUrl, disableUpload, translationFunction, organizationColor }: ImagePreviewProps) => {
  const [mediaList, setMediaList] = useState<Array<MediaListItem>>([])

  const [showCropper, setShowCropper] = useState<boolean>(false)
  const [showVideoPlayer, setShowVideoPlayer] = useState<boolean>(false)


  const [imageInputBtn, setImageInputBtn] = useState<HTMLInputElement | undefined | null>()
  const [selectedImage, setSelectedImage] = useState<MediaListItem>()
  const [selectedVideo] = useState<MediaFile>()
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0)

  const handleChange = (event: any) => {
    let updatedMediaList: MediaListItem[] = []
    let files: File[] = event.target.files
    for (let file of files) {
      if (file.type.startsWith("video")) {
        let video: MediaFile = {
          URL: URL.createObjectURL(file),
          File: file,
          type: MediaType.Video
        }
        updatedMediaList.push({ mediaFile: video, formValues: null })
        continue
      }

      let image: MediaFile = {
        URL: URL.createObjectURL(file),
        File: file,
        type: MediaType.Image
      }

      updatedMediaList.push({ mediaFile: image, formValues: null })
    }

    setMediaList([...mediaList, ...updatedMediaList]);
    propagateImageListChange([...mediaList, ...updatedMediaList])
  }

  const deleteFile = (fileUrl: string) => {
    let filtered = mediaList.filter((item: MediaListItem) => item.mediaFile.URL !== fileUrl)
    setMediaList(filtered)
    propagateImageListChange(filtered)
  }

  const elementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = elementRef.current;



    const handleAnimationEnd = () => {
      // Do something after the animation ends
    };

    if (element) {
      element.style.animation = 'entryAnimation 0.5s ease-in-out';
      element.style.opacity = '100%';


      element.addEventListener('animationend', handleAnimationEnd);

      return () => {
        element.removeEventListener('animationend', handleAnimationEnd);
      };
    }
  }, []);

  const closeModal = (e: any) => {
    setShowCropper(false);
    setShowVideoPlayer(false);
  }

  const propagateImageListChange = (files: MediaListItem[]) => {
    onChange(files)
  }

  const uploadImageClick = (e: any) => {
    if (showCropper || showVideoPlayer) {
      return;
    }
    imageInputBtn?.click();
  }

  const onCropHander = (img: MediaFile) => {
    let oldImg = mediaList[selectedImageIndex]
    oldImg.mediaFile = img
    const mediaListCopy = [...mediaList]
    mediaListCopy[selectedImageIndex].mediaFile = img
    setMediaList(mediaListCopy);
    setShowCropper(false);
    propagateImageListChange(mediaListCopy)
  }

  function VideoModal() {
    if (selectedVideo === undefined) {
      return <div />;
    }

    return <div className="modal" style={{ display: showVideoPlayer ? "block" : "none" }} >
      <div className="modal-content video-modal">
        <button className="close-modal" aria-label="close" onClick={closeModal}>&times;</button>
        <video className="modal-video" controls>
          <source src={selectedVideo.URL} type={selectedVideo.File.type} />
          Your browser does not support the video tag.
        </video>

      </div>
    </div>
  }

  function CropperModal() {
    if (selectedImage === undefined) {
      return <div />;
    }

    return <div className="modal" style={{ display: showCropper ? "block" : "none" }} >
      <div className="modal-content image-modal">
        <button className="close-modal" aria-label="close" onClick={closeModal}>&times;</button>
        <ImageCropper src={selectedImage.mediaFile} onUpdate={() => { }} onSave={onCropHander} cancel={closeModal} translationFunction={translationFunction} />
      </div>
    </div>
  }
  return (
    <div className="image-preview-box">
      <div>
        <script src="js/load-image.all.min.js"></script>
        {showCropper ? <div className="pop-in" ref={elementRef}><CropperModal /></div> : ""}
        {showVideoPlayer ? <VideoModal /> : ""}

        <div className="media-preview-wrapper">
          {mediaList.map(function (d, idx) {
            // return (<img key={idx} onClick={(e: any) => { setSelectedImageIndex(idx); setSelectedImage(d); setShowCropper(true); e.stopPropagation(); }} className="preview-media" src={d.URL} />)
            return (
              <MediaPreview
                key={idx}
                file={d}
                selectedMediaIdentifier={selectedMediaUrl}
                errorFiles={errorFiles}
                deleteFile={() => deleteFile(d.mediaFile.URL)}
                onCropSelect={() => { setSelectedImageIndex(idx); setSelectedImage(d); setShowCropper(true); }}
              />)
          })}
        </div>

      </div>
      <input ref={(input) => setImageInputBtn(input)} className="img-preview-input-btn" accept="image/png, image/gif, image/jpeg, video/mp4 , video/mov, video/mpg, video/mpeg" type="file" name="files" onChange={handleChange} multiple />
      <div style={{ marginTop: '30px' }}>

        <button style={{ backgroundColor: organizationColor, width: 250, fontSize: "1.17em" }} className="btn h-middle" onClick={uploadImageClick}>{translationFunction('CREATE_CONTRIBUTION_PAGE.SELECT_IMAGES_BUTTON')}</button>
      </div>
    </div>
  );

}