import { createContext, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PlatformTranslation } from '../types'
import { Api } from '../api/api'

type ApplicationContextType = {
  language: string
  setLanguageInContext: (lang: string) => void
  systemPageTranslations: PlatformTranslation[]
  updateSystemPageTranslations: Function

}

const ApplicationContext = createContext<ApplicationContextType>({
  language: 'en',
  setLanguageInContext: (lang: string) => { },
  systemPageTranslations: [],
  updateSystemPageTranslations: (translations: PlatformTranslation) => { }
})


const ApplicationProvider = ({ children }: any) => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState<string>('en')
  const [systemPageTranslations, setSystemPageTranslations] = useState<PlatformTranslation[]>([])
  const [fecthedOnce, setFetchedOnce] = useState<boolean>(false)


  const setLanguageInContext = (lang: string) => {
    if (lang !== language) {
      i18n.changeLanguage(lang)
      setLanguage(lang)
    }
  }

  const updateSystemPageTranslations = (translations: PlatformTranslation[]) => {
    setSystemPageTranslations(translations)
  }

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const response = await Api.getSystempPages()
        setSystemPageTranslations(response)
      } catch (error: any) {
        console.error("Could not get user due to: ", error)
      }
    }
    if (!systemPageTranslations.length && !fecthedOnce) {
      setFetchedOnce(true)
      fetchTranslations()
    }
  }, [systemPageTranslations, fecthedOnce])


  return (
    <ApplicationContext.Provider
      value={{ language, setLanguageInContext, updateSystemPageTranslations, systemPageTranslations }} >
      {children}
    </ApplicationContext.Provider >
  )
}

const useApplicationContext = (): ApplicationContextType => useContext(ApplicationContext)
export { ApplicationProvider, useApplicationContext }