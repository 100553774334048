import {
  CollectionTranslation,
  CollectionType,
  OrganizationType,
  OrganizationTranslation,
  PlatformTranslation,
  HelpPageTranslation,
  TranslationType
} from '../types'

export const getBrowserLanguage = () => {
  const userLang = navigator.language
  const char = userLang.indexOf('-')
  const language = userLang.substring(0, char)
  return language
}

export const getBrowserRegion = () => {
  const userLang = navigator.language
  const char = userLang.indexOf('-')
  const region = userLang.substring(char + 1)
  return region
}


export const _handleKeyDownPreventDefault = (e: any) => {
  if (e.key === 'Enter') {
    e.preventDefault()
  }
}

export const formatDate = (dateString: Date | null) => {

  return dateString ? new Date(dateString).toLocaleDateString() : dateString
}
export const formatDateString = (dateString: string) => {
  return new Date(dateString).toLocaleDateString()
}


export const getOrganizationKeyInLang = (organization: OrganizationType, key: 'about' | 'description' | 'terms', lang: string) => {
  let translation: OrganizationTranslation | null = null
  organization.translations.forEach((t: OrganizationTranslation) => {
    if (t.languageCode === lang) {
      translation = t
    }
  })

  if (!translation) {
    let enTranslation = organization.translations.filter((t: OrganizationTranslation) => t.languageCode === 'en')[0]
    if (enTranslation) {
      return enTranslation[key]
    }
    return ""
  }

  return translation[key]
}

export const getOrganizationKeyInLangWithDefault = (organization: OrganizationType, key: 'about' | 'description' | 'terms', lang: string) => {
  let translation: OrganizationTranslation | null = null
  organization.translations.forEach((t: OrganizationTranslation) => {
    if (t.languageCode === lang) {
      translation = t
    }
  })

  if (!translation) {
    return ""
  }

  return translation[key]
}

export const getCollectionTitleOrDescriptionInLang = (collection: CollectionType, key: 'title' | 'description', lang: string) => {
  let translation: CollectionTranslation | null = null
  collection.translations.forEach((t: CollectionTranslation) => {
    if (t.languageCode === lang) {
      translation = t
    }
  })

  if (!translation) {
    let enTranslation = collection.translations.filter((t: CollectionTranslation) => t.languageCode === 'en')[0]
    return enTranslation[key]
  }

  return translation[key]
}

export const getCollectionKeyInLang = (collection: CollectionType, key: 'title' | 'description' | 'terms', lang: string) => {
  let translation: CollectionTranslation | null = null
  collection.translations.forEach((t: CollectionTranslation) => {
    if (t.languageCode === lang) {
      translation = t
    }
  })

  if (!translation) {
    let enTranslation = collection.translations.filter((t: CollectionTranslation) => t.languageCode === 'en')[0]
    return enTranslation[key]
  }

  return translation[key]
}



export const getCollectionKeyInLangWithDefault = (collection: CollectionType, key: 'title' | 'description' | 'terms', lang: string) => {
  let translation: CollectionTranslation | null = null
  collection.translations.forEach((t: CollectionTranslation) => {
    if (t.languageCode === lang) {
      translation = t
    }
  })

  if (!translation) {
    return ""
  }

  return translation[key]
}


export const preventDefault = (e: any) => {
  if (e.key === 'Enter') {
    e.preventDefault()
  }
}


export const getHelpPageTranslation = (translations: HelpPageTranslation[], lang: string): string | null => {
  let translationsInLang = translations.filter((t: HelpPageTranslation) => t.languageCode === lang)

  if (translationsInLang.length) {
    return translationsInLang[0].translation
  }

  let enTranslation = translations.filter((t: HelpPageTranslation) => t.languageCode === 'en')

  if (enTranslation.length) {
    return enTranslation[0].translation
  }

  return null
}

export const getSystemPageTranslation = (translations: PlatformTranslation[], lang: string): TranslationType | null => {
  let translationsInLang = translations.filter((t: PlatformTranslation) => t.languageCode === lang)

  if (translationsInLang.length) {
    return translationsInLang[0].translations
  }

  let enTranslation = translations.filter((t: PlatformTranslation) => t.languageCode === 'en')

  if (enTranslation.length) {
    return enTranslation[0].translations
  }

  return null
}