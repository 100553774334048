import '../../css/EditOrganization.css'
import { useEffect, useState } from "react"
import { useLocation, useHistory } from 'react-router-dom'
import {
  Language,
  HelpPageTranslationsType,
  UpdateHelpPagePayload,
  HelpPageTranslation
} from "../../types"
import { ROUTE } from '../../routes'
import { useUserContext } from '../../context/UserContext';
import { AdminApi } from '../../api/admin';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { toastNotifyError, toastNotifySuccess } from '../common/ToastMessage'
import { HelpPageTranslations } from './HelpPageTranslations'
import { userHasPermission } from '../../helpers/permissionsHelper'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'


const EditHelpPagesComponent = () => {
  const location = useLocation()
  const history = useHistory()
  const userCtx = useUserContext()
  const orgCtx = useOrganizationContext()

  const [apiLanguages, setApiLanguages] = useState<Language[] | null>(null)
  const [languages, setLanguages] = useState<HelpPageTranslationsType | null>(null)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const { getTranslation } = useCustomTranslation(orgCtx?.organization?.id || '')

  const handleTranslationUpdate = (language: string, value: string) => {
    if (languages && language in languages) {
      let prevLangValues = languages[language]
      prevLangValues.translation = value
      setLanguages(prev => ({ ...prev, [language]: prevLangValues }))
    }
  }


  const verifyTranslations = () => {
    if (!languages) return false
    let entries = Object.entries(languages)
    let isValid = entries.reduce((acc, current) => {
      if (current[1].required) {
        return acc && (current[1].translation.length > 0)
      }
      return acc && true
    }, true)
    return isValid
  }


  const submissionFormatTranslations = () => {
    if (!languages) return []
    let formatted: HelpPageTranslation[] = []
    for (const [key, value] of Object.entries(languages)) {
      if (value.translation === '') {
        continue
      }

      let langObject: HelpPageTranslation = {
        languageCode: key,
        translation: value.translation
      }
      formatted.push(langObject)
    }
    return formatted
  }

  const handleSubmit = async () => {
    if (!orgCtx || !orgCtx.organization || !verifyTranslations()) {
      return
    }

    let formatted = submissionFormatTranslations()
    const data: UpdateHelpPagePayload = {
      translations: formatted,
    }
    setIsUpdating(true)
    try {
      const response = await AdminApi.updateOrganizationHelpPages(orgCtx.organization.id, data)
      if (response.length) {
        let updatedTranslations: HelpPageTranslation[] = []
        response.forEach((t: any) => {
          updatedTranslations.push({
            languageCode: t.languageCode,
            translation: t.translation,
          })
        });
        orgCtx.updateHelpPageTranslations(updatedTranslations)
      }
      toastNotifySuccess(getTranslation('GLOBAL.UPDATE_SUCCESS'))
    } catch (error: any) {

      toastNotifyError(error.respone ? error.response.data.message : 'Something went wrong, could not update help page translations')

    } finally {
      setIsUpdating(false)
    }
  }

  useEffect(() => {
    const setUpLanguages = (fetchedLanguages: Language[]) => {
      let x: HelpPageTranslationsType = {}
      fetchedLanguages.forEach((l: Language) => {
        let hasTranslation = orgCtx && orgCtx.helpPageTranslations ? orgCtx.helpPageTranslations.filter((t: HelpPageTranslation) => t.languageCode === l.language) : []
        let initialTranslation = ''
        if (hasTranslation.length) {
          initialTranslation = hasTranslation[0].translation
        }
        x[l.language] = {
          required: l.required,
          language: l.language,
          translation: initialTranslation,
        }
      });
      setLanguages(x)

    }
    const getLanguages = async () => {
      const response = await AdminApi.getLanguages()
      setApiLanguages(response)
      setUpLanguages(response)
    }

    if (!userCtx || !userCtx.loggedInUser || !orgCtx || !orgCtx.organization) {
      history.push(ROUTE.HOME)
    } else {
      let orgId = orgCtx.organization.id
      let userRoles = userCtx.loggedInUser.roles
      if (!(userHasPermission(userRoles, userCtx.actionRoles.SUPER_ADMIN, orgId, userCtx.userIsPlaformAdmin) || userHasPermission(userRoles, userCtx.actionRoles.ADMIN, orgId, userCtx.userIsPlaformAdmin))) {
        history.push(ROUTE.HOME)
      }
      if (!apiLanguages) {
        getLanguages()
      } else {
        setUpLanguages(apiLanguages)
      }
    }

  }, [location, history, userCtx, apiLanguages, orgCtx])

  const submitButtonClasses = isUpdating ? 'loader-btn loader-btn--loading btn-stnd-al m-t-50' : 'loader-btn btn-stnd-al m-t-50'


  if (!languages) {
    return (<div className="loader--loading"></div>)
  }
  return (
    <div className="App">
      <div className="app-content edit-plaform-page">
        <h1>{getTranslation('ADMIN.HELP_PAGE.EDIT_TITLE')}</h1>
        <div className="edit-organization-container">
          <div className="edit-organization-form-container">
            <HelpPageTranslations
              languages={languages}
              onTranslationUpdate={handleTranslationUpdate}
              editorTitle={getTranslation('ADMIN.HELP_PAGE.TITLE')}
              sectionTitle={getTranslation('ADMIN.HELP_PAGE.SECTION_TITLE')}
            />
          </div>
          <button className={submitButtonClasses} onClick={handleSubmit} style={{ backgroundColor: orgCtx?.organization?.color }}>
            <span className="loader-btn__text">{getTranslation('GLOBAL.SAVE')}</span>
          </button>
        </div>
      </div>
    </div>
  )
}


export const EditHelpPages = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="userAdministration" router={history}>
        <EditHelpPagesComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <EditHelpPagesComponent {...props} />
  )
}