import * as TooltipPrimitive from '@radix-ui/react-tooltip';

export const CustomToolTip = ({ message, icon }: any) => {
  const Tooltip = TooltipPrimitive.Root;
  const TooltipTrigger = TooltipPrimitive.Trigger;
  const TooltipContent = TooltipPrimitive.Content;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button className="tooltip-button">
          {icon}
        </button>
      </TooltipTrigger>
      <TooltipContent className="tooltip-content" side="right">
        <div>{message}</div>
      </TooltipContent>
    </Tooltip>
  )

}